import React, { useState, useEffect, } from 'react';

const Save = ({ className, color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895 0 0 0.895 0 2V20C0 21.105 0.895 22 2 22H20C21.105 22 22 21.105 22 20V4L18 0H16V6C16 6.552 15.552 7 15 7H6C5.448 7 5 6.552 5 6V0H2ZM12 0V5H14V0H12ZM16 12H6C4.895 12 4 12.895 4 14V20H18V14C18 12.895 17.105 12 16 12Z" fill={colorState} />
    </svg>
  );
};

export default Save;