import React from "react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ARTextField from '@presentation/components/ui/ARTextField/ARTextField.index';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import AppRadioButton from '@presentation/components/AppRadioButton/AppRadioButton';
import AppSelectSearch from '@presentation/components/AppSelectSearch/AppSelectSearch';
import { Item as AppSelectSearchItem } from '@presentation/components/AppSelectSearch/AppSelectSearch.types';
import { ITableBodyRowProps } from './TableBodyRow.interfaces';
import { LanguageCode } from '@domain/LanguageCode';
import './TableBodyRow.scss';

const TableBodyRow = (params: ITableBodyRowProps) => {
  const [socialNameErrorState, setSocialNameErrorState] = React.useState(false);
  const [socialNameErrorTextState, setSocialNameErrorTextState] = React.useState('');
  const [socialNameState, setSocialNameState] = React.useState<string | null>(params.name || null);
  const [languageCode, setLanguageCode] = React.useState<LanguageCode | null>(params.languageCode || null);
  const [isDefaultState, setIsDefaultState] = React.useState<boolean | null>(params.default || null);

  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/socialNameState', socialNameState); //DELETE

    if (socialNameState !== null) {
      setSocialNameErrorState(!socialNameState.length);
      params.inputLanguageLocalName(params.uuid + '', socialNameState + '');
    }
  }, [socialNameState]);
  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/socialNameState', socialNameState); //DELETE

    if (socialNameErrorState) {
      setSocialNameErrorTextState('Заполните поле');
    } else {
      setSocialNameErrorTextState('');
    }

  }, [socialNameErrorState]);

  React.useEffect(() => {
    // console.debug('TableBodyRow/useEffect/params.uuid', params.uuid); //DELETE
    // console.debug('TableBodyRow/useEffect/params.name', params.name); //DELETE
    // console.debug('TableBodyRow/useEffect/params.languageCode', params.languageCode); //DELETE
    // console.debug('TableBodyRow/useEffect/params.default', params.default); //DELETE
    setSocialNameState(params.name || '');
    setLanguageCode(params.languageCode || null);
    setIsDefaultState(!!params.default);
  }, [params.uuid]);

  React.useEffect(() => {
    // console.debug('TableBodyRow/useEffect/params.name', params.name); //DELETE
    setSocialNameState(params.name || '');
  }, [params.name]);

  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/params.languageCode', params.languageCode); //DELETE
    setLanguageCode(params.languageCode || null);
  }, [params.languageCode]);

  React.useEffect(() => {
    // console.debug('TableBodyRow/useEffect/params.default', params.default); //DELETE
    setIsDefaultState(!!params.default);
  }, [params.default]);

  const selectLocalCodeHandler = (item: AppSelectSearchItem) => {
    params.setLanguageLocalCode(params.uuid + '', item);
  };

  return (
    <TableRow>
      <TableCell className="socials-settings-table-body-row__cell" sx={{ borderBottom: "none", width: '25%', }}>
        <ARTextField
          className="socials-settings-table-body-row__input"
          theme='standard'
          label="Email"
          variant="filled"
          fullWidth={true}
          error={socialNameErrorState}
          helperText={socialNameErrorTextState}
          onInput={setSocialNameState}
          value={socialNameState}
        />
      </TableCell>

      <TableCell className="socials-settings-table-body-row__cell" sx={{ borderBottom: "none", width: '35%', }}>
        <AppSelectSearch
          value={params.languageCode ? params.languageCode.name! : ''}
          onItemClick={selectLocalCodeHandler}
          items={params.languageCodes}
        />
      </TableCell>

      <TableCell className="socials-settings-table-body-row__cell" sx={{ borderBottom: "none", width: '30%', textAlign: 'center', }}>
        <div className="socials-settings-table-body-row__cell-container">
          <AppRadioButton
            mode="checkbox"
            value={!!isDefaultState}
            onChange={(data) => { params.setDefaultStatus(params.uuid || '', data.value) }}
          />
        </div>
      </TableCell>

      <TableCell sx={{ borderBottom: "none", width: '10%', }} align="right">
        <AppSvgIcon
          className="socials-settings-table-body-row__actions--delete"
          name="delete"
          color="#CF2136"
          onClick={() => params.delete(params.uuid || '')}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableBodyRow;