import React from "react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import './TableHeadRow.scss';

const TableHeadRow = () => {
  return (
    <TableRow>
      <TableCell
        className="socials-settings-table-head-row__cell"
        sx={{ borderBottom: "none", width: '25%', }}
        key={1}
      >
        Язык
      </TableCell>

      <TableCell
        className="socials-settings-table-head-row__cell"
        sx={{ borderBottom: "none", width: '75%', }}
        key={2}
      >
        Символ
      </TableCell>
    </TableRow>
  );
};

export default TableHeadRow;