import { useState, useEffect } from 'react';

export function useStateBoolean(value: boolean) {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return state;
}