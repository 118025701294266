import React from "react";
import AppButton from '@presentation/components/AppButton/AppButton';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import { AppButtonCancelParams } from './AppButtonCancel.interfaces';
import './AppButtonCancel.scss';

const AppButtonCancel = (params: AppButtonCancelParams) => {
  return (
    <AppButton
      className={`app-button-cancel ${params.className}`}
      variant="contained"
      loaderColor="white"
      onClick={params.onClick}
      disabled={params.loading || params.disabled}
    >
      <div className="app-button-cancel__container">
        <div className="app-button-cancel__title">Отменить</div>
        <div className="app-button-cancel__logo">
          <AppSvgIcon className="app-button-cancel__logo--svg" name="plus" color="#FFFFFF" />
        </div>
      </div>
    </AppButton>
  );
};

export default AppButtonCancel;