import React from 'react';
import PacksProvider from '@providers/PacksProvider';
// import AnalyticsProvider from '@providers/AnalyticsProvider';
import SalesChart from '@components/pages/analytics/SalesChart';
import Header from '@components/pages/analytics/Header';
import PacksTable from '@components/pages/analytics/PacksTable';
import DevicesChart from '@components/pages/analytics/DevicesChart';
import TopCountries from '@components/pages/analytics/TopCountries';
import styles from './styles.module.scss';

const Template = ({
  packsState,
  searchPacks,
  selectAllPacks,
  onSelectPack,
  downloadCommonState,
  priceCommonState,
  setDateInterval,
  datePickerClose,
  selectAllState,
}) => {
  return (
    <div className="analytics">
      <div className="section-wrapper">
        <div className="section analytics__section">
          <Header
            download={downloadCommonState}
            price={priceCommonState}
            setDateInterval={setDateInterval}
            onClose={datePickerClose}
          />
          <SalesChart />

          <PacksProvider packs={packsState}>
            <PacksTable
              search={searchPacks}
              selectAll={selectAllPacks}
              selectPack={onSelectPack}
              selectAllState={selectAllState}
            />
          </PacksProvider>
        </div>

        <div className="analytics__row">
          <DevicesChart />
          <TopCountries />
        </div>
      </div>
    </div>
  );
};

export default Template;