import * as React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './styles.module.scss';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Template({ value, handleChange, leftTab, rightTab, }) {
  return (
    <Tabs
      className={styles.container}
      value={value}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="inherit"
      variant="fullWidth"
      aria-label="full width tabs example"
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
    >
      <Tab label={leftTab} {...a11yProps(0)} />
      <Tab label={rightTab} {...a11yProps(1)} />
    </Tabs>
  );
}
