import { Dispatch, SetStateAction } from 'react';

interface signinFormValidationParams {
  emailValueState: string | null,
  setEmailErrorState: Dispatch<SetStateAction<boolean>>,
  passwordValueState: string | null,
  setPasswordErrorState: Dispatch<SetStateAction<boolean>>,
};
interface ISigninFormEmailValidation {
  emailValueState: string | null,
  setEmailErrorState: Dispatch<SetStateAction<boolean>>,

};
interface ISigninFormPasswordValidation {
  passwordValueState: string | null,
  setPasswordErrorState: Dispatch<SetStateAction<boolean>>,
};

export const signinFormValidation = ({
  emailValueState,
  setEmailErrorState,
  passwordValueState,
  setPasswordErrorState,
}: signinFormValidationParams): boolean => {
  const isEmailFieldValid = signinFormEmailValidation({ emailValueState, setEmailErrorState });
  const isPasswordFieldValid = signinFormPasswordValidation({ passwordValueState, setPasswordErrorState });

  return isEmailFieldValid && isPasswordFieldValid;
}

export const signinFormEmailValidation = (
  { emailValueState, setEmailErrorState, }: ISigninFormEmailValidation
): boolean => {
  if (!emailValueState?.length) {
    setEmailErrorState(true);
    return false;
  }

  setEmailErrorState(false);
  return true;
};

export const signinFormPasswordValidation = (
  { passwordValueState, setPasswordErrorState, }: ISigninFormPasswordValidation
): boolean => {
  if (!passwordValueState?.length) {
    setPasswordErrorState(true);
    return false;
  }

  setPasswordErrorState(false);
  return true;
}