import { makeAutoObservable } from "mobx";
import { apiAnalytics } from "@api";
import { analyticsIncomingAdapter, analyticsPacksIncomingAdapter, } from '@adapters/analytics'

class Analytics {
  analytics = [];
  packs = [];

  constructor() {
    makeAutoObservable(this);
  }

  /* FETCHERS */
  async fetchAnalyticsCommon({ dateFrom, dateTo, packs, }) {
    try {
      if (dateFrom && dateTo) {
        let params = {
          date_from: dateFrom,
          date_to: dateTo,
        };

        if (packs && packs.length) {
          params.packs = packs;
        }

        const response = await apiAnalytics.get(`common`, { params, });

        this.analytics = await analyticsIncomingAdapter({
          data: response.data?.data,
          isMock: false,
        });

        return this.analytics;
      }
    } catch (e) {
      return Object.assign({}, e).response;
    }
  }
  async fetchPacks({ dateFrom, dateTo, search, }) {
    try {
      if (dateFrom && dateTo) {
        const response = await apiAnalytics.get(`packs`, {
          params: {
            search,
            localization: 'en',

            // date_from: dateFrom,
            // date_to: dateTo,

            date_from: "2020-12-25T00:00:00.000Z",
            date_to: "2021-01-15T00:00:00.000Z",
          }
        });

        console.debug('response', response); //DELETE

        // const response = {}; //DELETE

        this.packs = await analyticsPacksIncomingAdapter({
          data: response.data?.data,
          isMock: false,
        });

        return this.packs;
      }
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }

  /* SETTERS */
  async setPacks({ packs, }) {
    return this.packs = packs;
  }
}

export default new Analytics();