import React from "react";
import { AppSkeletonParams } from './AppSkeleton.interfaces';
import './AppSkeleton.scss';

const AppSkeleton = (params: AppSkeletonParams) => {
  return (
    <div className={`app-skeleton ${params.className}`} style={{ height: `${params.height || 48}px` }} />
  );
};

export default AppSkeleton;