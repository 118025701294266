import React from "react";
import classNames from "classnames";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ITableBodyRowProps } from './TableBodyRow.interfaces';
import './TableBodyRow.scss';

const TableBodyRow = (params: ITableBodyRowProps) => {
  const [socialNameState, setSocialNameState] = React.useState<string | null>(params.name || null);
  const [code, setCode] = React.useState<string | null>(params.code || null);

  return (
    <TableRow hover sx={{ cursor: 'pointer' }} onClick={() => params.onClick(params.uuid)}>
      <TableCell className={classNames('socials-settings-table-body-row__cell', 'socials-settings-table-body-row__cell-left')} sx={{ borderBottom: "none", width: '25%', }}>
        <div className="socials-settings-table-body-row__cell-container">
          {socialNameState}
        </div>
      </TableCell>

      <TableCell className={classNames('socials-settings-table-body-row__cell', 'socials-settings-table-body-row__cell-right')} sx={{ borderBottom: "none", width: '25%', }}>
        <div className="socials-settings-table-body-row__cell-container">
          {code}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableBodyRow;