import * as React from 'react';
import { observer } from 'mobx-react-lite';
import classNames from "classnames";
import Dialog from '@store/dialog';
import AppCard from '@presentation/components/AppCard/AppCard';
import usePosition from './hooks/usePosition';
import { Position } from './AppDialog.types';
import './AppDialog.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  classNameCard?: string;
  position?: Position;
  isOpen?: boolean;
}

const AppDialog = observer((params: Props) => {
  const position = usePosition(params.position || Position.Center);

  React.useEffect(() => {
    Dialog.setOpenStatus(!!params.isOpen);
  }, [params.isOpen]);

  if (!params.isOpen) return null;

  return (
    <div className={classNames('app-dialog', params.className)} style={{ top: `${Dialog.offsetTop}px` }}>
      <div className="app-dialog__backdrop" />
      <div className={classNames('app-dialog__main', `app-dialog__main_${position}`)}>
        <AppCard className={classNames('app-dialog__card', params.classNameCard)}>
          {params.children}
        </AppCard>
      </div>
    </div>
  );
});

export default AppDialog;