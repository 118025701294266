import React from 'react';
import { Link, useNavigate, } from "react-router-dom";
import User from '@store/user';
import ARSvg from '@presentation/components/ui-embedded/ARSvg';
import { topMenuItems, bottomMenuItems, } from './data';
import { renderMenuItems } from './services/menuItemRenderers';
import styles from './TheSideBar.module.scss';

const TheSideBar = () => {
  const navigate = useNavigate();

  const logoutHandler = async () => {
    console.debug('presentation/components/TheSideBar/logoutHandler'); //DELETE

    await User.signout();
    navigate('/analytics');
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar__top}>
        <div className={styles.sidebar__logo}>
          <Link to="/" className={styles.sidebar__item}>
            <ARSvg name="ArtlionLogoSidebar" color="#B0B0B0" />
          </Link>
        </div>

        <div className={styles.sidebar__menu_items}>
          {renderMenuItems({ items: topMenuItems })}
        </div>
      </div>

      <div className={styles.sidebar__bottom}>
        <div className={styles.sidebar__menu_items}>
          {renderMenuItems({ items: bottomMenuItems, logout: logoutHandler })}
        </div>
      </div>
    </div>
  );
};

export default TheSideBar;