import { makeAutoObservable } from "mobx";
import { api } from "@api";
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

class StickerPackItem {
  saved = false;
  pack = {
    uuid: null,
    lang: '',
    properties: {
      free: true,
      public: true,
    },
    stickers: [],
    previews: [],
    social_links: [],
    localizations: [],
  };
  stickersToAdd = [];
  stickersToUpdate = [];
  stickersToDelete = [];
  stickersToDisplay = [];
  invalidStickersToAdd = [];

  previewsToAdd = [];
  previewsToDelete = [];

  constructor() {
    makeAutoObservable(this);
  }

  /* FETCHERS */
  async fetch(payload = {}) {
    try {
      const response = await api.get({
        gateway: artlionUkApiGateway,
        url: `admin/packs/${payload.uuid}`,
      });

      return this.pack = response.data;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }

  /* UPLOADERS */
  async updatePack(payload = null) {
    console.debug('updatePack: ', payload); // DELETE

    if (!payload) {
      return;
    }

    try {
      const response = await api.put({
        gateway: artlionUkApiGateway,
        url: `admin/packs/${this.pack.uuid}/update`,
        params: payload,
      });

      console.debug('updatePack[response]: ', response); // DELETE

      return response;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }
  async addStickers({ stickers, }) {
    if (stickers.length) {
      console.debug('addStickers: ', stickers); // DELETE

      const stickersToAdd = new FormData();

      await stickers.forEach((sticker) => {
        stickersToAdd.append('image[]', sticker.file);
        stickersToAdd.append(`${sticker.file.name}_order`, sticker.view.order);
        stickersToAdd.append(`${sticker.file.name}_emoji`, sticker.view.emoji);
      });

      try {
        const responseByAdd = await api.post({
          gateway: artlionUkApiGateway,
          url: `admin/packs/${this.pack.uuid}/uploadImage`,
          params: stickersToAdd,
          properties: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },
        })

        console.debug('addStickers[resp]', responseByAdd.data); // DELETE

        let stickersToUpdate = [];

        await stickers.forEach((sticker, iSticker) => {
          stickersToUpdate.push({
            uuid: responseByAdd.data[iSticker],
            order: sticker.view.order,
            emoji: sticker.view.emoji,
            slug: "",
          });
        });

        const responseByUpdate = await this.updatePack(this.updateStickers({ payload: {}, stickers: stickersToUpdate, }));

        return responseByUpdate;
      }
      catch (e) {
        return Object.assign({}, e).response;
      }
    }
  }
  async deleteStickers({ stickers, }) {
    if (stickers.length) {
      console.debug('deleteStickers: ', stickers); // DELETE

      await this.deleteMedia({ data: stickers });
    }
  }
  updateStickers({ payload, stickers, }) {
    if (stickers.length) {
      console.debug('updateStickers: ', stickers); // DELETE

      return {
        ...payload,
        stickers,
      };
    } else {
      return payload;
    }
  }
  async addPreviews({ previews, }) {
    if (previews.length) {
      console.debug('addPreviews: ', previews); // DELETE

      const previewsToAdd = new FormData();

      await previews.forEach((preview) => {
        previewsToAdd.append('file[]', preview.file);
      });

      try {
        const responseByAdd = await api.post({
          gateway: artlionUkApiGateway,
          url: `admin/packs/${this.pack.uuid}/uploadPreview`,
          params: previewsToAdd,
          properties: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })

        console.debug('addPreviews[resp]', responseByAdd.data); // DELETE

        let previewsToUpdate = [];

        await previews.forEach((preview, iPreview) => {
          previewsToUpdate.push({
            uuid: responseByAdd.data[iPreview],
            slug: preview.view.slug,
          });
        });

        const responseByUpdate = await this.updatePack(this.updatePreviews({ payload: {}, previews: previewsToUpdate, }));

        return responseByUpdate;
      }
      catch (e) {
        return Object.assign({}, e).response;
      }
    }
  }
  async deletePreviews({ previews, }) {
    if (previews.length) {
      console.debug('deletePreviews: ', previews); // DELETE

      await this.deleteMedia({ data: previews });
    }
  }
  updatePreviews({ payload, previews, }) {
    if (previews.length) {
      return {
        ...payload,
        previews,
      };
    } else {
      return payload;
    }
  }
  updateSocialLinks({ payload, social_links, }) {
    if (social_links) {
      return {
        ...payload,
        social_links,
      };
    } else {
      return payload;
    }
  }
  updateProperties({ payload, properties, }) {
    if (properties) {
      return {
        ...payload,
        properties,
      };
    } else {
      return payload;
    }
  }
  updateDefault({ payload, }) {
    return {
      ...payload,
      name: this.pack.name,
      author_uuid: this.pack.author_uuid,
      description: this.pack.description,
      category: this.pack.category,
      country: this.pack.country,
      created_at: this.pack.created_at,
      ads_banner: this.pack.ads_banner,
      copyright: this.pack.copyright,
      localizations: this.pack.localizations,
      telegram: this.pack.telegram,
      signal: this.pack.signal,
      id_pay: this.pack.id_pay,
      lang: this.pack.lang,
    };
  }

  /* HELPERS */
  async swapStickersOrder(payload = {}) {
    console.log('swapStickersOrder[start]', payload); // DELETE

    this.stickersToDisplay = this.stickersToDisplay.map((s) => {
      if (s.original_url === payload.over.original_url) {
        return {
          ...s,
          order: payload.under.order,
        }
      }
      if (s.original_url === payload.under.original_url) {
        return {
          ...s,
          order: payload.over.order,
        }
      }

      return s;
    });

    console.debug('swapStickersOrder[end]', this.pack.stickers, this.stickersToAdd); // DELETE
  }
  async parseStickersToDisplay(payload = {}) {
    console.debug('parseStickersToDisplay'); // DELETE

    this.stickersToDisplay = [];

    console.debug( // DELETE
      'this.pack.stickers', this.pack.stickers,
      'this.stickersToAdd', this.stickersToAdd
    );

    for (let iSticker = 0; iSticker < this.pack.stickers.length; iSticker++) {
      console.debug('this.pack.stickers', this.pack.stickers[iSticker]); // DELETE

      this.stickersToDisplay.push({
        ...this.pack.stickers[iSticker]
      });
    }
    for (let iSticker = 0; iSticker < this.stickersToAdd.length; iSticker++) {
      console.debug('this.stickersToAdd', this.stickersToAdd[iSticker]); // DELETE

      this.stickersToDisplay.push({
        ...this.stickersToAdd[iSticker].view
      });
    }
  }
  async initOrder() {
    console.debug('initOrder'); // DELETE

    this.stickersToDisplay.sort(this.sortStickers);

    for (let iSticker = 0; iSticker < this.stickersToDisplay.length; iSticker++) {
      this.stickersToDisplay[iSticker].order = iSticker + 1;

      if (this.stickersToDisplay[iSticker].uuid) {
        console.debug('initOrder[have uuid]'); // DELETE

        this.pack.stickers = this.pack.stickers.map((sticker) => {
          if (sticker.uuid === this.stickersToDisplay[iSticker].uuid) {
            return {
              ...sticker,
              order: this.stickersToDisplay[iSticker].order,
            }
          }

          return sticker;
        });
      } else {
        console.debug('initOrder[dont have uuid]'); // DELETE

        this.stickersToAdd = this.stickersToAdd.map((stickerToAdd) => {
          if (stickerToAdd.view.original_url === this.stickersToDisplay[iSticker].original_url) {
            return {
              ...stickerToAdd,
              view: {
                ...stickerToAdd.view,
                order: this.stickersToDisplay[iSticker].order,
              },
            }
          }

          return stickerToAdd;
        });
      }
    }

    console.debug('initOrder', this.stickersToDisplay); // DELETE
  }
  async syncOrder() {
    this.stickersToDisplay.forEach((stickerToDisplay) => {
      if (stickerToDisplay.uuid) {
        console.debug('syncOrder[have uuid]'); // DELETE

        this.pack.stickers = this.pack.stickers.map((sticker) => {
          if (sticker.uuid === stickerToDisplay.uuid) {
            return {
              ...sticker,
              order: stickerToDisplay.order,
            }
          }

          return sticker;
        });
      } else {
        console.debug('syncOrder[dont have uuid]'); // DELETE

        this.stickersToAdd = this.stickersToAdd.map((stickerToAdd) => {
          if (stickerToAdd.view.original_url === stickerToDisplay.original_url) {
            return {
              ...stickerToAdd,
              view: {
                ...stickerToAdd.view,
                order: stickerToDisplay.order,
              },
            }
          }

          return stickerToAdd;
        });
      }
    });
  }
  sortStickers(a, b) {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  }
  sortStickersToAdd(a, b) {
    if (a.view.order > b.view.order) {
      return 1;
    } else {
      return -1;
    }
  }

  /* STORE SETTERS */
  async reset(payload = {}) {
    console.debug('RESET'); // DELETE

    this.saved = false;
    this.pack = {
      properties: [],
      stickers: [],
      previews: [],
      social_links: [],
      localizations: [],
    };
    this.stickersToDisplay = [];
    this.stickersToAdd = [];
    this.invalidStickersToAdd = [];
  }
  async setStickersToAdd(payload = {}) {
    console.log('setStickersToAdd', payload); // DELETE

    if (
      payload.sticker.width === 512
      &&
      payload.sticker.height === 512
    ) {
      this.stickersToAdd.push({
        file: payload.file,
        view: {
          original_url: payload.sticker.src,
          order: this.stickersToAdd.length + this.pack.stickers.length + 1,
          emoji: [],
        },
      });
    } else {
      this.invalidStickersToAdd.push(payload);
    }
  }
  async setStickersToUpdate({ stickers, }) {
    console.debug('setStickersToUpdate', stickers); // DELETE

    this.stickersToUpdate = stickers;
  }
  async setPreviewsToAdd({ file, preview, slug, width, height, }) {
    console.log('setPreviewsToAdd', { file, preview, slug, width, height }); // DELETE

    if (
      preview.width === width
      &&
      preview.height === height
    ) {
      this.previewsToAdd.push({
        file: file,
        view: {
          original_url: preview.src,
          slug,
        },
      });
      this.pack.previews.push({
        original_url: preview.src,
        slug,
      });
    }
  }
  async setUuid(uuid = null) {
    this.setStore({ uuid, });
  }
  async setName(name = null) {
    this.setStore({ name });
  }
  async setTelegram(telegram = null) {
    this.setStore({ telegram });
  }
  async setSignal(signal = null) {
    this.setStore({ signal });
  }
  async setAdsBanner(ads_banner = null) {
    this.setStore({ ads_banner });
  }
  async setPropertiesIdentifierPay(identifierPay = null) {
    // this.setStore({
    //   properties: {
    //     ...this.pack.properties,
    //     identifierPay,
    //   }
    // });

    this.setStore({ id_pay: identifierPay });
  }
  async setPropertiesCode(code = null) {
    this.setStore({
      properties: {
        ...this.pack.properties,
        code,
      }
    });
  }
  async setPropertiesCommission(commission = null) {
    this.setStore({
      properties: {
        ...this.pack.properties,
        commission,
      }
    });
  }
  async setPropertiesLocation(location) {
    this.setStore({
      properties: {
        ...this.pack.properties,
        location,
      }
    });
  }
  async setPropertiesFree(free) {
    this.setStore({
      properties: {
        ...this.pack.properties,
        free,
      }
    });
  }
  async setPropertiesPublic(value) {
    this.setStore({
      properties: {
        ...this.pack.properties,
        public: value,
      }
    });
  }
  async setSocialLink({ url, order, name, slug, }) {
    if (this.pack.social_links.findIndex(link => Number(link.order) === Number(order)) !== -1) {
      this.setStore({
        social_links: [
          ...this.pack.social_links.map((social_link) => {
            if (Number(social_link.order) === Number(order)) {
              return {
                url: url ? url : social_link.url,
                order: order ? order : social_link.order,
                name: name ? name : social_link.name,
                slug: slug ? slug : social_link.slug,
              };
            } else {
              return social_link;
            }
          }),
        ]
      });
    } else {
      this.setStore({
        social_links: [
          ...this.pack.social_links,
          {
            url, order, name, slug,
          },
        ],
      });
    }
  }
  async setCategory(category = null) {
    console.debug('setCategoryt', category);

    if (!category) return;

    this.setStore({ category });
  }
  async setCountry(country = null) {
    console.debug('setCountry', country);

    if (!country) return;

    this.setStore({ country });
  }
  async setAuthor(author = null) {
    console.debug('setAuthor', author); //DELETE

    if (!author) return;

    this.setStore({ author_uuid: author.uuid });
  }
  async setCopyright({ copyright, }) {
    console.debug('set copyright', copyright);
    this.setStore({ copyright });
  }
  async setLocalizations({ location, name, description, }) {
    if (
      this.pack.localizations.findIndex(localization => localization.location === location) !== -1
    ) {
      this.setStore({
        localizations: [
          ...this.pack.localizations.map((localization) => {
            if (localization.location === location) {
              return {
                location: location !== null ? location : localization.location,
                name: name !== null ? name : localization.name,
                description: description !== null ? description : localization.description,
              };
            } else {
              return localization;
            }
          }),
        ]
      });
    } else {
      this.setStore({
        localizations: [
          ...this.pack.localizations,
          {
            location,
            name,
            description,
          },
        ],
      });
    }
  }
  async setStore(payload = {}) {
    this.pack = {
      ...this.pack,
      ...payload,
    };
  }

  /* ACTIONS */
  deleteSticker = async (payload = {}) => {
    if (payload.sticker.uuid) {
      this.pack.stickers = this.pack.stickers.filter(
        sticker => sticker.uuid !== payload.sticker.uuid
      );

      this.stickersToDelete.push(payload.sticker.uuid);
    } else {
      this.stickersToAdd = this.stickersToAdd.filter(
        stickerToAdd => stickerToAdd.view.original_url !== payload.sticker.original_url
      );
    }
  }
  deletePreview = async ({ slug, }) => {
    this.pack.previews = this.pack.previews.filter((preview) => {
      if (preview.slug === slug) {
        if (preview.uuid) {
          this.previewsToDelete.push(preview.uuid);
        }

        return false;
      }

      return true;
    });
  }
  addEmojiToSticker = async ({ sticker, emoji, }) => {
    if (sticker.uuid) {
      this.pack.stickers = this.pack.stickers.map((itemSticker) => {
        if (
          itemSticker.uuid === sticker.uuid &&
          itemSticker.emoji.length < 3 &&
          !itemSticker.emoji.filter(
            itemEmoji => itemEmoji.emoji === emoji.emoji
          ).length
        ) {
          return {
            ...itemSticker,
            emoji: [
              ...itemSticker.emoji,
              { emoji: emoji.emoji, },
            ],
          };
        }

        return {
          ...itemSticker,
        };
      });
    } else {
      this.stickersToAdd = this.stickersToAdd.map((itemSticker) => {
        if (
          itemSticker.view.original_url === sticker.original_url &&
          itemSticker.view.emoji.length < 3 &&
          !itemSticker.view.emoji.filter(
            itemEmoji => itemEmoji.emoji === emoji.emoji
          ).length
        ) {
          return {
            ...itemSticker,
            view: {
              ...itemSticker.view,
              emoji: [
                ...itemSticker.view.emoji,
                { emoji: emoji.emoji, },
              ],
            }
          };
        }

        return {
          ...itemSticker,
        };
      });
    }
  }
  deleteEmojiFromSticker = async ({ sticker, emoji, }) => {
    if (sticker.uuid) {
      this.pack.stickers = this.pack.stickers.map((itemSticker) => {
        if (itemSticker.uuid === sticker.uuid) {
          return {
            ...itemSticker,
            emoji: itemSticker.emoji.filter(
              itemEmoji => itemEmoji.emoji !== emoji.emoji
            ),
          };
        }

        return {
          ...itemSticker,
        };
      });
    } else {
      this.stickersToAdd = this.stickersToAdd.map((itemSticker) => {
        if (itemSticker.view.original_url === sticker.original_url) {
          return {
            ...itemSticker,
            view: {
              ...itemSticker.view,
              emoji: itemSticker.view.emoji.filter(
                itemEmoji => itemEmoji.emoji !== emoji.emoji
              ),
            }
          };
        }

        return {
          ...itemSticker,
        };
      });
    }
  }
  downloadSticker = async (payload = {}) => {
    console.debug('deleteSticker', payload); // DELETE
  }

  /* SERVICE ACTIONS */
  async deleteMedia({ data, }) {
    try {
      const response = await api.delete({
        gateway: artlionUkApiGateway,
        url: `admin/media/delete`,
        properties: {
          data,
        }
      });

      return response;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }
  async publish() {
    console.debug('PUBLISH PACK'); // DELETE

    if (this.pack.uuid) {
      try {
        const response = await api.put({
          gateway: artlionUkApiGateway,
          url: `admin/packs/${this.pack.uuid}/publish`,
        });

        return response;
      }
      catch (e) {
        return Object.assign({}, e).response;
      }
    }
  }
  async unpublish() {
    console.debug('UNPUBLISH PACK'); // DELETE

    if (this.pack.uuid) {
      try {
        const response = await api.put({
          gateway: artlionUkApiGateway,
          url: `admin/packs/${this.pack.uuid}/unpublish`,
        });

        return response;
      }
      catch (e) {
        return Object.assign({}, e).response;
      }
    }
  }
  async create() {
    console.debug('CREATE PACK'); // DELETE

    try {
      const response = await api.post({
        gateway: artlionUkApiGateway,
        url: `admin/packs`,
      });

      return this.pack.uuid = response.data.uuid;
    } catch (e) {
      return Object.assign({}, e).response;
    }
  }
  async save() {
    console.debug('SAVE PACK'); // DELETE

    if (!this.pack.uuid) {
      await this.create();
    }

    await this.addStickers({ stickers: this.stickersToAdd });
    await this.addPreviews({ previews: this.previewsToAdd });
    await this.updatePack(
      this.updateDefault({
        payload: this.updateStickers({
          stickers: this.stickersToUpdate,
          payload: this.updateSocialLinks({
            social_links: this.pack.social_links,
            payload: this.updateProperties({
              properties: this.pack.properties,
              payload: {},
            }),
          }),
        })
      }),
    );
    await this.deleteStickers({ stickers: this.stickersToDelete });
    await this.deletePreviews({ previews: this.previewsToDelete });

    this.stickersToAdd = [];
    this.previewsToAdd = [];
    this.stickersToUpdate = [];
    this.stickersToDelete = [];
    this.previewsToDelete = [];
    this.saved = true;

    return;
  }
}

export default new StickerPackItem();