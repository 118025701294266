export const getRandomInt = (min, max) => {
  const MIN = Math.ceil(min);
  const MAX = Math.floor(max);

  return Math.floor(Math.random() * (MAX - MIN)) + MIN;
}
export const getPercent = (value, total) => {
  return (value / total) * 100;
};
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });

  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}