import { makeAutoObservable } from 'mobx';

export default class StoreState {
  value: string | null = null;
  searchValue: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async setValue(value: string | null) {
    console.debug('AppSelectSearch/store/StoreState/setValue/value', value); //DELETE
    this.value = value;
  }
  async setSearchValue(value: string | null) {
    console.debug('AppSelectSearch/store/StoreState/setSearchValue/value', value); //DELETE
    this.searchValue = value;
  }
}