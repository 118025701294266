import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '@presentation/components/TheSideBar/TheSideBar.index';
import TheLocalization from '@presentation/components/TheLocalization/TheLocalization.index';
import TheCopyright from '@presentation/components/TheCopyright/TheCopyright.index';
import styles from './WorkspaceLayout.module.scss';

const WorkspaceLayout = () => {
  return (
    <div className={styles.workspace_layout}>
      <div className={styles.workspace_layout__left_bar}>
        <Sidebar />
      </div>

      <div className={styles.workspace_layout__middle_bar}>
        <div className={styles.workspace_layout__outlet}>
          <Outlet />
        </div>

        <TheCopyright />
      </div>

      <div className={styles.workspace_layout__right_bar}>
        <TheLocalization />
      </div>
    </div>
  );
};

export default WorkspaceLayout;