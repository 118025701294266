import React from 'react';
import classNames from "classnames";
import TextField from '@mui/material/TextField';
import { Props } from './AppTextField.interfaces';
import { inputHandler } from './AppTextField.handlers';
import { AppTextFieldInputEvent, AppTextFieldVariant, AppTextFieldVariants, } from './AppTextField.types';
import InputAdornment from '@mui/material/InputAdornment';
import './AppTextField.scss';

const getInputAdornment = (value: any) => {
  return (
    <InputAdornment position="start">
      {value}
    </InputAdornment>
  );
};

const getVariant = (variant: AppTextFieldVariant) => {
  const classNode = 'artextfield-variant__';

  switch (variant) {
    case AppTextFieldVariants.Standard:
      return classNode + AppTextFieldVariants.Standard;
    case AppTextFieldVariants.Outlined:
      return classNode + AppTextFieldVariants.Outlined;
    default:
      return classNode + AppTextFieldVariants.Standard;
  }
};

const AppTextField = (params: Props) => {
  React.useEffect(() => {
  }, []);

  const onClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (params.onClick) params.onClick(e);
  };

  return (
    <TextField
      className={classNames('artextfield', getVariant(params.variant), `artextfield-theme__${params.theme}`, params.className)}
      label={params.label}
      variant="outlined"
      fullWidth={params.fullWidth}
      error={params.error}
      helperText={params.helperText}
      onChange={(event: AppTextFieldInputEvent) => { inputHandler({ event, onInput: params.onInput }) }}
      value={params.value || ''}
      type={params.type}
      InputProps={{
        startAdornment: getInputAdornment(params.inputAdornment || ''),
      }}
      onClick={onClick}
      sx={{
        '& .MuiInputBase-root': {
          height: '48px',
        }
      }}
    />
  );
};

export default AppTextField;