export const percentData = {
  ios: {
    percent: 0,
  },
  android: {
    percent: 0,
  },
};
export const chartData = {
  labels: [],
  datasets: [{
    label: "DevicesChart",
    data: [],
    backgroundColor: ["#000000", "#82EE95"],
    borderColor: ["#000000", "#82EE95"],
    borderWidth: 1,
    cutout: "85%",
  }],
};