import { accountsMock } from '@mocks/accounts';
import { getCurrentTimestampInMillseconds, } from '@utils/date';

const accountsAdapter = ({ data, isMock, }) => {
  console.debug('accountsAdapter', { data, isMock, }); //DELETE

  if (isMock) {
    return accountsMock({
      count: 30,
      startDate: getCurrentTimestampInMillseconds(),
    });
  }

  return data;
};

export default accountsAdapter;