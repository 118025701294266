import { makeAutoObservable } from "mobx";
import Cookies from 'js-cookie';
import { api } from "@api";

class Auth {
  authData = {};

  constructor() {
    makeAutoObservable(this);
  }

  async signin(payload = {}) {
    console.debug( "signin", this.authData ); // DELETE

    try {
      const response = await api.post('auth/login', this.authData);

      console.log( 'signin[response]', response.data ); //DELETE

      Cookies.set('token', response.data.token);
      Cookies.set('uuid', response.data.uuid);

      return response;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }

  async signout(payload = {}) {
    //console.debug( "signout", payload ); // DELETE

    Cookies.set('token', '');
    Cookies.set('uuid', '');
  }

  async setAuthData(payload = {}) {
    console.debug( "setAuthData", payload ); // DELETE

    this.authData = {
      ...this.authData,
      ...payload,
    };
  }

  isAuth() {
    console.log('isAuth: ', Cookies.get('token')); // DELETE

    return Boolean(Cookies.get('token'));
  }
}

export default new Auth();