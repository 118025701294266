import Avatar from '@mui/material/Avatar';

export const renderAvatars = ({ avatars, }) => {
  return avatars.map((avatar, index) => (
    <Avatar
      alt={avatar.name}
      src={avatar.preview}
      key={avatar.uuid}
      sx={{ zIndex: index }}
    />
  ));
};