import React from 'react';
import Template from './template';

const ARCard = ({ children, className }) => {
  return (
    <Template className={className}>
      {children}
    </Template>
  );
};

export default ARCard;