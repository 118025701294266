import { useState, useEffect } from 'react';
import classNames from "classnames";

export default function useGetFieldClasses(isOpen: boolean) {
  const [state, setState] = useState('');

  useEffect(() => {
    setState(classNames(
      'app-select-search__card',
      'app-select-search__card-field'
    ));
  }, [isOpen]);

  return state;
}