import React, { useState, useEffect, useRef, } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from "lodash";
import { fetchPacks, } from './services/packsService';
import { fetchAnalyticsCommon, } from './services/analyticsService';
import { selectAllPacks, selectPack, getSelectedPacks, } from './services/packsTableService';
import Template from './template';


const Analytics = observer(() => {
  const packsStateDefault = [];
  const searchPacksStateDefault = null;
  const analyticsStateDefault = {};
  const dateIntervalDefault = {
    dateFrom: '2020-12-25T00:00:00.000Z',
    dateTo: '2021-01-15T00:00:00.000Z',
  };
  const downloadCommonStateDefault = 0;
  const priceCommonStateDefault = 0;
  const selectAllStateDefault = false;

  const [packsState, setPacksState] = useState(packsStateDefault);
  const [selectAllState, setSelectAllState] = useState(selectAllStateDefault);
  const [searchPacksState, setSearchPacksState] = useState(searchPacksStateDefault);
  const [analyticsState, setAnalyticsState] = useState(analyticsStateDefault);
  const [dateIntervalState, setDateIntervalState] = useState(dateIntervalDefault);
  const [downloadCommonState, setDownloadCommonState] = useState(downloadCommonStateDefault);
  const [priceCommonState, setPriceCommonState] = useState(priceCommonStateDefault);

  useEffect(async () => {
    await fetchAnalyticsData();
  }, []);
  useEffect(async () => {
    console.debug('packsState change', packsState); //DELETE

    setDownloadCommonState(34);
    setPriceCommonState(325);
  }, [packsState]);
  useEffect(async () => {
    console.debug('SELECT ALL', selectAllState); //DELETE
  }, [selectAllState]);

  const datePickerClose = async () => {
    await fetchAnalyticsData();
  };
  const setDateInterval = ({ value }) => {
    if (
      value[0]?.toISOString() &&
      value[1]?.toISOString() &&
      (
        (dateIntervalState.dateFrom !== value[0].toISOString()) ||
        (dateIntervalState.dateTo !== value[1].toISOString())
      )
    ) {
      setDateIntervalState({
        dateFrom: value[0].toISOString(),
        dateTo: value[1].toISOString(),
      });
    }
  };
  const fetchAnalyticsData = async () => {
    setPacksStateDebounce({ dateIntervalState });
    setAnalyticsStateDebounce({ dateIntervalState });
  };
  const searchPacks = debounce(async (value) => {
    if (value !== searchPacksState) {
      setSearchPacksState(value);
      setPacksState(await fetchPacks({
        ...dateIntervalState,
        search: value,
      }));
    }
  }, 400);
  const onSelectPack = async (data) => {
    const packs = await selectPack(data);

    setPacksState(packs);
    checkSelectingAllPacks();
    setAnalyticsStateDebounce({
      dateIntervalState,
      packs: await getSelectedPacks(),
    });
  }
  const onSelectAllPacks = async (data) => {
    const packs = await selectAllPacks(data);

    console.debug('onSelectAllPacks::packs', packs); //DELETE

    setPacksState(packs);
  };
  const checkSelectingAllPacks = async () => {
    const selectedPacks = await getSelectedPacks();

    console.debug(
      'checkSelectingAllPacks',
      selectedPacks.length === packsState.length,
      selectedPacks.length,
      packsState.length
    ); //DELETE

    setSelectAllState(
      selectedPacks.length === packsState.length
    );
  };

  const setAnalyticsStateDebounce = useRef(debounce(async ({ dateIntervalState, packs, }) => {
    console.debug('setAnalyticsStateDebounce', packs); //DELETE

    setAnalyticsState(await fetchAnalyticsCommon({ ...dateIntervalState, packs, }));
  }, 500)).current;
  const setPacksStateDebounce = debounce(async () => {
    console.debug('setPacksStateDebounce'); //DELETE

    setPacksState(await fetchPacks(dateIntervalState));
  }, 500);

  return (
    <Template
      packsState={packsState}
      searchPacks={searchPacks}
      selectAllPacks={onSelectAllPacks}
      onSelectPack={onSelectPack}
      downloadCommonState={downloadCommonState}
      priceCommonState={priceCommonState}
      setDateInterval={setDateInterval}
      datePickerClose={datePickerClose}
      selectAllState={selectAllState}
    />
  );
});

export default Analytics;