import React from 'react';
import stickerImg   from '@assets/img/stickers/img.jpg';
import addImg from '@assets/img/add.svg';

const Finance = () => {
  return (
    <>
      <div className="finance">
        <div className="overlay"></div>

        <div className="section-wrapper">
          <div className="section create__section">
            <div className="section__header">
              <a href="#" className="button section__button button_calendar">2021</a>
            </div>

            <table className="total-table finance__total-table">
              <thead className="total-table__thead">
                <tr>
                  <td>Весь доход</td>
                  <td>Выплачено</td>
                  <td>К выплате</td>
                </tr>
              </thead>
              <tbody className="total-table__tbody">
                <tr>
                  <td>&#36;13.2 К</td>
                  <td>&#36;12.2 К</td>
                  <td>&#36;1 К</td>
                </tr>
              </tbody>
            </table>

            <div className="finance__content">
              <div className="finance-table finance__finance-table scroll scroll_x scroll_margin-none">
                <table className="finance-table__thead">
                  <tr>
                    <td>Дата</td>
                    <td>Сумма выплаты</td>
                    <td>Сумма фактическая</td>
                    <td></td>
                    <td>Номер вывленного счета</td>
                  </tr>
                </table>

                <div className="finance-table__wrapper scroll scroll_margin-none">
                  <table className="finance-table__table">
                    <tbody>
                      <tr>
                        <td>24.01.21</td>
                        <td>&#36;100</td>
                        <td>740 руб.</td>
                        <td></td>
                        <td>№1300234</td>
                      </tr>
                      
                      <tr>
                        <td>24.01.21</td>
                        <td>&#36;100</td>
                        <td>740 руб.</td>
                        <td></td>
                        <td>№1300234</td>
                      </tr>
                      
                      <tr>
                        <td>24.01.21</td>
                        <td>&#36;100</td>
                        <td>740 руб.</td>
                        <td></td>
                        <td>№1300234</td>
                      </tr>
                      
                      <tr>
                        <td>24.01.21</td>
                        <td>&#36;100</td>
                        <td>740 руб.</td>
                        <td></td>
                        <td>№1300234</td>
                      </tr>
                      
                      <tr>
                        <td>24.01.21</td>
                        <td>&#36;100</td>
                        <td>740 руб.</td>
                        <td></td>
                        <td>№1300234</td>
                      </tr>
                      
                      <tr>
                        <td>24.01.21</td>
                        <td>&#36;100</td>
                        <td>740 руб.</td>
                        <td></td>
                        <td>№1300234</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="stickers-table finance__stickers-table scroll scroll_x">
                <table className="stickers-table__thead">
                  <tr>
                    <td>
                      <label className="radio">
                        <input className="radio__input" type="checkbox" name="all" />
                        <span className="radio__pseudo-radio"></span>
                      </label>
                    </td>
                    <td></td>
                    <td>
                      <span>Название</span>
                      <div className="search stickers-table__search"><input className="search__input" type="search" /></div>
                    </td>
                    <td>Весь доход</td>
                    <td>Выплачено</td>
                    <td>К выплате</td>
                    <td></td>
                  </tr>
                </table>
                
                  <table className="stickers-table__tbody">
                    <tr>
                      <td>
                        <label className="radio">
                          <input className="radio__input" type="checkbox" name="sticker" />
                          <span className="radio__pseudo-radio"></span>
                        </label>
                      </td>
                      <td><img src={ stickerImg } alt="Sticker icon" /></td>
                      <td><span>QUPPY</span></td>
                      <td>&#36;7.5 К</td>
                      <td>&#36;500</td>
                      <td>&#36;7 К</td>
                      <td>
                        <button className="add-button add-button_small show-modal"><img src={ addImg } alt="" /></button>
                      </td>
                    </tr>
              
                    <tr>
                      <td>
                        <label className="radio">
                          <input className="radio__input" type="checkbox" name="sticker" />
                          <span className="radio__pseudo-radio"></span>
                        </label>
                      </td>
                      <td><img src={ stickerImg }alt="Sticker icon" /></td>
                      <td><span>QUPPY</span></td>
                      <td>&#36;7.5 К</td>
                      <td>&#36;500</td>
                      <td>&#36;7 К</td>
                      <td>
                        <button className="add-button add-button_small show-modal"><img src={ addImg } alt="" /></button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="radio">
                          <input className="radio__input" type="checkbox" name="sticker" />
                          <span className="radio__pseudo-radio"></span>
                        </label>
                      </td>
                      <td><img src={ stickerImg }alt="Sticker icon" /></td>
                      <td><span>QUPPY</span></td>
                      <td>&#36;7.5 К</td>
                      <td>&#36;500</td>
                      <td>&#36;7 К</td>
                      <td>
                        <button className="add-button add-button_small show-modal"><img src={ addImg } alt="" /></button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="radio">
                          <input className="radio__input" type="checkbox" name="sticker" />
                          <span className="radio__pseudo-radio"></span>
                        </label>
                      </td>
                      <td><img src={ stickerImg }alt="Sticker icon" /></td>
                      <td><span>QUPPY</span></td>
                      <td>&#36;7.5 К</td>
                      <td>&#36;500</td>
                      <td>&#36;7 К</td>
                      <td>
                        <button className="add-button add-button_small show-modal"><img src={ addImg } alt="" /></button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="radio">
                          <input className="radio__input" type="checkbox" name="sticker" />
                          <span className="radio__pseudo-radio"></span>
                        </label>
                      </td>
                      <td><img src={ stickerImg } alt="Sticker icon" /></td>
                      <td><span>QUPPY</span></td>
                      <td>&#36;7.5 К</td>
                      <td>&#36;500</td>
                      <td>&#36;7 К</td>
                      <td>
                        <button className="add-button add-button_small show-modal"><img src={ addImg } alt="" /></button>
                      </td>
                    </tr>
                  </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-overlay">
        <div className="modal">
          <div className="modal__content">
            <table className="modal__table ">
              <thead className="modal__thead">
                <tr>
                  <td>Дата</td>
                  <td>Выплата в &#36;</td>
                  <td>Фактическая выплата</td>
                  <td>Номер счета</td>
                </tr>
              </thead>
            
              <tbody className="modal__tbody">
                <tr>
                  <td><input type="text" className="input modal__input" placeholder="16.06.21" /></td>
                  <td><input type="text" className="input modal__input" placeholder="1000" /></td>
                  <td>
                    <div className="modal__payment">
                      <input type="text" className="input modal__input" placeholder="74000" />
                      <div className="select" id="procent-select">
                        <p className="select__button accounts__select"></p>
                        <ul className="select__list"></ul>
                      </div>
                    </div>
                  </td>
                <td><input type="text" className="input modal__input" placeholder="№131700" /></td>

                </tr>
              </tbody>
            </table>

            <div className="modal__buttons">
              <button className="simple-button simple-button_light" id="close-modal">Отменить</button>
              <button className="simple-button">Принять</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Finance;