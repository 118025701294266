import { api } from '@api';
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

export const languagesGet = async (): Promise<any> => {
  const response = await api.get({
    gateway: artlionUkApiGateway,
    url: 'dictionaries/languages',
  });

  return response;
};

export const languagesPost = async (payload: any): Promise<any> => {
  const response = await api.post({
    gateway: artlionUkApiGateway,
    url: 'dictionaries/languages',
    params: payload,
  });

  return response;
};

export const languageGet = async (uuid: Uuid): Promise<any> => {
  const response = await api.get({
    gateway: artlionUkApiGateway,
    url: `dictionaries/languages/${uuid}`,
  });

  return response;
};