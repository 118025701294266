import React from 'react';
import './ARTable.scss';
import { IARTableParams } from './ARTable.interfaces';
import { renderARTable } from './ARTable.renderers';
import { getARTableInlineStyles } from './ARTable.getters';

const ARTable = (params: IARTableParams) => {
  const getARTableInlineStylesParams = {
    width: params.width,
    minWidth: params.minWidth,
    maxWidth: params.maxWidth,
  };

  return (
    <div className={`artable ${params.className}`} style={getARTableInlineStyles(getARTableInlineStylesParams)}>
      {renderARTable(params)}
    </div>
  );
};

export default ARTable;