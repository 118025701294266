export const topMenuItems: Array<MenuItem> = [
  {
    iconName: 'sticker',
    title: '',
    route: '/',
  },
  {
    iconName: 'chart',
    title: '',
    route: 'analytics',
  },
  {
    iconName: 'money',
    title: '',
    route: 'finance',
  },
  {
    iconName: 'account',
    title: '',
    route: 'accounts',
  },
  {
    iconName: 'directory',
    title: '',
    route: 'settings/data',
  },
];

export const bottomMenuItems: Array<MenuItem> = [
  {
    iconName: 'settings',
    title: '',
    route: 'settings',
  },
  {
    iconName: 'logout',
    title: '',
    route: '',
  },
];