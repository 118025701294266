import React from 'react';
import Template from './template';

const TwoTabsBtn = ({ value, handleChange, leftTab, rightTab }) => {
  return (
    <Template
      value={value}
      handleChange={handleChange}
      leftTab={leftTab}
      rightTab={rightTab}
    />
  );
};

export default TwoTabsBtn;