import React from "react";
import AppButtonSave from '@presentation/components/AppButtonSave/AppButtonSave';
import AppButtonAdd from '@presentation/components/AppButtonAdd/AppButtonAdd';
import { ITableHeaderParams } from './TableHeader.interfaces';
import './TableHeader.scss';

const TableHeader = (params: ITableHeaderParams) => {
  const [stubState, setStubState] = React.useState<boolean>(true);
  const [saveLoadingState, setSaveLoadingState] = React.useState<boolean>(false);

  React.useEffect(() => {
    console.debug('pages/settingsData/SocialsSettings/TableHeader/useEffect/stub', params.stub); //DELETE

    setStubState(!!params.stub);
  }, [params.stub]);
  React.useEffect(() => {
    console.debug('pages/settingsData/SocialsSettings/TableHeader/useEffect/saveLoading', params.saveLoading); //DELETE

    setSaveLoadingState(!!params.saveLoading);
  }, [params.saveLoading]);

  return (
    <div className="socials-settings-table-header">
      <AppButtonSave className="socials-settings-table-header__btn-save" onClick={params.save} disabled={stubState || params.saveDisabled} loading={saveLoadingState} />
      <AppButtonAdd className="socials-settings-table-header__btn-add" onClick={params.add} disabled={stubState || saveLoadingState} />
    </div>
  );
};

export default TableHeader;