import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ className, color, width, height, onClick, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg className={className} onClick={() => { if (onClick) { onClick() } }} width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.36 1.2L8.58667 0H13.4933L14.72 1.2H20.8533C21.5305 1.2 22.08 1.7376 22.08 2.4C22.08 3.0624 21.5305 3.6 20.8533 3.6H1.22667C0.549547 3.6 0 3.0624 0 2.4C0 1.7376 0.549547 1.2 1.22667 1.2H7.36ZM3.56021 21.8789L1.65073 6H20.4293L18.5198 21.8789C18.3763 23.0873 17.3295 24 16.0832 24H5.99917C4.75287 24 3.70496 23.0885 3.56021 21.8789ZM8.58667 8.4C9.26379 8.4 9.81333 8.9376 9.81333 9.6V20.4H8.58667C7.90955 20.4 7.36 19.8624 7.36 19.2V9.6C7.36 8.9376 7.90955 8.4 8.58667 8.4ZM14.72 9.6C14.72 8.9376 14.1705 8.4 13.4933 8.4C12.8162 8.4 12.2667 8.9376 12.2667 9.6V20.4H13.4933C14.1705 20.4 14.72 19.8624 14.72 19.2V9.6Z" fill={colorState} />
    </svg>
  );
};

export default ArtlionLogoVertical;