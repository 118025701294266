import React from 'react';
import DatePickerBtn from '@components/composite/DatePickerBtn';
// import { chartData, } from './services/data';
import './styles.scss';

const Header = ({
  download,
  price,
  setDateInterval,
  onClose,
}) => {
  return (
    <div>
      <header className="section__header analytics-page__header">
        <DatePickerBtn
          className="analytics-page__header--datepicker"
          onClose={onClose}
          onChange={setDateInterval}
        />
      </header>

      <table className="total-table">
        <thead className="total-table__thead">
          <tr>
            <td>Скачено</td>
            <td>Доход</td>
          </tr>
        </thead>
        <tbody className="total-table__tbody">
          <tr>
            <td>{download}</td>
            <td>{`$${price}`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Header;