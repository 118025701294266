import * as React from 'react';
import classNames from "classnames";
import './AppCard.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const AppCard = (params: Props) => {
  return (
    <div className={classNames('app-card', params.className)} onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { if (params.onClick) params.onClick(e) }}>
      {params.children}
    </div>
  );
};

export default AppCard;