import { makeAutoObservable } from "mobx";

class Dialog {
  isOpen: boolean = false;
  offsetTop: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async setOpenStatus(status: boolean) {
    this.isOpen = status;
  }

  async setOffsetTop(value: number) {
    this.offsetTop = value;
  }
}

export default new Dialog();