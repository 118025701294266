import React from "react";
import AppButton from '@presentation/components/AppButton/AppButton';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import { AppButtonAddParams } from './AppButtonAdd.interfaces';
import './AppButtonAdd.scss';

const AppButtonAdd = (params: AppButtonAddParams) => {
  return (
    <AppButton
      className={`app-button-add ${params.className}`}
      variant="contained"
      loaderColor="white"
      onClick={params.onClick}
      disabled={params.loading || params.disabled}
    >
      <div className="app-button-add__container">
        <div className="app-button-add__title">Добавить</div>
        <div className="app-button-add__logo">
          <AppSvgIcon className="app-button-add__logo--svg" name="plus" color="#FFFFFF" />
        </div>
      </div>
    </AppButton>
  );
};

export default AppButtonAdd;