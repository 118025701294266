import { useEffect, useRef } from "react"

export const useClickOutside = (handler: () => void) => {
    const domNode = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const clickOutsideHandler = (event: any) => {
            if (!domNode.current!.contains(event.target)) {
                handler();
            }
        }

        document.addEventListener('mousedown', clickOutsideHandler);

        return () => {
            document.removeEventListener('mousedown', clickOutsideHandler);
        }
    }, [])

    return domNode;
}