import React, { useState, useEffect, useRef, } from 'react';
import Icon from '../Icon';

const Select = (params) => {
  const [visible, setVisible] = useState(false);
  const [classSelect, setClassSelect] = useState('select');
  const [classActiveSelect, setClassActiveSelect] = useState('');
  const [classVector, setClassVector] = useState('select__selected-vector');
  const [classItems, setClassItems] = useState('select__selected-vector');

  useEffect(
    () => {
      setClassSelect(getters.getSelectClass());
    },

    []
  );
  useEffect(
    () => {
      setClassVector(getters.getVectorClass());
      setClassItems(getters.getItemsClass());
      setClassActiveSelect(getters.getSelectActiveClass())
    },

    [visible]
  );

  const renderer = {
    renderItems() {
      if (!params.items?.length) {
        return;
      }

      return params.items.map((item, index) => {
        return (
          <li className="select__item" key={index} onClick={() => handlers.onItemClicked({item})}>
            <label className="select__label">
              {item.name}
            </label>
          </li>
        );
      });
    }
  }
  const handlers = {
    onVectorClicked() {
      setVisible(!visible);
    },
    onItemClicked(payload = {}) {
      setVisible(false);
      params.onItemClicked({item: payload.item});
    },
  }
  const helpers = {
    useClickOutside: (handler) => {
      let domNode = useRef();

      useEffect(() => {
        let maybeHandler = (event) => {
          if (!domNode.current.contains(event.target)) {
            handler();
          }
        };

        document.addEventListener('mousedown', maybeHandler);

        return () => {
          document.removeEventListener('mousedown', maybeHandler);
        };
      });

      return domNode;
    },
  }
  const getters = {
    getSelectClass() {
      if (params.theme === 'solid') {
        return 'select-solid';
      }

      return 'select';
    },
    getSelectActiveClass() {
      if (visible) {
        return `${getters.getSelectClass()}_active`;
      }

      return '';
    },
    getVectorClass() {
      let str = `${getters.getSelectClass()}__selected-vector`;

      if (visible) {
        str += ` ${getters.getSelectClass()}__selected-vector_active`;
      }

      return str;
    },
    getItemsClass() {
      let str = `${getters.getSelectClass()}__list`;

      if (visible) {
        str += ` ${getters.getSelectClass()}__list_active`;
      }

      return str;
    },
  }
  const refs = {
    domNode: helpers.useClickOutside(() => {
      setVisible(false);
    }),
  }

  return (
    <div className={`${classSelect} ${classActiveSelect}`} ref={refs.domNode}>
      <div className={ `${classSelect}__selected` } onClick={e => handlers.onVectorClicked()}>
        <div className={`${classSelect}__selected-item`}>
          {params.selected}
        </div>
        <div className={classVector} >
          <Icon 
            name='selectVector' 
            width="15" 
            height="11" 
            viewBox="0 0 20 11" 
            fill="#CF2136"
          />
        </div>
      </div>
      <ul className={classItems}>
        {renderer.renderItems()}
      </ul>
    </div>
  );
};

export default Select;