import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import auth from '@store/auth';

const AuthorizedMiddleware = () => {
  const location = useLocation();

  console.debug('authorizedMiddleware', !auth.isAuth()); //DELETE

  if (auth.isAuth()) {
    return <Outlet />;
  }

  return <Navigate to='/signin' state={{ from: location }} />
};

export default AuthorizedMiddleware;