import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Common from './Common/common.template';
import StickerPackItem from '@store/workspace/stickerPack/item';
import dictionary from '@store/dictionary';
import Select from '../../../../UI/Select';
import Preview from '../../../../composite/Preview';
import AdditionalSocialLink from '../../../../composite/AdditionalSocialLink';
import TextField from '../../../../UI/alTextField/text-field.template';

const InformationTab = observer(({ authors }) => {
  useEffect(() => {
    console.debug('StickerPackItem.pack infoTab: ', StickerPackItem, dictionary,);
  }, [StickerPackItem.pack]);

  const [lang, setLang] = useState(
    StickerPackItem.pack.localizations?.length
      ? StickerPackItem.pack.localizations[0].location
      : 'en'
  );

  const getters = {
    getBannerClass({ ads_banner }) {
      if (!ads_banner) {
        return 'add-button_big add-img-stub_disabled';
      } else {
        return 'add-button_big';
      }
    },
    getPreviewBySlug({ slug }) {
      return StickerPackItem.pack.previews.filter(preview => preview.slug === slug)[0];
    },
    getSocialLinkByOrder({ order }) {
      return StickerPackItem.pack.social_links.filter(link => link.order === order)[0];
    },
    getAdditionalLinkActiveClass({ iconState, }) {
      return `${iconState ? 'additional-links__button_active' : ''}`;
    },
    getNameByLangCode({ lang, }) {
      console.debug('getNameByLangCode', lang); // DELETE

      if (!lang) {
        return StickerPackItem.pack.localizations?.length
          ? StickerPackItem.pack.localizations[0].name
          : null;
      } else if (StickerPackItem.pack.localizations?.length) {
        const filteredLocations = StickerPackItem.pack.localizations.filter(
          localization => localization.location === lang
        );

        console.debug('getNameByLangCode[name]', filteredLocations); // DELETE

        return filteredLocations.length ? filteredLocations[0].name : null;
      } else {
        return null;
      }
    },
    getDescByLangCode({ lang, }) {
      console.debug('getDescByLangCode', lang); // DELETE

      if (!lang) {
        return StickerPackItem.pack.localizations?.length
          ? StickerPackItem.pack.localizations[0].description
          : null;
      } else if (StickerPackItem.pack.localizations?.length) {
        const filteredLocations = StickerPackItem.pack.localizations.filter(
          localization => localization.location === lang
        );

        return filteredLocations.length ? filteredLocations[0].description : null;
      } else {
        return null;
      }
    },
    getLangByCode({ lang, }) {
      console.debug('getLangByCode', lang); // DELETE

      if (!lang) {
        const DEF_LANG_CODE = "en";

        return dictionary.languages?.filter(language => language.code === DEF_LANG_CODE)[0].name;
      } else {
        return dictionary.languages?.filter(language => language.code === lang)[0].name;
      }
    },
    getCopyright() {
      console.debug('getCopyright', StickerPackItem.pack.copyright); //DELETE

      return StickerPackItem.pack.copyright ||
        authors.length && new Date().getFullYear() + ' ⓒ ' + authors.find(author => author.uuid === StickerPackItem.pack.author_uuid)?.name ||
        ""
    }
  }
  const handlers = {
    inputPackName({ name, }) {
      console.debug('inputPackName', name, lang); // DELETE

      StickerPackItem.setLocalizations({
        name,
        location: lang,
        description: null,
      });
    },
    inputPackDesc({ description, }) {
      console.debug('inputPackDesc', description, lang); // DELETE

      StickerPackItem.setLocalizations({
        description,
        location: lang,
        name: null,
      });
    },
    onIdentifierPayChanged(payload = {}) {
      StickerPackItem.setIdentifierPay(payload.identifierPay);
    },
    onCodeChanged(payload = {}) {
      StickerPackItem.setCode(payload.code);
    },
    onAdsBannerChanged(payload = {}) {
      StickerPackItem.setAdsBanner(payload.checked);
    },
    selectCountry({ item, }) {
      console.debug('selectCountry', item); // DELETE

      StickerPackItem.setCountry(item);
    },
    async selectAuthor({ item, }) {
      console.debug('selectAuthor', item); // DELETE

      await StickerPackItem.setCopyright({ copyright: '', });
      await StickerPackItem.setAuthor(item);
      await StickerPackItem.setCopyright({ copyright: getters.getCopyright(), });
    },
    selectCategory({ item, }) {
      console.debug('selectCategory', item); // DELETE

      StickerPackItem.setCategory(item);
    },
    onPayTypeClicked(payload = {}) {
      StickerPackItem.setPropertiesFree(payload.value);
    },
    onPackTypeClicked(payload = {}) {
      StickerPackItem.setPropertiesPublic(payload.value);
    },
    setSocialLink({ url, order, name, slug, }) {
      StickerPackItem.setSocialLink({ url, order, name, slug, });
    },
    setTelegram({ url, }) {
      StickerPackItem.setTelegram(url);
    },
    setSignal({ url, }) {
      StickerPackItem.setSignal(url);
    },
    onCopyrightChanged({ copyright, }) {
      StickerPackItem.setCopyright({ copyright, });
    },
    closePreview({ slug, }) {
      console.debug('closePreview', slug);

      StickerPackItem.deletePreview({ slug, })
    },
  }

  return (
    <div className="information">
      <div className="information__form">
        <Common />

        <h4 className="information__title">Оформление страницы</h4>
        <div className="information__row">
          <ul className="information__add-block">
            <li className="information__add-block-item">
              <Preview
                classNameImg="arl-preview__img_circle"
                width={96}
                height={96}
                img={getters.getPreviewBySlug({ slug: 'whatsappicon' })}
                slug="whatsappicon"
                close={handlers.closePreview}
              />
              <div className="information__add-block-size">96x96 .PNG</div>

              <Preview
                classNameImg="arl-preview__img_trayicon"
                width={117}
                height={84}
                img={getters.getPreviewBySlug({ slug: 'trayicon' })}
                slug="trayicon"
                close={handlers.closePreview}
              />
              <div className="information__add-block-size">117x84 .PNG</div>
            </li>

            <li className="information__add-block-item">
              <Preview
                classNameImg=""
                width={512}
                height={512}
                img={getters.getPreviewBySlug({ slug: 'logoPack' })}
                slug="logoPack"
                close={handlers.closePreview}
              />

              <div className="information__add-block-size">512x512 .PNG</div>
            </li>

            <li className="information__add-block-item">
              <Preview
                classNameImg="arl-preview__img_rectangle"
                width={954}
                height={309}
                img={getters.getPreviewBySlug({ slug: 'listItem' })}
                slug="listItem"
                close={handlers.closePreview}
              />

              <div className="information__add-block-size">954x309 .PNG</div>
            </li>

            <li className="information__add-block-item">
              <Preview
                classNameImg="arl-preview__img_middle"
                width={1125}
                height={675}
                img={getters.getPreviewBySlug({ slug: 'previewBanner' })}
                slug="previewBanner"
                close={handlers.closePreview}
              />

              <div className="information__add-block-size">1125x675 .PNG</div>
            </li>

            <li className="information__add-block-item information__add-block-item_banner">
              <label className="radio">
                <input
                  id="banner-checkbox"
                  type="checkbox"
                  className="radio__input"
                  checked={StickerPackItem.pack.ads_banner ?? false}
                  onChange={e => handlers.onAdsBannerChanged({ checked: e.target.checked })}
                />
                <span className="radio__pseudo-radio"></span>
                Рекламный баннер
              </label>

              <Preview
                className={getters.getBannerClass({ ads_banner: StickerPackItem.pack.ads_banner })}
                classNameImg="arl-preview__img_big"
                width={1125}
                height={753}
                img={getters.getPreviewBySlug({ slug: 'banner' })}
                slug="banner"
                close={handlers.closePreview}
              />

              <div className="information__add-block-size">1125x753 .PNG</div>
            </li>
          </ul>
        </div>

        <h4 className="information__title information__title_margin">Ссылки на стикеры</h4>
        <div className="information__row">
          <label className="information__add-link">
            Telegram:
            <input
              type="text"
              className="input"
              value={StickerPackItem.pack.telegram || ""}
              onChange={e => handlers.setTelegram({
                url: e.target.value,
              })}
            />
          </label>

          <label className="information__add-link">
            Signal:
            <input
              type="text"
              className="input"
              value={StickerPackItem.pack.signal || ""}
              onChange={e => handlers.setSignal({
                url: e.target.value,
              })}
            />
          </label>
        </div>

        <div className="information__row information__row_margin information__row--dictionary">
          <label className="information__select-label">
            <span className="information__select-title">
              Категория:
            </span>
            <Select
              selected={StickerPackItem.pack.category?.name ?? 'Выбрать категорию'}
              items={dictionary.categories}
              theme='solid'
              onItemClicked={handlers.selectCategory}
            />
          </label>

          <label className="information__select-label">
            <span className="information__select-title">
              Страна:
            </span>
            <Select
              selected={StickerPackItem.pack.country?.name ?? 'Выбрать страну'}
              items={dictionary.countries}
              theme='solid'
              onItemClicked={handlers.selectCountry}
            />
          </label>

          <label className="information__select-label">
            <span className="information__select-title">
              Автор:
            </span>
            <Select
              selected={authors.find(author => author.uuid === StickerPackItem.pack.author_uuid)?.name ?? 'Выбрать страну'}
              items={authors}
              theme='solid'
              onItemClicked={handlers.selectAuthor}
            />
          </label>
        </div>

        {/* <h4 className="information__title">Дополнительные ссылки</h4>
          <div className="information__row additional-links">
            <ul className="additional-links__list">
              <li className="additional-links__item-wrapper">
                <AdditionalSocialLink
                  order={2}
                  value={getters.getSocialLinkByOrder({ order: 2 }) || {}}
                  onChange={handlers.setSocialLink}
                />
              </li>

              <li className="additional-links__item-wrapper">
                <AdditionalSocialLink
                  order={3}
                  value={getters.getSocialLinkByOrder({ order: 3 }) || {}}
                  onChange={handlers.setSocialLink}
                />
              </li>

              <li className="additional-links__item-wrapper">
                <AdditionalSocialLink
                  order={4}
                  value={getters.getSocialLinkByOrder({ order: 4 }) || {}}
                  onChange={handlers.setSocialLink}
                />
              </li>

              <li className="additional-links__item-wrapper">
                <AdditionalSocialLink
                  order={5}
                  value={getters.getSocialLinkByOrder({ order: 5 }) || {}}
                  onChange={handlers.setSocialLink}
                />
              </li>
            </ul>
          </div> */}

        <div className="information__row information__row--copyright">
          <TextField
            classNames={['information__row--copyright-input']}
            placeholder="COPYRIGHT"
            value={StickerPackItem.pack.copyright || ""}
            onChange={e => handlers.onCopyrightChanged({ copyright: e.target.value })}
          />
        </div>
      </div>
    </div>
  )
});

export default InformationTab;