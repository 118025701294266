import { languageCodesGet, languageCodesPost } from './languageCodes.endpoints';
import { languageCodesGetAdapter, languageCodesPostAdapter } from './languageCodes.adapters';

export const fetchLanguageCodes = async () => {
  const languageCodesGetResponse = await languageCodesGet();
  const languageCodesGetAdapterResponse = await languageCodesGetAdapter(languageCodesGetResponse);
  return languageCodesGetAdapterResponse.data;
};

export const createLanguageCodes = async (payload: any) => {
  const languages = await languageCodesPostAdapter(payload);
  const languageCodesPostResponse = await languageCodesPost(languages);
  return languageCodesPostResponse.data;
};