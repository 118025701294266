import React, { useState, } from 'react';
import { createTheme, } from '@mui/material/styles';
import Template from './template';


const DatePickerBtn = ({ className, onChange, onClose, }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = createTheme({
    palette: {
      ar_red: {
        main: '#CF2136',
      },
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return <Template
    id={id}
    className={className}
    theme={theme}
    open={open}
    anchorEl={anchorEl}
    handleClick={handleClick}
    handleClose={handleClose}
    onChange={onChange}
  />;
};

export default DatePickerBtn;