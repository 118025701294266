import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import auth from '@store/auth';

const NotAuthorizedMiddleware = () => {
  const location = useLocation();

  console.debug('NotAuthorizedMiddleware', auth.isAuth()); // DELETE

  if (auth.isAuth()) {
    return <Navigate to='/' state={{ from: location }} />
  }

  return <Outlet />;
};

export default NotAuthorizedMiddleware;