import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ARCard from '@presentation/components/ui/ARCard/ARCard.index';
import AppSkeleton from '@presentation/components/AppSkeleton/AppSkeleton';
import EmptyCase from './components/EmptyCase/EmptyCase.index';
import { ARTableHeader, ARTableFooter, } from './ARTable.types';
import { IARTableParams, IRenderARTableMainParams } from './ARTable.interfaces';

export const renderARTable = (params: IARTableParams) => {
  console.debug('presentation/components/ui/ARTable/renderers/renderARTable'); //DELETE

  if (params.variant === 'contained') {
    return (
      <ARCard className="artable__container">
        {renderTableContent(params)}
      </ARCard>
    );
  }

  return (
    <div className="artable__container">
      {renderTableContent(params)}
    </div>
  );
};
export const renderTableContent = (params: IARTableParams) => {
  return (
    <>
      {renderARTableHeader(params.header)}
      {renderARTableMain({
        tableHeadRow: params.tableHeadRow,
        tableBodyRow: params.tableBodyRow,
        items: params.items,
        loading: params.loading,
        maxHeight: params.maxHeight,
      })}
      {!params.items?.length &&
        !params.loading &&
        <EmptyCase
          actionBtnLabel={params.emptyActionBtnLabel}
          onActionBtnClick={params.onEmptyActionBtnClick}
        />
      }
      {(params.items && !params.loading) ? renderARTableFooter(params.footer) : null}
    </>
  );
};
export const renderARTableHeader = (header: ARTableHeader) => {
  console.debug('presentation/components/ui/ARTable/renderers/renderARTableHeader'); //DELETE

  if (!header) {
    return null;
  }

  return (
    <div className="artable-header">
      {header}
    </div>
  );
};
export const renderARTableMain = (params: IRenderARTableMainParams) => {
  console.debug('presentation/components/ui/ARTable/renderers/renderARTableMain/params', params); //DELETE

  return (
    <div className="artable-main">
      <TableContainer sx={{ maxHeight: params.maxHeight }}>
        <Table stickyHeader className="artable-main--table">
          <TableHead className="artable-main--table__head">
            {params.tableHeadRow}
          </TableHead>

          {!!params.items?.length &&
            !params.loading &&
            <TableBody className="artable-main--table__body">
              {params.items?.map((item, index) => (
                React.cloneElement(
                  params.tableBodyRow,
                  { ...item, key: index, }
                )
              ))}
            </TableBody>
          }
        </Table>

        {params.loading &&
          <div className="artable-main__stubs">
            <AppSkeleton className="artable-main__stub" height={64} />
            <AppSkeleton className="artable-main__stub" height={64} />
            <AppSkeleton className="artable-main__stub" height={64} />
          </div>
        }
      </TableContainer>
    </div>
  );
};
export const renderARTableFooter = (footer: ARTableFooter) => {
  console.debug('presentation/components/ui/ARTable/renderers/renderARTableFooter'); //DELETE

  if (!footer) {
    return null;
  }

  return (
    <div className="artable-footer">
      {footer}
    </div>
  );
};