import React, { useState, useEffect, } from 'react';
import { observer, } from 'mobx-react-lite';
import { parseDataByDate, } from './services/getters';
import { setChartData, } from './services/setters';
import { chartData, } from './services/data';
import Template from './template';
import AnalyticsStore from '@store/analytics';

const SalesChart = observer(() => {
  const [chartDataState, setChartDataState] = useState(chartData);

  useEffect(async () => {
    const DATA = await parseDataByDate({ data: AnalyticsStore.analytics, });

    console.debug('DATA', DATA); //DELETE

    setChartDataState(setChartData(DATA));
  }, [AnalyticsStore.analytics]);

  return (
    <Template
      chartData={chartDataState}
    />
  );
});

export default SalesChart;