import React from 'react';

const Settings = () => {
  return (
    <div className="settings">
      <div className="section-wrapper">
        <div className="section settings__section">
          <div className="settings__email">
            <h2 className="settings__title">Замена Email!</h2>
            <div className="settings__form">
              <input type="text" className="input settings__input" placeholder="Новый Email" />
              <button className="simple-button settings__submit">Принять</button>
            </div>
          </div>

          <div className="settings__email">
            <h2 className="settings__title">Замена пароля!</h2>
            <form action="#" className="settings__form">
              <input type="password" className="input settings__input" placeholder="Старый пароль" />
              <input type="password" className="input settings__input" placeholder="Новый пароль" />
              <button className="simple-button settings__submit">Принять</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;