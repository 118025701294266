import React from 'react';
import { Routes, Route, Navigate, } from 'react-router-dom';

import MainLayout from '@presentation/layouts/MainLayout/MainLayout.index';
import WorkspaceLayout from '@presentation/layouts/WorkspaceLayout/WorkspaceLayout.index';
import AuthLayout from '@presentation/layouts/AuthLayout/AuthLayout.index';

import WithRouterParams from '@components/hoc/WithRouterParams';

import AuthorizedMiddleware from '@middleware/auth/AuthorizedMiddleware';
import NotAuthorizedMiddleware from '@middleware/auth/NotAuthorizedMiddleware';

import Signin from '@presentation/pages/signin/signin.index';

import StickerPacks from '@presentation/pages/stickerPacks/stickerPacks.index'; //TODO
import StickerPack from '@presentation/pages/stickerPack/stickerPack.index'; //TODO
import Sticker from '@presentation/pages/stickers/id'; //DELETE
import StickersList from '@presentation/pages/stickers/list'; //DELETE


import Accounts from '@presentation/pages/accounts';
import Analitics from '@presentation/pages/analytics';
import Finance from '@presentation/pages/finance';

import SettingsAccount from '@presentation/pages/settingsAccount/settingsAccount.index';
import SettingsData from '@presentation/pages/SettingsData/SettingsData';

import NotFound from '@presentation/pages/404';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route element={<AuthorizedMiddleware />}>
          <Route element={<WorkspaceLayout />}>
            <Route index element={<Navigate to="stickerpacks" />} />

            <Route path="stickerpacks">
              <Route index element={<StickersList />} />
              <Route path="create" element={<Sticker />} />
              <Route path=":uuid" element={
                <WithRouterParams>
                  <Sticker />
                </WithRouterParams>
              } />
            </Route>

            <Route path="analytics" element={<Analitics />} />
            <Route path="finance" element={<Finance />} />
            <Route path="accounts" element={<Accounts />} />

            <Route path="settings">
              <Route index element={<SettingsAccount />} />
              <Route path="data" element={<SettingsData />} />
            </Route>
          </Route>
        </Route>

        <Route element={<NotAuthorizedMiddleware />}>
          <Route element={<AuthLayout />}>
            <Route path="signin" element={<Signin />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;