import React from "react";
import { observer } from 'mobx-react-lite';
import AppButtonAdd from '@presentation/components/AppButtonAdd/AppButtonAdd';
import AppSelectSearch from '@presentation/components/AppSelectSearch/AppSelectSearch';
import { Item as AppSelectSearchItem } from '@presentation/components/AppSelectSearch/AppSelectSearch.types';
import { ITableHeaderParams } from './TableHeader.interfaces';
import LanguageFormContext from '../../LanguageForm.contexts';
import { searchLanguageCode } from './services/searchLanguageCodeService/searchLanguageCodeService';
import './TableHeader.scss';

const TableHeader = observer((params: ITableHeaderParams) => {
  return (
    <LanguageFormContext.Consumer>
      {({ stateStore }: any) => (
        <div className="table-header">
          <AppSelectSearch
            loading={!stateStore.isLoaded || stateStore.isSaving}
            value={stateStore.languageCode?.name}
            items={stateStore.languageCodes?.map((languageCode: any) => ({ ...languageCode, name: languageCode.code }))}
            onItemClick={(item: AppSelectSearchItem) => { console.debug('onItemClick/AppSelectSearchItem', item); stateStore.setLanguageCode(item) }}
            inputSearchValue={(value: string) => searchLanguageCode({ store: stateStore, value })}
          />

          <AppButtonAdd
            className="table-header__add"
            onClick={() => {
              console.debug('TableHeader/stateStore.items', stateStore);
              if (params.add) params.add();
            }}
            disabled={!stateStore.isLoaded || stateStore.isSaving}
          />
        </div>
      )}
    </LanguageFormContext.Consumer>
  );
});

export default TableHeader;