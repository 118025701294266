import { useState, useEffect } from 'react';

export default function useLanguageCodesLoading(languageCodes: any) {
  const [state, setState] = useState<boolean>(false);

  useEffect(() => {
    console.debug('useLanguageCodesLoading', languageCodes === undefined); //DELETE
    setState(languageCodes === undefined);
  }, [languageCodes]);

  return state;
}