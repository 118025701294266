import React from 'react';
import Radio from '@mui/material/Radio';
import BpCheckedIcon from '../BpCheckedIcon/BpCheckedIcon';
import BpIcon from '../BpIcon/BpIcon';

//TODO move to components
function BpRadio(props: any) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default BpRadio;