import React, { useState, useRef, } from 'react';
import { observer } from 'mobx-react-lite';
import StickerPackItem from '@store/workspace/stickerPack/item';
import Sticker from '../../sticker';
import AddImgStub from '../../../../UI/AddImgStub';

const ListStickersTab = observer(
  () => {
    const [crtDragSticker, setCrtDragSticker] = useState(null);

    const handlers = {
      dragStartHandler(e, sticker) {
        console.debug('dragStartHandler', sticker); // DELETE

        setCrtDragSticker(sticker);

        setTimeout(() => {
          //e.target.style.display = 'none';
        });
      },
      dragEndHandler(e) {
        setTimeout(() => {
          //e.target.style.display = '';
        });
      },
      dragOverHandler(e) {
        e.preventDefault();
      },
      dropHandler: async (e, sticker) => {
        e.preventDefault();

        console.debug('dropHandler', sticker); // DELETE

        await StickerPackItem.swapStickersOrder({ over: crtDragSticker, under: sticker, });
        await StickerPackItem.syncOrder();
        await StickerPackItem.setStickersToUpdate({ stickers: StickerPackItem.pack.stickers, });
      },
    }
    const renderer = {
      renderStickerList: (payload = {}) => {
        return (
          <>
            {
              [...StickerPackItem.stickersToDisplay].sort(helpers.sortStickers).map(
                (sticker) => {
                  return (
                    <div
                      draggable={true} key={sticker.original_url}
                      onDragStart={(e) => handlers.dragStartHandler(e, sticker)}
                      onDragLeave={(e) => handlers.dragEndHandler(e)}
                      onDragEnd={(e) => handlers.dragEndHandler(e)}
                      onDragOver={(e) => handlers.dragOverHandler(e)}
                      onDrop={(e) => handlers.dropHandler(e, sticker)}
                    >
                      <Sticker
                        sticker={sticker}
                        addEmoji={callbacks.addEmoji}
                        deleteSticker={callbacks.deleteSticker}
                        deleteEmoji={callbacks.deleteEmoji}
                        downloadSticker={StickerPackItem.downloadSticker}
                      />
                    </div>
                  );
                }
              )
            }
          </>
        );
      },
    }
    const helpers = {
      sortStickers(a, b) {
        if (a.order > b.order) {
          return 1;
        } else {
          return -1;
        }
      }
    }
    const callbacks = {
      deleteSticker: async (payload = {}) => {
        console.debug('deleteSticker[callback]', payload); // DELETE

        await StickerPackItem.deleteSticker({ sticker: payload.sticker });
        await StickerPackItem.parseStickersToDisplay();
        await StickerPackItem.initOrder();
        await StickerPackItem.setStickersToUpdate({ stickers: StickerPackItem.pack.stickers, });
      },
      addSticker: async (payload = {}) => {
        console.debug('addSticker[callback]', payload); // DELETE

        await StickerPackItem.setStickersToAdd({ file: payload.file, sticker: payload.img, });
        await StickerPackItem.parseStickersToDisplay();
      },
      addEmoji: async ({ sticker, emoji, }) => {
        console.debug('addEmoji[callback]', { sticker, emoji, }); // DELETE

        await StickerPackItem.addEmojiToSticker({ sticker, emoji, });
        await StickerPackItem.parseStickersToDisplay();
        await StickerPackItem.setStickersToUpdate({ stickers: StickerPackItem.pack.stickers, });
      },
      deleteEmoji: async ({ sticker, emoji, }) => {
        console.debug('deleteEmoji[callback]', { sticker, emoji, }); // DELETE

        await StickerPackItem.deleteEmojiFromSticker({ sticker, emoji, });
        await StickerPackItem.parseStickersToDisplay();
        await StickerPackItem.setStickersToUpdate({ stickers: StickerPackItem.pack.stickers, });
      },
    }

    return (
      <div className="stickers">
        <div className="stickers__list">
          {renderer.renderStickerList()}
          <AddImgStub
            className="add-img-stub__stickers"
            change={callbacks.addSticker}
          />
        </div>
      </div>
    )
  }
);

export default ListStickersTab;