import { useState, useEffect, } from 'react';
import { InputSearchValueCallBack, } from '../AppSelectSearch.types';

export interface IUseSearchValueParams {
  value: string | null,
  callback?: InputSearchValueCallBack,
}

export default function useSearchValue(params: IUseSearchValueParams) {
  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (params.value !== null) {
      setState(params.value);
      if (params.callback) params.callback(params.value);
    }
  }, [params.value]);

  return state;
}