import React, { useState, useEffect, } from 'react';
import Template from './template';

const Country = ({ title, value, text, }) => {
  const [titleState, setTitleState] = useState('');
  const [valueState, setValueState] = useState(0);
  const [textState, setTextState] = useState('');

  useEffect(async () => {
    setTitleState(title);
    setValueState(value);
    setTextState(text);
  }, [title, value, text,]);

  return (
    <Template
      title={titleState}
      value={valueState}
      text={textState}
    />
  );
};

export default Country;