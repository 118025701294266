import React from "react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import './TableHeadRow.scss';

const TableHeadRow = () => {
  return (
    <TableRow>
      <TableCell
        className="socials-settings-table-head-row__cell"
        sx={{ borderBottom: "none", width: '25%', }}
        key={1}
      >
        Наименование
      </TableCell>

      <TableCell
        className="socials-settings-table-head-row__cell"
        sx={{ borderBottom: "none", width: '25%', }}
        key={2}
      >
        Локализация
      </TableCell>

      <TableCell
        className="socials-settings-table-head-row__cell"
        sx={{ borderBottom: "none", width: '30%', textAlign: 'center', }}
        key={3}
      >
        По умолчанию
      </TableCell>

      <TableCell
        className="socials-settings-table-head-row__cell"
        sx={{ borderBottom: "none", width: '20%', }}
        key={4}
      ></TableCell>
    </TableRow>
  );
};

export default TableHeadRow;