export const tableHeadCellStyles = {
  borderBottom: "none",
  background: '#F9F9F9',
  fontFamily: 'Helvetica Neue',
  fontSize: 16,
  fontWeight: 500,
  textAlign: 'center',
};
export const columns = [
  { id: 'select', label: '', width: 40 },
  { id: 'avatar', label: '', width: 40 },
  { id: 'name', label: 'Название', width: 380 },
  { id: 'download', label: 'Скачено', width: 180 },
  { id: 'price', label: 'Доход', width: 100 },
];