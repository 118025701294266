import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ARRadioButton from '@components/UI/ARRadioButton';
import ARSearchWithLogo from '@components/composite/ARSearchWithLogo';
import Avatar from '@mui/material/Avatar';
import styles from '../styles.module.scss';

const SELECT = 'select';
const AVATAR = 'avatar';
const NAME = 'name';
const DOWNLOAD = 'download';
const PRICE = 'price';
const EMPTY = '';

export const renderTableHead = ({ columns, tableHeadCellStyles, search, selectAll, selectAllState, }) => {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          align={column.align}
          style={{
            ...tableHeadCellStyles,
            width: column.width,
            paddingLeft: column.id === 'name' ? '30px' : '15px',
          }}
        >
          {renderHeadCell({ column, search, selectAll, selectAllState, })}
        </TableCell>
      ))}
    </TableRow>
  );
};
export const renderTableBody = ({ columns, rows, selectPack, }) => {
  return rows.map((row) => {
    return (
      <TableRow role="checkbox" tabIndex={-1} key={row.uuid}>
        {columns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                borderBottom: "none",
                fontSize: '16px',
                fontWeight: '400',
                paddingLeft: column.id === 'name' ? '30px' : '15px',
                textAlign: column.id !== 'name' ? 'center' : 'left',
              }}
            >
              {renderBodyCell({ columnId: column.id, row, selectPack, })}
            </TableCell>
          );
        })}
      </TableRow>
    );
  })
};
export const renderHeadCell = ({ column, search, selectAll, selectAllState, }) => {
  console.debug('renderHeadCell', selectAllState); //DELETE

  switch (column.id) {
    case SELECT:
      return (
        <ARRadioButton
          mode="checkbox"
          onClick={selectAll}
          value={selectAllState}
        />
      );

    case AVATAR:
      return EMPTY;

    case NAME:
      return (
        <ARSearchWithLogo
          leftLabel={column.label}
          className={styles.search}
          onInput={(e) => { search(e.target.value) }}
        />
      );

    default:
      return column.label;
  }
};
export const renderBodyCell = ({ columnId, row, selectPack, }) => {
  if (columnId === 'select') { //DELETE
    console.debug('renderBodyCell', { columnId, row, select: row[columnId] }); //DELETE
  }

  switch (columnId) {
    case SELECT:
      return (
        <ARRadioButton
          mode="checkbox"
          value={row[columnId]}
          onChange={({ value, }) => { selectPack({ value, uuid: row.uuid, }) }}
        />
      );

    case AVATAR:
      return (
        <Avatar
          alt="Remy Sharp"
          src={row[columnId]}
        />
      );

    case PRICE:
      return `$${row[columnId]}`;

    default:
      return row[columnId];
  }
};