import { getDateByTimestamp, } from '@utils/date';
import { chartData, } from './data';

export const setChartData = (data) => {
  console.debug('SalesChart[setter][setChartData]', data); //DELETE

  if (!data) return chartData;

  return {
    ...chartData,
    labels: data.map(item => item.date),
    datasets: [
      {
        ...chartData.datasets[0],
        data: data.map(item => item.sales),
      },
      {
        ...chartData.datasets[1],
        data: data.map(item => item.favorites),
      }
    ],
  };
};