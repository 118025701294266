import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { renderTableHead, renderTableBody } from './services/renderers';
import { tableHeadCellStyles, columns, } from './services/data';
import styles from './styles.module.scss';

const Template = ({
  packs,
  search,
  selectAll,
  selectAllState,
  selectPack,
}) => {
  const [packsState, setPacksState] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(async () => {
    console.debug('PACKS::Template', packs); //DELETE

    setPacksState(packs);
  }, [packs,]);

  const handleChangePage = (event, newPage) => { //DELETE
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => { //DELETE
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer sx={{ maxHeight: 400 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          {renderTableHead({ columns, tableHeadCellStyles, search, selectAll, selectAllState, })}
        </TableHead>

        <TableBody>
          {renderTableBody({ columns, rows: packsState, selectPack, })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Template;