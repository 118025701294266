import { useState, useEffect, } from 'react'
import AccountsPageContext from '@contexts/AccountsPageContext'

const AccountsPageProvider = ({ children, accounts }) => {
  const [accountsState, setAccountsState] = useState([]);

  useEffect(() => {
    console.debug('AccountsPageProvider[useEffect]', accounts); //DELETE

    setAccountsState(accounts);
  }, [accounts]);

  return (
    <AccountsPageContext.Provider value={{ accounts: accountsState, }}>
      {children}
    </AccountsPageContext.Provider>
  );
}

export default AccountsPageProvider;