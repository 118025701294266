import { useState, useEffect } from 'react';
import classNames from "classnames";

export default function useGetArrowClasses(isOpen: boolean) {
  const [state, setState] = useState('');

  useEffect(() => {
    setState(classNames(
      'app-select-search__arrow',
      isOpen ? 'app-select-search__arrow_open' : ''
    ));
  }, [isOpen]);

  return state;
}