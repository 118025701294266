import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Picker from 'emoji-picker-react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';

const Sticker = observer(({
  sticker,
  addEmoji,
  deleteEmoji,
  deleteSticker,
  downloadSticker,
}) => {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlers = {
    onDeleteStickerClicked(payload = {}) {
      deleteSticker({ sticker: payload.sticker });
    },
    onEmojiClick(event, emojiObject) {
      console.debug('onEmojiClick', emojiObject, sticker); // DELETE

      setChosenEmoji(emojiObject);

      addEmoji({ sticker, emoji: emojiObject, });
    },
    handleClick(event) {
      console.debug('handleClick', event.currentTarget); // DELETE

      setAnchorEl(event.currentTarget);
    },
    handleClose() {
      console.debug('handleClose'); // DELETE

      setAnchorEl(null);
    },
  }
  const renderers = {
    renderEmojis() {
      return (
        <>
          {sticker.emoji.map((emoji, index) => {
            return (
              <span
                key={index}
                onClick={() => deleteEmoji({ sticker, emoji, })}
              >
                {emoji.emoji}
              </span>
            );
          })}
        </>
      );
    }
  }

  return (
    <div className="stickers__item">
      <div className="sticker-card sticker-card__active">
        <div className="sticker-card__header">
          <span className="sticker-card__number">
            {sticker.order}
          </span>

          <div className="sticker-card__emoji">
            {renderers.renderEmojis()}

            <SvgIcon
              className="sticker-card__add"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-describedby={id}
              onClick={handlers.handleClick}
              style={{ height: '20px', width: '20px' }}
            >
              <path fillRule="evenodd" clipRule="evenodd" d="M0 17.5C0 7.83562 7.83562 0 17.5 0C27.1644 0 35 7.83562 35 17.5C35 27.1644 27.1644 35 17.5 35C7.83562 35 0 27.1644 0 17.5ZM18.9583 18.9583H26.25C27.055 18.9583 27.7083 18.3065 27.7083 17.5C27.7083 16.6935 27.055 16.0417 26.25 16.0417H18.9583V8.75C18.9583 7.94354 18.305 7.29167 17.5 7.29167C16.695 7.29167 16.0417 7.94354 16.0417 8.75V16.0417H8.75C7.945 16.0417 7.29167 16.6935 7.29167 17.5C7.29167 18.3065 7.945 18.9583 8.75 18.9583H16.0417V26.25C16.0417 27.0565 16.695 27.7083 17.5 27.7083C18.305 27.7083 18.9583 27.0565 18.9583 26.25V18.9583Z" fill="#CF2136" />
            </SvgIcon>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handlers.handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Picker onEmojiClick={handlers.onEmojiClick} />
            </Popover>

          </div>
        </div>

        <img draggable={false} src={sticker.original_url} className="sticker-card__img" alt="angry sticker" />

        <div className="sticker-card__footer">
          <SvgIcon
            className="sticker-card__add sticker-card_delete"
            viewBox="0 0 28 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: '23px', width: '30px' }}
            onClick={() => handlers.onDeleteStickerClicked({ sticker })}
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M8.75 1.75L10.5 0H17.5L19.25 1.75H28V5.25H0V1.75H8.75ZM1.75 31.5V8.75H26.25V31.5C26.25 33.425 24.675 35 22.75 35H5.25C3.325 35 1.75 33.425 1.75 31.5ZM10.5 12.25V31.5H7V12.25H10.5ZM21 31.5V12.25H17.5V31.5H21Z" fill="#CF2136" />
          </SvgIcon>

          <SvgIcon
            className="sticker-card__add sticker-card_download"
            viewBox="0 0 30 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: '23px', width: '30px' }}
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4615 1.34615C13.4615 0.603077 14.0646 0 14.8077 0C15.5508 0 16.1538 0.603077 16.1538 1.34615V6.73077H13.4615V1.34615ZM13.4615 6.73077H2.69231C1.20481 6.73077 0 7.93558 0 9.42308V32.3077C0 33.7952 1.20481 35 2.69231 35H26.9231C28.4106 35 29.6154 33.7952 29.6154 32.3077V9.42308C29.6154 7.93558 28.4106 6.73077 26.9231 6.73077H16.1538V23.6734L19.2405 20.5867C19.7669 20.0603 20.6177 20.0603 21.1441 20.5867C21.6704 21.113 21.6704 21.9639 21.1441 22.4902L15.7595 27.8749C15.497 28.1374 15.1523 28.2692 14.8077 28.2692C14.4631 28.2692 14.1184 28.1374 13.8559 27.8749L8.4713 22.4902C7.94496 21.9639 7.94496 21.113 8.4713 20.5867C8.99765 20.0603 9.8485 20.0603 10.3748 20.5867L13.4615 23.6734V6.73077Z" fill="#CF2136" />
          </SvgIcon>

          <SvgIcon
            className="sticker-card__add sticker-card_drag"
            viewBox="0 0 33 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: '23px', width: '25px' }}
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M1.50022 3.4695e-06C1.04943 -0.00637189 0.630123 0.230473 0.402865 0.619845C0.175607 1.00922 0.175607 1.49079 0.402865 1.88016C0.630123 2.26953 1.04943 2.50638 1.50022 2.5H31.5002C31.951 2.50638 32.3703 2.26953 32.5976 1.88016C32.8248 1.49079 32.8248 1.00922 32.5976 0.619845C32.3703 0.230473 31.951 -0.00637189 31.5002 3.4695e-06H1.50022ZM1.50022 8.75C1.04943 8.74363 0.630123 8.98047 0.402865 9.36984C0.175607 9.75922 0.175607 10.2408 0.402865 10.6302C0.630123 11.0195 1.04943 11.2564 1.50022 11.25H31.5002C31.951 11.2564 32.3703 11.0195 32.5976 10.6302C32.8248 10.2408 32.8248 9.75922 32.5976 9.36984C32.3703 8.98047 31.951 8.74363 31.5002 8.75H1.50022ZM0.402865 18.1198C0.630123 17.7305 1.04943 17.4936 1.50022 17.5H31.5002C31.951 17.4936 32.3703 17.7305 32.5976 18.1198C32.8248 18.5092 32.8248 18.9908 32.5976 19.3802C32.3703 19.7695 31.951 20.0064 31.5002 20H1.50022C1.04943 20.0064 0.630123 19.7695 0.402865 19.3802C0.175607 18.9908 0.175607 18.5092 0.402865 18.1198Z" fill="#CF2136" />
          </SvgIcon>
        </div>
      </div>
    </div>
  )
}
);

export default Sticker;