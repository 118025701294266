import { makeAutoObservable, observe } from 'mobx';

export default class StoreState {
  active: boolean | undefined = undefined;
  activePrev: boolean | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    observe(this, "active", ({ oldValue }) => {
      this.activePrev = oldValue === undefined ? undefined : !!oldValue;
    });
  }

  async setActive(active: boolean) {
    // console.debug('StoreState/setActive/active', active); //DELETE
    this.active = active;
  }
}