import { styled } from '@mui/material/styles';
import BpIcon from '../BpIcon/BpIcon';

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#EDEDED',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#CF2136,#CF2136 40%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EDEDED',
  },
});

export default BpCheckedIcon;