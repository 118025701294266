import React from 'react';
import ARSvg from '@components/UI/ARSvg';
import ARSearch from '@components/UI/ARSearch';
import styles from './styles.module.scss';


const Template = ({
  className,
  leftLabel,
  onInput,
}) => {
  return (
    <div className={`${className} ${styles.container}`}>
      <div className={`${styles.label} ${styles.label__left}`}>
        {leftLabel}
      </div>

      <div className={styles.wrapper}>
        <ARSvg
          name="search"
          width="20"
          height="20"
          color="#CF2136"
        />
        <ARSearch
          className={styles.search}
          onInput={onInput}
        />
      </div>
    </div>
  );
};

export default Template;