import React from "react";
import classNames from "classnames";
import AppTextField from '@presentation/components/AppTextField/AppTextField';
import { AppTextFieldVariants } from '@presentation/components/AppTextField/AppTextField.types';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import AppCard from '@presentation/components/AppCard/AppCard';
import AppSkeleton from '@presentation/components/AppSkeleton/AppSkeleton';
import { useClickOutside } from '@hooks/useClickOutside';
import { IAppSelectSearchParams } from './AppSelectSearch.interfaces';
import { Item } from './AppSelectSearch.types';
import './AppSelectSearch.scss';
import StoreState from './store/StoreState';
import { observer } from 'mobx-react-lite';

import useGetClasses from './hooks/useGetClasses';
import useGetFieldClasses from './hooks/useGetFieldClasses';
import useGetArrowClasses from './hooks/useGetArrowClasses';
import useGetMenuClasses from './hooks/useGetMenuClasses';
import useSearchValue from './hooks/useSearchValue';

import { onItemClickHandler } from './services/onItemClick';
import { inputSearchValueHandler } from './services/inputSearchValue';

const AppSelectSearch = observer((params: IAppSelectSearchParams) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [stateStore, setStateStore] = React.useState<StoreState>(new StoreState());
  const close = (): void => setIsOpen(false);
  const ref = useClickOutside(close);
  const classes = useGetClasses(isOpen);
  const fieldClasses = useGetFieldClasses(isOpen);
  const arrowClasses = useGetArrowClasses(isOpen);
  const menuClasses = useGetMenuClasses(isOpen);
  const searchValue = useSearchValue({
    value: stateStore.searchValue,
    callback: params.inputSearchValue
  });

  React.useEffect(() => {
    stateStore.setValue(params.value);
  }, [params.value]);

  React.useEffect(() => {
    if (!isOpen && stateStore.searchValue !== null) {
      stateStore.setSearchValue('');
    }
  }, [isOpen]);

  const renderField = () => {
    if (isOpen) {
      return (
        <AppTextField
          inputAdornment={<AppSvgIcon
            name="search"
            color="#CF2136"
          />}
          className="app-select-search__search"
          variant={AppTextFieldVariants.Outlined}
          value={stateStore.searchValue}
          onInput={(e) => inputSearchValueHandler({
            store: stateStore,
            value: e,
          })}
          onClick={appTextFieldClick}
        />
      );
    }

    return (
      <div className={classNames('app-select-search__search', 'app-select-search__search-title')} onClick={() => setIsOpen(!isOpen)}>
        {stateStore.value || 'Выбрать'}
      </div>
    );
  };
  const renderMenuItem = (item: Item, key: number) => {
    return (
      <div className="app-select-search__card-menu--item" key={key} onClick={() => onItemClickHandler({
        store: stateStore,
        item,
        close,
        onItemClick: params.onItemClick,
      })}>
        {item.name}
      </div>
    );
  };
  const renderMenuItems = (items: Array<Item>) => {
    return (items?.map((item: Item, index: number) => renderMenuItem(item, index)));
  };

  const onClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };
  const appTextFieldClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  if (!params.loading) {
    return (
      <div ref={ref} className={classes}>
        <AppCard className={fieldClasses} onClick={onClick}>
          <div className="app-select-search__header">
            {renderField()}

            <AppSvgIcon
              className={arrowClasses}
              name="arrow"
              color="#CF2136"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </AppCard>

        <AppCard className={menuClasses}>
          {renderMenuItems(params.items)}
        </AppCard>
      </div>
    );
  }

  return (
    <AppSkeleton className="app-select-search__skeleton" />
  );
});

export default AppSelectSearch;