import React from 'react';
import './text-field.style.scss';
import { getInputClasses, } from './text-field.service';

const Icon = ({
  title,
  value,
  disabled,
  onChange,
  classNames,
  placeholder,
  caps,
}) => {
  const handlers = {
    change(e) {
      if (onChange) onChange(e);
    },
  };

  return (
    <div>
      <input
        type="text"
        className={getInputClasses({ classes: classNames, caps, })}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={e => handlers.change(e)}
      />
    </div>
  );
};

export default Icon;