import React, { useState, useEffect, } from 'react';
import { observer, } from 'mobx-react-lite';
import { parseDataByPlatform, } from './services/getters';
import { setPercentData, setChartData, } from './services/setters';
import { percentData, chartData, } from './services/data';
import Template from './template';
import AnalyticsStore from '@store/analytics';

const DevicesChart = observer(() => {
  const [percentDataState, setPercentDataState] = useState(percentData);
  const [chartDataState, setChartDataState] = useState(chartData);

  useEffect(async () => {
    const DATA = await parseDataByPlatform({ data: AnalyticsStore.analytics, });

    setPercentDataState(setPercentData(DATA));
    setChartDataState(setChartData(DATA));
  }, [AnalyticsStore.analytics]);

  return (
    <Template
      percentData={percentDataState}
      chartData={chartDataState}
    />
  );
});

export default DevicesChart;