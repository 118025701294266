import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ARRadioButton from '@components/UI/ARRadioButton';
import TextField from '@components/UI/alTextField/text-field.template';
import ARAvatarGroup from '@components/UI/ARAvatarGroup';
import Avatar from '@mui/material/Avatar';
import styles from '../styles.module.scss';

const PACKS = 'packs';

export const renderTableHead = ({ columns, tableHeadCellStyles, }) => {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          align={column.align}
          style={{
            ...tableHeadCellStyles,
            width: column.width,
            paddingLeft: column.id === 'name' ? '30px' : '15px',
          }}
        >
          {renderHeadCell({ column, })}
        </TableCell>
      ))}
    </TableRow>
  );
};
export const renderTableBody = ({ columns, rows, selectPack, }) => {
  return rows.map((row) => {
    return (
      <TableRow role="checkbox" tabIndex={-1} key={row.uuid}>
        {columns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                borderBottom: "none",
                fontSize: '16px',
                fontWeight: '400',
                paddingLeft: column.id === 'name' ? '30px' : '15px',
                textAlign: column.id !== 'name' ? 'center' : 'left',
              }}
            >
              {renderBodyCell({ columnId: column.id, row, selectPack, })}
            </TableCell>
          );
        })}
      </TableRow>
    );
  })
};
export const renderHeadCell = ({ column, search, selectAll, selectAllState, }) => {
  console.debug('renderHeadCell', selectAllState); //DELETE

  return column.label;
};
export const renderBodyCell = ({ columnId, row, selectPack, }) => {
  if (columnId === 'select') { //DELETE
    console.debug('renderBodyCell', { columnId, row, select: row[columnId] }); //DELETE
  }

  switch (columnId) {
    case PACKS:
      return (
        <ARAvatarGroup avatars={row[columnId]} />
      );

    default:
      return (
        <TextField
          classNames={[styles.textfield]}
          disabled={true}
          value={row[columnId]}
        />
      );
  }
};