import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import './common.style.scss';
import StickerPackItem from '@store/workspace/stickerPack/item';
import dictionary from '../../../../../../store/dictionary';
import Select from '../../../../../UI/Select';
import TextField from '../../../../../UI/alTextField/text-field.template';

import User from '@store/user';

import AppSelect from '@presentation/components/AppSelect/AppSelect';
import { fetchLanguages } from '@api/rest/dictionaries/languages/languages';

const InformationTab = observer(
  () => {
    useEffect( // DELETE
      () => {
        console.debug('infoTab[Common]'); //DELETE
        console.debug('infoTab[Common]::User', User.user); //DELETE
      },

      [User.user]
    );

    useEffect(async () => {
      const fetchLanguagesResponse = await fetchLanguages({});
      console.debug('fetchLanguagesResponse', fetchLanguagesResponse); //DELETE
      setLanguages(fetchLanguagesResponse);
    }, []);

    useEffect(async () => {
      console.debug('StickerPackItem WWWWW', StickerPackItem.pack.lang); //DELETE
      setPackLang(StickerPackItem.pack.lang);
    }, [StickerPackItem]);

    const [lang, setLang] = useState('en');
    const [languages, setLanguages] = useState([]);
    const [packLang, setPackLang] = useState('');

    const selectPackLang = (value) => {
      console.debug('selectPackLang/value', value);
      console.debug('selectPackLang/StickerPackItem.pack.lang', StickerPackItem.pack.lang);

      if (value !== StickerPackItem.pack.lang) {
        StickerPackItem.pack.lang = value;
      }

      console.debug('selectPackLang/StickerPackItem.pack.lang', StickerPackItem.pack.lang);
    };

    const getters = {
      getNameByLangCode({ lang, }) {
        console.debug('getNameByLangCode', lang); // DELETE

        if (!lang) {
          return StickerPackItem.pack.localizations?.length
            ? StickerPackItem.pack.localizations[0]?.name
            : null;
        } else if (StickerPackItem.pack.localizations?.length) {
          const filteredLocations = StickerPackItem.pack.localizations.filter(
            localization => localization.location === lang
          );

          console.debug('getNameByLangCode[name]', filteredLocations); // DELETE

          return filteredLocations.length ? filteredLocations[0]?.name : null;
        } else {
          return null;
        }
      },
      getDescByLangCode({ lang, }) {
        console.debug('getDescByLangCode', lang); // DELETE

        if (!lang) {
          return StickerPackItem.pack.localizations?.length
            ? StickerPackItem.pack.localizations[0].description
            : null;
        } else if (StickerPackItem.pack.localizations?.length) {
          const filteredLocations = StickerPackItem.pack.localizations.filter(
            localization => localization.location === lang
          );

          return filteredLocations.length ? filteredLocations[0].description : null;
        } else {
          return null;
        }
      },
      getLangByCode({ lang, }) {
        console.debug('getLangByCode', lang); // DELETE

        if (!lang) {
          const DEF_LANG_CODE = "en";

          return dictionary.languages?.filter(language => language.code === DEF_LANG_CODE)[0]?.name;
        } else {
          return dictionary.languages?.filter(language => language.code === lang)[0]?.name;
        }
      },
    }
    const handlers = {
      inputPackName({ name, }) {
        console.debug('inputPackName', name, lang); // DELETE

        StickerPackItem.setLocalizations({
          name,
          location: lang,
          description: null,
        });
      },
      inputPackDesc({ description, }) {
        console.debug('inputPackDesc', description, lang); // DELETE

        StickerPackItem.setLocalizations({
          description,
          location: lang,
          name: null,
        });
      },
      onIdentifierPayChanged({ identifierPay, }) {
        StickerPackItem.setPropertiesIdentifierPay(identifierPay);
      },
      onCodeChanged({ code, }) {
        StickerPackItem.setPropertiesCode(code);
      },
      changeCommission({ commission, }) {
        StickerPackItem.setPropertiesCommission(commission);
      },
      onLangSelectItemClicked({ item, }) {
        setLang(item.code);

        // StickerPackItem.setPropertiesLocation(payload.item);
      },
      onPayTypeClicked(payload = {}) {
        StickerPackItem.setPropertiesFree(payload.value);
      },
      onPackTypeClicked(payload = {}) {
        StickerPackItem.setPropertiesPublic(payload.value);
      },
    }

    return (
      <div className="information__row">
        <div className="information__id-block">
          <div className="information__id-block--uuid-pack">
            {!!StickerPackItem.pack.uuid &&
              `${StickerPackItem.pack.sharePackUrl}`
            }
          </div>

          {User.user?.role.name === 'admin' && ''}

          <div id="price-input" className="information__id-block--price-input">
            <div className="information__id-block-radio">
              <label
                className="radio"
                onClick={() => handlers.onPayTypeClicked({ value: false })}
              >
                <input
                  className="radio__input"
                  value="not-disabled"
                  type="radio"
                  name="price"
                  readOnly
                  checked={!StickerPackItem.pack.properties?.free}
                />
                <span className="radio__pseudo-radio"></span>
                <span>Платный</span>
              </label>

              <label
                className="radio"
                id="price-input"
                onClick={() => handlers.onPayTypeClicked({ value: true })}
              >
                <input
                  className="radio__input"
                  value="disabled"
                  type="radio"
                  name="price"
                  readOnly
                  checked={StickerPackItem.pack.properties?.free}
                />
                <span className="radio__pseudo-radio"></span>
                <span>Бесплатный</span>
              </label>
            </div>

            <div className="info-tab-common--idpay-commission">
              <TextField
                placeholder="Id покупки"
                caps={true}
                disabled={StickerPackItem.pack.properties?.free}
                value={StickerPackItem.pack.id_pay ?? ''}
                onChange={e => handlers.onIdentifierPayChanged({ identifierPay: e.target.value })}
              />

              <div className="info-tab-common--commission">
                <span className="info-tab-common--commission-title">Комиссия:</span>
                <TextField
                  caps={true}
                  classNames={['info-tab-common--commission-input']}
                  disabled={StickerPackItem.pack.properties?.free}
                  value={StickerPackItem.pack.properties?.commission ?? ''}
                  onChange={e => handlers.changeCommission({ commission: e.target.value })}
                />
                <span className="info-tab-common--commission-title">%</span>
              </div>
            </div>
          </div>

          <div id="public-input">
            <div className="information__id-block-radio">
              <label
                className="radio"
                onClick={() => handlers.onPackTypeClicked({ value: true })}
              >
                <input
                  className="radio__input"
                  value="disabled"
                  type="radio"
                  name="public"
                  readOnly
                  checked={StickerPackItem.pack.properties?.public}
                />
                <span className="radio__pseudo-radio"></span>
                <span>Публичный</span>
              </label>

              <label
                className="radio"
                onClick={() => handlers.onPackTypeClicked({ value: false })}
              >
                <input
                  className="radio__input"
                  value="not-disabled"
                  type="radio"
                  name="public"
                  readOnly
                  checked={!StickerPackItem.pack.properties?.public}
                />
                <span className="radio__pseudo-radio"></span>
                <span>Скрытый</span>
              </label>
            </div>

            <TextField
              classNames={['information__row--code']}
              placeholder="код"
              caps={true}
              disabled={StickerPackItem.pack.properties?.public}
              value={StickerPackItem.pack.properties?.code ?? ''}
              onChange={e => handlers.onCodeChanged({ code: e.target.value })}
            />
          </div>
        </div>

        <div className="information__info-block">
          <div className="information__info-block-row">
            <TextField
              classNames={['information__row--name']}
              placeholder="Название пака"
              value={getters.getNameByLangCode({ lang, }) ?? ""}
              onChange={e => handlers.inputPackName({ name: e.target.value })}
            />

            <Select
              selected={getters.getLangByCode({ lang, }) ?? 'Выбрать язык'}
              items={dictionary.languages}
              onItemClicked={handlers.onLangSelectItemClicked}
            />
          </div>

          <div className="information__info-block-row">
            <AppSelect
              label="Язык пака"
              items={languages}
              value={packLang}
              onChange={selectPackLang}
            />
          </div>

          <textarea
            className="input input_big"
            placeholder="Описание пака"
            value={getters.getDescByLangCode({ lang, }) ?? ""}
            onChange={e => handlers.inputPackDesc({ description: e.target.value })}
          />
        </div>
      </div>
    )
  }
);

export default InformationTab;