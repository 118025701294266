import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { observer } from 'mobx-react-lite';
import StickerPackList from '@store/workspace/stickerPack/list';
import StickerPackItem from '@store/workspace/stickerPack/item';

import StickerNameLogo from '@assets/img/stickers/img.jpg';
import StickerEdit from '@assets/img/stickers/doc.svg';
import StickerCancel from '@assets/img/stickers/cancel.svg';
import StickerOk from '@assets/img/stickers/ok.svg';

const StickersList = observer(() => {
  const navigate = useNavigate();
  const [stickersState, setStickersState] = useState([]);

  useEffect(() => {
    console.debug('presentation/pages/stickers/useEffect'); // DELETE

    StickerPackList.fetch().then(() => {
      console.debug('StickersList', StickerPackList.packs); // DELETE
    });
  }, []);

  const renderer = {
    stickers() {
      return StickerPackList.packs.map((pack, index) => {
        return (
          <div className="stickers__list--table-row" key={index}>
            <div className="stickers__list--table-cell">
              <img
                className="stickers__list--table-cell-logo"
                src={getters.getPreviewLink({ pack, })}
                alt="Sticker"
              />
            </div>

            <div
              className="
                    stickers__list--table-cell
                    stickers__list--table-cell-name
                  "
            >
              {pack.name}
            </div>

            {renderer.renderStatus({ status: pack.status, })}

            <div className="stickers__list--table-cell">
              25.05.21
            </div>

            <div className="stickers__list--table-cell">
              28.05.21
            </div>

            <div className="stickers__list--table-cell">
              <div className="stickers__edit">
                <img
                  src={StickerEdit}
                  alt="Edit"
                  onClick={(event) => { handlers.onEditPackClick({ uuid: pack.uuid, event, }) }}
                />
              </div>
            </div>

            <div className="stickers__list--table-cell">
              <button className="stickers__button-icon">
                <img src={StickerCancel} alt="cancel" />
              </button>
            </div>

            <div className="stickers__list--table-cell">
              <div onClick={(event) => { handlers.deletePack({ uuid: pack.uuid, event, }) }}>
                <svg width="25" height="25" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.75 1.75L10.5 0H17.5L19.25 1.75H28V5.25H0V1.75H8.75ZM1.75 31.5V8.75H26.25V31.5C26.25 33.425 24.675 35 22.75 35H5.25C3.325 35 1.75 33.425 1.75 31.5ZM10.5 12.25V31.5H7V12.25H10.5ZM21 31.5V12.25H17.5V31.5H21Z" fill="#CF2136" />
                </svg>
              </div>
            </div>
          </div>
        );
      });
    },
    renderStatus({ status, }) {
      return (
        <div
          className={`
              stickers__list--table-cell
              stickers__list--table-cell-status
              sticker__status 
              stickers__status 
              ${status === 'in_shop' ? 'stickers__list--table-cell-status_green' : ''}
              ${status === 'ready_release' ? 'stickers__list--table-cell-status_orange' : ''}
              ${status === null ? 'stickers__list--table-cell-status_red' : ''}
            `}
        >
          {getters.getStatusText({ status, })}
        </div>
      );
    },
  }
  const handlers = {
    async createNewPack() {
      console.debug('[handler]::createNewPack'); // DELETE

      await StickerPackItem.reset();
      navigate(`/stickerpacks/create`);
    },
    async onEditPackClick(payload = {}) {
      console.debug('[handler]::onEditPackClick', payload); // DELETE

      navigate(`/stickerpacks/${payload.uuid}`);
    },
    async deletePack(payload = {}) {
      console.debug('[handler]::deletePack', payload, StickerPackList.packs); // DELETE

      await StickerPackList.detele({ uuid: payload.uuid });

      StickerPackList.fetch();
    },
  }
  const getters = {
    getPreviewLink({ pack }) {
      console.debug('getPreviewLink', pack); // DELETE

      const SLUG = 'whatsappicon';
      const PREVIEW = pack.previews.filter(preview => preview.slug === SLUG)[0]?.original_url;

      return PREVIEW || StickerNameLogo;
    },
    getStatusText({ status, }) {
      switch (status) {
        case 'in_shop':
          return 'В магазине';

        case 'ready_release':
          return 'Готов к релизу';

        default:
          return 'Не все заполнено!';
      }
    },
  }

  return (
    <section className="section stickers__section">
      <header className="section__header">
        <div className="search section__search"><input className="search__input" type="search" /></div>
        <div className="button section__button" onClick={handlers.createNewPack}>Новый пак</div>
      </header>

      <div className="stickers__list--table">
        <div className="stickers__list--table-header">
          <div className="stickers__list--table-row">
            <div className="stickers__list--table-cell"></div>
            <div className="stickers__list--table-cell">Название</div>
            <div className="stickers__list--table-cell">Статус</div>
            <div className="stickers__list--table-cell">Дата создания</div>
            <div className="stickers__list--table-cell">Дата релиза</div>
            <div className="stickers__list--table-cell"></div>
            <div className="stickers__list--table-cell"></div>
            <div className="stickers__list--table-cell"></div>
          </div>
        </div>

        <div className="stickers__list--table-body">
          {renderer.stickers()}
        </div>
      </div>
    </section>
  )
});

export default StickersList;