import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import StickerPackItem from '@store/workspace/stickerPack/item';

import DefaultStickersTab from './DefaultStickersTab';
import ListStickersTab    from './ListStickersTab';

let staticData = {}

let handlers = {}

let helpers = {}

const StickersTab = observer(
  () => {
    return (
      <div className="add-stickers__stickers-tab">
        {
          StickerPackItem.pack.stickers.length || StickerPackItem.stickersToDisplay.length
          ? <ListStickersTab />
          : <DefaultStickersTab />
        }
      </div>
    )
  }
);

export default StickersTab;