import { IServiceParams } from '../AppSelectSearch.interfaces';
import { InputSearchValueCallBack, } from '../AppSelectSearch.types';

export interface IInputSearchValueParams extends IServiceParams {
  value: string;
  inputSearchValueCallBack?: InputSearchValueCallBack;
}

export const inputSearchValueHandler = (params: IInputSearchValueParams) => {
  params.store.setSearchValue(params.value);
  if (params.inputSearchValueCallBack) params.inputSearchValueCallBack(params.value);
};