import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#EDEDED',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#EDEDED',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#CF2136,#CF2136 40%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EDEDED',
  },
});
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

const CHECKBOX = 'checkbox';
const EMPTY = '';

const Template = ({
  className,
  value,
  name,
  mode,
  onClickInGroup,
  onClick,
  onChange,
}) => {
  const [activeState, setActiveState] = useState(value ?? false);

  useEffect(() => {
    if (onChange) {
      onChange({ value: activeState, });
    }
  }, [activeState,]);
  useEffect(() => {
    setActiveState(value);
  }, [value,]);

  return (
    <FormControlLabel
      className={`${className} ${styles.wrapper}`}
      value={value}
      control={<BpRadio />}
      checked={mode?.toLowerCase() === CHECKBOX ? activeState : name === value}
      onClick={async () => {
        if (mode?.toLowerCase() === CHECKBOX) {
          await setActiveState(!activeState);
        } else {
          onClickInGroup({ name, });
        }

        if (onClick) {
          onClick({ value: !activeState, });
        }
      }}
    />
  );
};

export default Template;