import { useState, useEffect } from 'react';
import { Position } from '../AppDialog.types';

export default function usePosition(position: Position) {
  const [state, setState] = useState(position);

  useEffect(() => {
    setState(position);
  }, []);

  return state;
}