import React, { useState, useEffect } from 'react';
import { useNavigate, } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';

import { observer } from 'mobx-react-lite';
import StickerPackItem from '@store/workspace/stickerPack/item';
import dictionary from '../../../store/dictionary';

import StickersTab from '../../../components/stickers/pack/tabs/stickersTab';
import InformationTab from '../../../components/stickers/pack/tabs/informationTab';

import { packAuthors } from '@api/rest/packs/packAuthors';

const StickerPack = observer(
  ({ params }) => {
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    const [preload, setPreload] = useState(false);
    const [packAuthorsState, setPackAuthorsState] = useState([]);

    useEffect(
      async () => {
        await StickerPackItem.setUuid(
          params
            ? params.uuid || null
            : null
        );

        console.debug("StickerPackItem.pack.uuid", StickerPackItem.pack.uuid);

        if (StickerPackItem.pack.uuid) {
          await getters.getPack({ uuid: StickerPackItem.pack.uuid, });
        }

        await StickerPackItem.parseStickersToDisplay();
        await dictionary.fetchCountries();
        await dictionary.fetchCategories();
        await dictionary.fetchLanguages();

        setPackAuthorsState(await packAuthors());
      },

      []
    );

    const staticData = {
      fileCount: 0,
      fileCounter: 0,
      stickers: [],
    }
    const getters = {
      getTabStyles: (payload = {}) => {
        if (payload.tab === tab) {
          return 'create__nav-button create__nav-button_active';
        }
        else {
          return 'create__nav-button';
        }
      },
      getPack: async (payload = null) => {
        console.debug('getPack', payload); // DELETE

        await StickerPackItem.reset();
        setTab(-1);

        await StickerPackItem.fetch({ uuid: payload.uuid });
        setTab(0);
      },
      getConfirmBtn() {
        if (StickerPackItem.pack.publish) {
          return (
            <a
              className="button section__button button__save button_orange"
              onClick={() => handlers.unpublishPack()}
            >
              {
                preload
                  ? <svg
                    className="button__preloader"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    style={{ fill: '#FFFFFF' }}
                  >
                    <path
                      d="M 16 4 L 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 12.734375 7.585938 9.851563 10 8.03125 L 10 13 L 12 13 L 12 5 L 4 5 L 4 7 L 8.09375 7 C 5.59375 9.199219 4 12.417969 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z"
                    />
                  </svg>
                  : 'Снять с публикации'
              }
            </a>
          );
        } else if (StickerPackItem.saved) {
          return (
            <a
              className="button section__button button__save button_green"
              onClick={() => handlers.publishPack()}
            >
              {
                preload
                  ? <svg
                    className="button__preloader"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    style={{ fill: '#FFFFFF' }}
                  >
                    <path
                      d="M 16 4 L 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 12.734375 7.585938 9.851563 10 8.03125 L 10 13 L 12 13 L 12 5 L 4 5 L 4 7 L 8.09375 7 C 5.59375 9.199219 4 12.417969 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z"
                    />
                  </svg>
                  : 'Опубликовать'
              }
            </a>
          );
        } else {
          return (
            <a
              className="button section__button button__save"
              onClick={() => handlers.savePack()}
            >
              {
                preload
                  ? <svg
                    className="button__preloader"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    style={{ fill: '#FFFFFF' }}
                  >
                    <path
                      d="M 16 4 L 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 12.734375 7.585938 9.851563 10 8.03125 L 10 13 L 12 13 L 12 5 L 4 5 L 4 7 L 8.09375 7 C 5.59375 9.199219 4 12.417969 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z"
                    />
                  </svg>
                  : 'Сохранить'
              }
            </a>
          );
        }
      },
    }
    const handlers = {
      onTabClick: (payload = {}) => {
        setTab(payload.tab);
      },
      publishPack: async () => {
        if (!preload) {
          setPreload(true);
          await StickerPackItem.publish();
          await StickerPackItem.fetch({ uuid: StickerPackItem.pack.uuid, });
          await StickerPackItem.parseStickersToDisplay();
          setPreload(false);
        }
      },
      unpublishPack: async () => {
        if (!preload) {
          setPreload(true);
          await StickerPackItem.unpublish();
          await StickerPackItem.fetch({ uuid: StickerPackItem.pack.uuid, });
          await StickerPackItem.parseStickersToDisplay();
          setPreload(false);
        }
      },
      savePack: async () => {
        if (!preload) {
          setPreload(true);
          const IS_NEW = !StickerPackItem.pack.uuid;

          await StickerPackItem.save();

          if (!IS_NEW) {
            await StickerPackItem.fetch({ uuid: StickerPackItem.pack.uuid, });
            await StickerPackItem.parseStickersToDisplay();
          } else {
            navigate(`/stickerpacks/${StickerPackItem.pack.uuid}`);
          }

          setPreload(false);
        }
      },
    }
    const renderer = {
      renderTab: (payload = {}) => {
        switch (tab) {
          case -1:
            return (
              <div className="detail-pack--preloader">
                <CircularProgress size={80} color='error' />
              </div>
            );

          case 0:
            return <StickersTab />;

          case 1:
            return <InformationTab authors={packAuthorsState} />;

          default:
            return <></>;
        }
      }
    }

    return (
      <div className="create">
        <div className="overlay"></div>

        <div className="section-wrapper">
          <div className="section create__section" style={{ width: '1128px' }}>
            <header className="section__header">
              {getters.getConfirmBtn()}
            </header>

            <nav className="create__nav">
              <ul className="create__nav-list">
                <li>
                  <button
                    className={getters.getTabStyles({ tab: 0 })}
                    onClick={event => handlers.onTabClick({ event, tab: 0 })}
                  >
                    Стикеры
                  </button>
                </li>

                <li>
                  <button
                    className={getters.getTabStyles({ tab: 1 })}
                    onClick={event => handlers.onTabClick({ event, tab: 1 })}
                  >
                    Информация
                  </button>
                </li>
              </ul>
            </nav>

            <div className="add-stickers">
              {renderer.renderTab()}
            </div>
          </div>
        </div>
      </div>
    )
  }
);

export default StickerPack;