import React, { useState, useEffect, } from 'react';
import Template from './template';

const ARProgressbar = ({ className, value, }) => {
  const [valueState, setValueState] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setValueState(value);
    }, 500);
  }, [value]);

  return (
    <Template className={className} value={valueState} />
  );
};

export default ARProgressbar;