import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.83456 6.54492C3.82195 2.66286 7.8604 0 12.5142 0C19.1297 0 24.5142 5.38453 24.5142 12C24.5142 18.6155 19.1297 24 12.5142 24C7.8604 24 3.82195 21.3371 1.83456 17.4551C1.6544 17.1354 1.66377 16.7428 1.85896 16.432C2.05416 16.1213 2.40383 15.9425 2.77001 15.966C3.13619 15.9896 3.46007 16.2118 3.61385 16.5449C5.27046 19.7809 8.62208 22 12.5142 22C18.0488 22 22.5142 17.5345 22.5142 12C22.5142 6.46547 18.0488 2 12.5142 2C8.62208 2 5.27046 4.21914 3.61385 7.45508C3.46007 7.78823 3.13619 8.01042 2.77001 8.03398C2.40383 8.05753 2.05416 7.87866 1.85897 7.56795C1.66377 7.25724 1.6544 6.86459 1.83456 6.54492ZM10.5781 7.614C10.7313 7.23699 11.0976 6.99034 11.5045 6.99023C11.7745 6.99025 12.033 7.09944 12.2213 7.29297L16.1314 11.2031C16.3802 11.392 16.5265 11.6862 16.5269 11.9985C16.5273 12.3109 16.3817 12.6055 16.1334 12.7949L12.2213 16.707C11.9705 16.9683 11.598 17.0735 11.2476 16.9822C10.8971 16.8908 10.6235 16.6171 10.5321 16.2667C10.4407 15.9162 10.546 15.5438 10.8072 15.293L13.1002 13H1.51424C1.15361 13.0051 0.818161 12.8156 0.636355 12.5041C0.454548 12.1926 0.454548 11.8074 0.636355 11.4959C0.818161 11.1844 1.15361 10.9949 1.51424 11H13.1002L10.8072 8.70703C10.5156 8.42322 10.425 7.99102 10.5781 7.614Z" fill={colorState} />
    </svg>
  );
};

export default ArtlionLogoVertical;