import { getPercent, } from '@utils/math';

export const parseDataByCountry = async ({ data, }) => {
  if (!data.countriesRatio) {
    return null;
  }

  return data.countriesRatio;
};
export const getOtherPercent = (other, total) => {
  return getPercent(other, total);
};
export const getOtherText = (other, total, field) => {
  if (field === 'count') {
    return Math.round(getOtherPercent(other, total));
  }

  return other;
};