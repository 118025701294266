import React from "react";
import AppButton from '@presentation/components/AppButton/AppButton';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import { AppButtonSaveParams } from './AppButtonSave.interfaces';
import './AppButtonSave.scss';

const AppButtonSave = (params: AppButtonSaveParams) => {
  return (
    <AppButton
      className={`app-button-save ${params.className}`}
      variant="contained"
      loaderColor="white"
      onClick={params.onClick}
      loading={params.loading}
      disabled={params.loading || params.disabled}
      background="#4CD964"
    >
      <div className="app-button-save__container">
        <div className="app-button-save__title">Сохранить</div>
        <div className="app-button-save__logo">
          <AppSvgIcon className="app-button-save__logo--svg" name="save" color="#FFFFFF" />
        </div>
      </div>
    </AppButton>
  );
};

export default AppButtonSave;