import { useMemo } from 'react';

export default function useLanguageCodes(languageCodesRead: any, languageCodesReadAdd: any) {
  return useMemo(() => {
    console.debug('useLanguages/useMemo/languagesRead', languageCodesRead); //DELETE
    console.debug('useLanguages/useMemo/languagesReadAdd', languageCodesReadAdd); //DELETE

    if (!languageCodesRead || !languageCodesReadAdd) return undefined;

    return [
      ...languageCodesRead,
      ...languageCodesReadAdd,
    ];
  }, [languageCodesRead, languageCodesReadAdd,]);
}