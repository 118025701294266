import React from 'react';
import AccountsPageContext from '@contexts/AccountsPageContext'
import ARCard from '@components/UI/ARCard';
import AccountsTableHeader from '@components/pages/accounts/AccountsTableHeader';
import AccountsTableBody from '@components/pages/accounts/AccountsTableBody';

const Template = () => {
  return (
    <AccountsPageContext.Consumer>
      {({ accounts, }) => (
        <div>
          {console.debug('Template::accounts', accounts) /* DELETE */}

          <ARCard className="backdrop--card">
            <AccountsTableHeader />
            <AccountsTableBody accounts={accounts} />
          </ARCard>
        </div>
      )}
    </AccountsPageContext.Consumer>
  );
};

export default Template;