import {
  ICancelParams,
  ISaveParams,
  IAddParams,
  IDeleteParams,
  ISetDefaultStatusParams,
  ISetLanguageLocalNameParams,
  ISetLanguageLocalCodeParams,
} from './LanguageForm.interfaces';
import { createLanguage, } from '@api/rest/dictionaries/languages/languages';

export const cancel = (params?: ICancelParams) => {
  if (!params?.store.isLoaded) return;
  if (params?.callback) params.callback();
};
export const save = async (params: ISaveParams) => {
  if (!params.store.languageCode || !params.store.languageCodesLocals.length) return;

  params.store.setIsSaving(true);

  const createLanguageResponse = await createLanguage({
    languageCode: params?.store.languageCode,
    languageCodesLocals: params?.store.languageCodesLocals,
  });

  console.debug('save/createLanguageResponse/createLanguageResponse', createLanguageResponse); //DELETE

  params.store.setIsSaving(false);

  if (params?.callback) params.callback();
};
export const add = (params?: IAddParams) => {
  params?.store.addLanguageCodesLocal();
  if (params?.callback) params.callback();
};
export const deleteItem = (params: IDeleteParams) => {
  params?.store.deleteLanguageCodesLocal(params.uuid);
  if (params?.callback) params.callback();
};
export const setDefaultStatus = (params?: ISetDefaultStatusParams) => {
  params?.store.setDefaultStatus(params.uuid, params.status);
  if (params?.callback) params.callback();
};
export const setLanguageLocalName = (params: ISetLanguageLocalNameParams) => {
  params.store.setLanguageLocalName(params.uuid, params.value);
  if (params?.callback) params.callback();
};
export const setLanguageLocalCode = (params: ISetLanguageLocalCodeParams) => {
  params.store.setLanguageLocalCode(params.uuid, params.languageCode);
  if (params?.callback) params.callback();
};