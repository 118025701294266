import { analyticsMock, analyticsPacksMock, } from '@mocks/analytics';
import { getCurrentTimestampInMillseconds, } from '@utils/date';
import { nFormatter, } from '@utils/math';

const parseSalesToFavoritesRatio = ({ sales }) => {
  return Object.keys(sales).map((key) => ({
    date: key,
    favorites: sales[key].favorites,
    sales: sales[key].sales
  }))
};
const parseCountriesRatioPercentage = ({ percentage, }) => {
  let countries = [];

  for (let i = 0; i < 3; i++) {
    if (percentage[i]) {
      countries.push({
        "name": percentage[i].name,
        "count": percentage[i].count
      })
    }
  }

  return {
    countries,
    other: percentage.other,
    total: percentage.total,
  };
};
const parseCountriesRatioPrices = ({ prices, }) => {
  return {
    countries: [
      { "name": "EN", "price": 723 },
      { "name": "DE", "price": 511 },
      { "name": "RU", "price": 300 },
    ],
    other: 450,
    total: 1984,
  };
};

export const analyticsIncomingAdapter = ({ data, isMock, }) => {
  if (isMock) {
    return analyticsMock({
      count: 30,
      startDate: getCurrentTimestampInMillseconds(),
    });
  }

  if (data) {
    return {
      salesToFavoritesRatio: parseSalesToFavoritesRatio({ sales: data.salesToFavoritesRatio }),
      platformsRatio: data.platformsRatio,
      countriesRatio: {
        percentage: parseCountriesRatioPercentage({ percentage: data.countriesRatio.percentage }),
        prices: parseCountriesRatioPrices({ prices: data.countriesRatio.prices }),
      },
    };
  }

  return {};
};
export const analyticsPacksIncomingAdapter = ({ data, isMock, }) => {
  console.debug('analyticsPacksIncomingAdapter', data); //DELETE

  if (isMock) {
    return analyticsPacksMock({ count: 30, });
  }

  if (data) {
    console.debug('analyticsPacksIncomingAdapter[HAVE]', data); //DELETE

    return data.map(item => ({
      uuid: item.uuid,
      select: false,
      avatar: item.preview,
      name: item.name,
      download: nFormatter(item.download, 1),
      price: nFormatter(item.price, 1),
    }));
  }

  return [];
};