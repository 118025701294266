import React, { useState, useEffect, } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import earthImg from '@assets/img/earth.svg';
import TwoTabsBtn from '@components/UI/TwoTabsBtn';
import { renderCountriesCount, renderCountriesPrice, } from './services/renderers';
import Country from './components/Country';
import styles from './styles.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const Template = ({ data, }) => {
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [dataState, setDataState] = useState(data);

  useEffect(() => {
    setDataState(data);
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Топ стран</div>
        <div className={styles.tabs}>
          <TwoTabsBtn
            value={value}
            handleChange={handleChange}
            leftTab="%"
            rightTab="$"
          />
        </div>
      </div>

      <div className={styles.body}>
        <img className={styles.earth} src={earthImg} alt="" />

        <div className={styles.countries}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {renderCountriesCount(dataState)}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {renderCountriesPrice(dataState)}
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
};

export default Template;