export const accountsMock = () => {
  return [
    {
      uuid: "a3a7f3cb-cd0f-42d9-ae2d-956b2eea64c_1",
      name: 'name 1',
      role: 'user',
      email: 'email@mail.com',
      percentage: '45',
      packs: [
        {
          uuid: "a3a7f3cb-cd0f-42d9-ae2d-956b2eea64c6",
          name: "name eng",
          preview: "http://localhost:80/storage/7/68cbe2c7-61a7-49b8-9397-e2c7393862ed.halloween_tray.png"
        },
        {
          uuid: "16aa6830-a1ba-4ab1-bfa4-cb89d3c8dcc3",
          name: "new test pack",
          preview: "http://localhost:80/storage/5/f19c66ca-f71b-45c2-b671-4e7f76dc612e.96_logo.png"
        },
        {
          uuid: "097b062b-7ebc-4af2-8b5f-ac760f8f5bc0",
          name: "love",
          preview: null
        },
        {
          uuid: "097b062b-7ebc-4af2-8b5f-ac760f8f5bc9",
          name: "love 9",
          preview: "http://localhost:80/storage/5/f19c66ca-f71b-45c2-b671-4e7f76dc612e.96_logo.png"
        },
      ],
    },
    {
      uuid: "a3a7f3cb-cd0f-42d9-ae2d-956b2eea64c_2",
      name: 'name 1',
      role: 'celebrity',
      email: 'email@mail.com',
      percentage: '15',
      packs: [
        {
          uuid: "a3a7f3cb-cd0f-42d9-ae2d-956b2eea64c6",
          name: "name eng",
          preview: "http://localhost:80/storage/7/68cbe2c7-61a7-49b8-9397-e2c7393862ed.halloween_tray.png"
        },
        {
          uuid: "16aa6830-a1ba-4ab1-bfa4-cb89d3c8dcc3",
          name: "new test pack",
          preview: "http://localhost:80/storage/5/f19c66ca-f71b-45c2-b671-4e7f76dc612e.96_logo.png"
        },
      ],
    },
    {
      uuid: "a3a7f3cb-cd0f-42d9-ae2d-956b2eea64c_3",
      name: 'name 1',
      role: 'painter',
      email: 'email@mail.com',
      percentage: '35',
      packs: [
        {
          uuid: "16aa6830-a1ba-4ab1-bfa4-cb89d3c8dcc3",
          name: "new test pack",
          preview: "http://localhost:80/storage/5/f19c66ca-f71b-45c2-b671-4e7f76dc612e.96_logo.png"
        },
      ],
    },
  ];
};