export const chartData = {
  labels: [],
  datasets: [
    {
      label: 'Продано',
      data: [12],
      backgroundColor: '#CF2136',
    },
    {
      label: 'Избранное',
      data: [15],
      backgroundColor: '#2B2B2B',
    },
  ],
};