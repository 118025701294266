import AnalyticsStore from '@store/analytics';

export const selectAllPacks = async ({ value, }) => {
  console.debug('selectAllPacks', value, AnalyticsStore.packs); //DELETE

  const packs = await AnalyticsStore.setPacks({
    packs: AnalyticsStore.packs.map((pack) => ({
      ...pack,
      select: value,
    }))
  });

  console.debug('selectAllPacks::packs', packs); //DELETE

  return packs;
}
export const selectPack = async ({ value, uuid, }) => {
  const packs = await AnalyticsStore.setPacks({
    packs: AnalyticsStore.packs.map((pack) => ({
      ...pack,
      select: pack.uuid === uuid ? value : pack.select,
    }))
  });

  return packs;
};

export const getSelectedPacks = async () => {
  const packs = await AnalyticsStore.packs
    .filter(pack => pack.select)
    .map(pack => pack.uuid);

  console.debug('getSelectedPacks', packs); //DELETE

  return packs;
};