import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg width="33" height="317" viewBox="0 0 33 317" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 100.5L0.5 317.5H32.5L32.5 100.5H0.5ZM21.9095 178.996L26.535 180.663V185.367L5.76406 177.328V172.535L26.535 164.586V169.439L21.9095 171.047L21.9095 178.996ZM18.5059 177.775L18.5059 172.267L10.8841 174.947V175.006L18.5059 177.775ZM26.535 149.79H18.4186V145.116C18.4186 143.945 18.6707 143.101 19.175 142.585C19.6792 142.069 20.4744 141.732 21.5604 141.573C22.3944 141.454 23.2671 141.365 24.1786 141.305C25.0901 141.246 25.8756 141.087 26.535 140.829V136.155C26.2441 136.373 25.8901 136.537 25.4731 136.646C25.0562 136.755 24.615 136.834 24.1495 136.884C23.6841 136.934 23.2283 136.968 22.7822 136.988C22.3362 137.008 21.9483 137.028 21.6186 137.048C21.095 137.088 20.5713 137.162 20.0477 137.271C19.5241 137.38 19.0441 137.554 18.6077 137.792C18.1713 138.03 17.7931 138.338 17.4731 138.715C17.1531 139.092 16.9156 139.568 16.7604 140.144H16.7022C16.2368 138.953 15.558 138.095 14.6659 137.569C13.7738 137.043 12.7168 136.78 11.495 136.78C10.6998 136.78 9.958 136.924 9.26951 137.212C8.58103 137.499 7.97497 137.916 7.45133 138.462C6.92769 139.008 6.51557 139.663 6.21497 140.427C5.91436 141.191 5.76406 142.049 5.76406 143.002L5.76406 154.464H26.535V149.79ZM15.1604 149.79V144.669C15.1604 143.598 14.9277 142.794 14.4622 142.258C13.9968 141.722 13.2404 141.454 12.1931 141.454C11.1847 141.454 10.4525 141.722 9.99679 142.258C9.54103 142.794 9.31315 143.598 9.31315 144.669V149.79H15.1604ZM26.535 115.315H9.60406V108.944H5.76406L5.76406 126.36H9.60406V119.989H26.535V115.315Z" fill={colorState} />
      <mask id="mask0_2287_6390" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="100" width="33" height="218">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 100.5L0.5 317.5H32.5L32.5 100.5H0.5ZM21.9095 178.996L26.535 180.663V185.367L5.76406 177.328V172.535L26.535 164.586V169.439L21.9095 171.047L21.9095 178.996ZM18.5059 177.775L18.5059 172.267L10.8841 174.947V175.006L18.5059 177.775ZM26.535 149.79H18.4186V145.116C18.4186 143.945 18.6707 143.101 19.175 142.585C19.6792 142.069 20.4744 141.732 21.5604 141.573C22.3944 141.454 23.2671 141.365 24.1786 141.305C25.0901 141.246 25.8756 141.087 26.535 140.829V136.155C26.2441 136.373 25.8901 136.537 25.4731 136.646C25.0562 136.755 24.615 136.834 24.1495 136.884C23.6841 136.934 23.2283 136.968 22.7822 136.988C22.3362 137.008 21.9483 137.028 21.6186 137.048C21.095 137.088 20.5713 137.162 20.0477 137.271C19.5241 137.38 19.0441 137.554 18.6077 137.792C18.1713 138.03 17.7931 138.338 17.4731 138.715C17.1531 139.092 16.9156 139.568 16.7604 140.144H16.7022C16.2368 138.953 15.558 138.095 14.6659 137.569C13.7738 137.043 12.7168 136.78 11.495 136.78C10.6998 136.78 9.958 136.924 9.26951 137.212C8.58103 137.499 7.97497 137.916 7.45133 138.462C6.92769 139.008 6.51557 139.663 6.21497 140.427C5.91436 141.191 5.76406 142.049 5.76406 143.002L5.76406 154.464H26.535V149.79ZM15.1604 149.79V144.669C15.1604 143.598 14.9277 142.794 14.4622 142.258C13.9968 141.722 13.2404 141.454 12.1931 141.454C11.1847 141.454 10.4525 141.722 9.99679 142.258C9.54103 142.794 9.31315 143.598 9.31315 144.669V149.79H15.1604ZM26.535 115.315H9.60406V108.944H5.76406L5.76406 126.36H9.60406V119.989H26.535V115.315Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2287_6390)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.6606 35.4278C27.2202 36.6986 27.5 38.1454 27.5 39.7681C27.5 41.3713 27.2202 42.8132 26.6606 44.0938C26.1011 45.3744 25.3304 46.4546 24.3487 47.3344C23.367 48.2141 22.2135 48.8887 20.8882 49.3579C19.5629 49.8271 18.1345 50.0617 16.6031 50.0617C15.0324 50.0617 13.5745 49.8271 12.2296 49.3579C10.8846 48.8887 9.71151 48.2141 8.71017 47.3344C7.70884 46.4546 6.92347 45.3744 6.35408 44.0938C5.78469 42.8132 5.5 41.3713 5.5 39.7681C5.5 38.1454 5.78469 36.6986 6.35408 35.4278C6.92347 34.157 7.70884 33.0817 8.71017 32.2019C9.71151 31.3221 10.8846 30.6476 12.2296 30.1784C13.5745 29.7092 15.0324 29.4745 16.6031 29.4745C18.1345 29.4745 19.5629 29.7092 20.8882 30.1784C22.2135 30.6476 23.367 31.3221 24.3487 32.2019C25.3304 33.0817 26.1011 34.157 26.6606 35.4278ZM27.0288 76.6901H23.1412V86.8957H6.00067V91.5H27.0288V76.6901ZM27.0288 61.3524H6.00067V65.9567H27.0288L27.0288 61.3524ZM23.6419 39.7681C23.6419 40.8043 23.4407 41.6841 23.0382 42.4075C22.6357 43.1309 22.1006 43.7174 21.4331 44.1671C20.7655 44.6168 20.0145 44.9442 19.1801 45.1495C18.3456 45.3548 17.4866 45.4575 16.6031 45.4575C15.6803 45.4575 14.7869 45.3548 13.923 45.1495C13.0591 44.9442 12.2885 44.6168 11.6111 44.1671C10.9337 43.7174 10.3938 43.1309 9.9913 42.4075C9.5888 41.6841 9.38755 40.8043 9.38755 39.7681C9.38755 38.7319 9.5888 37.8521 9.9913 37.1287C10.3938 36.4054 10.9337 35.8188 11.6111 35.3692C12.2885 34.9195 13.0591 34.592 13.923 34.3867C14.7869 34.1814 15.6803 34.0788 16.6031 34.0788C17.4866 34.0788 18.3456 34.1814 19.1801 34.3867C20.0145 34.592 20.7655 34.9195 21.4331 35.3692C22.1006 35.8188 22.6357 36.4054 23.0382 37.1287C23.4407 37.8521 23.6419 38.7319 23.6419 39.7681ZM12.9511 13.8729H27.0288V18.1839H6.00067V13.6089L20.1078 4.86964V4.81099H6.00067V0.5H27.0288L27.0288 5.10426L12.9511 13.8142V13.8729Z" fill={colorState} />
    </svg>

  );
};

export default ArtlionLogoVertical;