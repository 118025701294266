import { makeAutoObservable } from 'mobx';
import { LanguageCode } from '@domain/LanguageCode';
import { LanguageLocal } from '@domain/LanguageLocal';

export default class StateStore {
  languageCode: LanguageCode | null = null;
  languageCodes: Array<LanguageCode> | null = null;
  languageCodesLocals: Array<LanguageLocal> = [];
  isNew: boolean = true;
  isSaving: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoaded() {
    return !this.isNew
      ? !!this.languageCodes && this.languageCode && this.languageCodesLocals
      : !!this.languageCodes;
  }

  async setIsNew(value: boolean) {
    this.isNew = value;
  }

  async setIsSaving(value: boolean) {
    this.isSaving = value;
  }

  async setLanguageCode(languageCode: LanguageCode) {
    this.languageCode = languageCode;
  }

  async setLanguageCodesLocals(languageCodesLocals: Array<LanguageLocal>) {
    this.languageCodesLocals = languageCodesLocals;
  }

  async setLanguageCodes(languageCodes: Array<LanguageCode>) {
    this.languageCodes = languageCodes;
  }

  async setDefaultStatus(uuid: Uuid, status: boolean) {
    this.languageCodesLocals = this.languageCodesLocals?.map((item: any) => {
      return {
        ...item,
        default: item.uuid === uuid ? status : false,
      };
    });
  }

  async setLanguageLocalName(uuid: Uuid, value: string) {
    this.languageCodesLocals = this.languageCodesLocals?.map((item: any) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          name: value,
        }
      }

      return item;
    });
  }

  async setLanguageLocalCode(uuid: Uuid, languageCode: LanguageCode) {
    this.languageCodesLocals = this.languageCodesLocals?.map((item: any) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          languageCode,
        }
      }

      return item;
    });
  }

  async addLanguageCodesLocal() {
    this.languageCodesLocals = [
      ...this.languageCodesLocals,
      {
        uuid: new Date().getTime().toString(),
        languageCode: null,
        name: null,
        default: false,
      }
    ];
  }

  async deleteLanguageCodesLocal(uuid: Uuid) {
    this.languageCodesLocals = this.languageCodesLocals?.filter((item: any) => item.uuid !== uuid);
  }
}