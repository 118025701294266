export const getCurrentTimestampInSeconds = () => (Math.floor(Date.now() / 1000));
export const getCurrentTimestampInMillseconds = () => (Math.floor(Date.now()));
export const getTimestampInSeconds = ({ date, }) => (Math.floor(date / 1000));
export const getDateByTimestamp = (timestamp) => {
  console.debug('getDateByTimestamp', timestamp); //DELETE

  let date = new Date(Number(timestamp));

  console.log(date.getDate()+
          "/"+(date.getMonth()+1)+
          "/"+date.getFullYear())

  return `${date.getDate(new Date())}.${date.getMonth(new Date()) + 1}.${date.getFullYear(new Date())}`;
};