import { IOS, ANDROID, } from '@shared/platforms';

export const parseDataByPlatform = ({ data, }) => {
  if (!data.platformsRatio) return null;

  return {
    ios: {
      count: data.platformsRatio[IOS],
      percent: Math.round((data.platformsRatio[IOS] / data.platformsRatio.total) * 100),
    },
    android: {
      count: data.platformsRatio[ANDROID],
      percent: Math.round((data.platformsRatio[ANDROID] / data.platformsRatio.total) * 100),
    },
  };
};