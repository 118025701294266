import { ChangeEvent } from 'react';

export type AppTextFieldTheme = string;
export type AppTextFieldVariant = AppTextFieldVariants | undefined;
export enum AppTextFieldVariants {
  Standard = "standard",
  Filled = "filled",
  Outlined = "outlined",
};
export type AppTextFieldInputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
