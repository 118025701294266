import { api } from '@api';
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

export const signin = async ({ email, password, }: SigninParams) => {
  console.debug("api/rest/auth/signin/params", { email, password }); // DELETE

  const response = await api.post({
    gateway: artlionUkApiGateway,
    url: 'auth/login',
    params: { email, password },
  });

  console.log('api/rest/auth/signin/response', response); //DELETE

  return response;
}