import React from "react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ARTextField from '@presentation/components/ui/ARTextField/ARTextField.index';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import { ITableBodyRowProps } from './TableBodyRow.interfaces';
import './TableBodyRow.scss';

const TableBodyRow = (params: ITableBodyRowProps) => {
  const [socialNameErrorState, setSocialNameErrorState] = React.useState(false);
  const [socialNameErrorTextState, setSocialNameErrorTextState] = React.useState('');
  const [socialNameState, setSocialNameState] = React.useState<string | null>(params.code || null);

  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/socialNameState', socialNameState); //DELETE

    if (socialNameState !== null) {
      setSocialNameErrorState(!socialNameState.length);
      params.inputLanguageCodeName(params.uuid + '', socialNameState + '');
    }
  }, [socialNameState]);
  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/socialNameState', socialNameState); //DELETE

    if (socialNameErrorState) {
      setSocialNameErrorTextState('Заполните поле');
    } else {
      setSocialNameErrorTextState('');
    }

  }, [socialNameErrorState]);

  if (params.isNew) {
    return (
      <TableRow>
        <TableCell sx={{ borderBottom: "none", width: '50%', }}>
          <ARTextField
            className="socials-settings-table-body-row__input"
            theme='standard'
            label="Email"
            variant="filled"
            fullWidth={true}
            error={socialNameErrorState}
            helperText={socialNameErrorTextState}
            onInput={setSocialNameState}
            value={socialNameState}
          />
        </TableCell>

        <TableCell sx={{ borderBottom: "none", width: '50%', }} align="right">
          <AppSvgIcon
            className="socials-settings-table-body-row__actions--delete"
            name="delete"
            color="#CF2136"
            onClick={() => { params.delete(params.uuid + '') }}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell className="socials-settings-table-body-row__cell" sx={{ borderBottom: "none", width: '25%', }}>
        <div className="socials-settings-table-body-row__cell-container">
          {socialNameState}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableBodyRow;