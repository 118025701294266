import React, { useState, useEffect, } from 'react';
import { observer, } from 'mobx-react-lite';
import { parseDataByCountry, } from './services/getters';
import Template from './template';
import AnalyticsStore from '@store/analytics';

const TopCountries = observer(() => {
  const [state, setState] = useState({});

  useEffect(async () => {
    const DATA = await parseDataByCountry({ data: AnalyticsStore.analytics, });

    setState(DATA);
  }, [AnalyticsStore.analytics]);

  return (
    <Template
      data={state}
    />
  );
});

export default TopCountries;