import React from "react";
import { fetchLanguageCodes, createLanguageCodes } from '@api/rest/dictionaries/languageCodes/languageCodes';
import ARTable from '@presentation/components/ui/ARTable/ARTable';
import AppTableHeader from '@presentation/components/AppTableHeader/AppTableHeader';
import { ILanguageCodesParams } from './LanguageCodes.interfaces';
import TableHeadRow from './components/TableHeadRow/TableHeadRow';
import TableBodyRow from './components/TableBodyRow/TableBodyRow';
import useLanguageCodes from './hooks/useLanguageCodes';
import useLanguageCodesLoading from './hooks/useLanguageCodesLoading';

const LanguageCodes = (params: ILanguageCodesParams) => {
  const [saveLoadingState, setSaveLoadingState] = React.useState<boolean>(false);
  const [saveDisabledState, setSaveDisabledState] = React.useState<boolean>(true);
  const [languageCodesRead, setLanguageCodesRead] = React.useState<Array<any> | null>(null);
  const [languageCodesAdd, setLanguageCodesAdd] = React.useState<Array<any>>([]);
  const languageCodes = useLanguageCodes(languageCodesRead, languageCodesAdd);
  const languageCodesLoading = useLanguageCodesLoading(languageCodes);

  React.useEffect(() => {
    fetchLanguageCodes().then((response) => {
      setLanguageCodesRead(response);
    });
  }, []);
  React.useEffect(() => {
    setSaveDisabledState(!languageCodesAdd.length);
  }, [languageCodesAdd.length]);

  const deleteLanguageCode = (uuid: Uuid) => {
    console.debug('LanguageCodes/deleteLanguageCode/uuid', uuid); //DELETE

    if (saveLoadingState) {
      return;
    }

    setLanguageCodesAdd(languageCodesAdd.filter((languageCodeAdd) => languageCodeAdd.uuid !== uuid));
  };
  const inputLanguageCodeName = (uuid: Uuid, name: string) => {
    if (saveLoadingState) {
      return;
    }

    setLanguageCodesAdd(languageCodesAdd.map((languageCodeAdd) => {
      if (languageCodeAdd.uuid === uuid) {
        return {
          ...languageCodeAdd,
          code: name,
        };
      }

      return languageCodeAdd;
    }));
  };
  const save = async () => {
    if (!await validationOnSave()) {
      alert('Данные введены некорректно');
      return;
    }

    setSaveLoadingState(true);
    await createLanguageCodes(languageCodesAdd);
    const languageCodes = await fetchLanguageCodes();
    setLanguageCodesAdd([]);
    setLanguageCodesRead(languageCodes);
    setSaveLoadingState(false);
  };
  const addLanguageCode = () => {
    setLanguageCodesAdd([...languageCodesAdd, {
      uuid: new Date().getTime().toString(),
      isNew: true,
      code: '',
    }]);
  };
  const validationOnSave = async () => {
    let isValid = true;
    languageCodesAdd.forEach(languageCode => isValid = !!languageCode.code);
    return isValid;
  };

  return (
    <ARTable
      className={params.className}
      variant="contained"
      header={<AppTableHeader
        save={save}
        add={addLanguageCode}
        stub={languageCodesLoading}
        saveLoading={saveLoadingState}
        saveDisabled={saveDisabledState}
      />}
      tableHeadRow={<TableHeadRow />}
      tableBodyRow={<TableBodyRow
        delete={deleteLanguageCode}
        inputLanguageCodeName={inputLanguageCodeName}
      />}
      items={languageCodes}
      loading={languageCodesLoading}
      emptyActionBtnLabel="Добавить соц. сеть"
      onEmptyActionBtnClick={addLanguageCode}
      maxHeight={'400px'}
    />
  );
};

export default LanguageCodes;