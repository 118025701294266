import React from 'react';
import ARProgressbar from '@components/UI/ARProgressbar';
import styles from './styles.module.scss';

const Template = ({ title, value, text, }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title}
      </div>

      <ARProgressbar 
        className={styles.chart} 
        value={value}
      />

      <div className={styles.text}>
        {text}
      </div>
    </div>
  );
}

export default Template;