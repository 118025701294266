import { PackAuthor } from '@domain/PackAuthor';
import { api } from '@api';
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

export const packAuthors = async (): Promise<Array<PackAuthor>> => {
  console.debug('api/rest/account/packAuthors'); //DELETE

  const response = await api.get({
    gateway: artlionUkApiGateway,
    url: 'admin/packs/authors',
  });

  console.debug('api/rest/account/packAuthors/response', response); //DELETE

  return response.data;
};