import * as React from "react";
import SocialsSettings from './components/SocialsSettings/SocialsSettings';
import LanguageCodes from './components/LanguageCodes/LanguageCodes';
import Languages from './components/Languages/Languages';
import './SettingsData.scss';

const SettingsData = () => {
  return (
    <div className="settings-data">
      <SocialsSettings className="settings-data__item" />
      <LanguageCodes className="settings-data__item" />
      <Languages className="settings-data__item" />
    </div>
  );
};

export default SettingsData;