import React, { useEffect } from 'react';
import Template from './template';

const ARRadioButton = ({
  className,
  value,
  mode,
  name,
  onClickInGroup,
  onClick,
  onChange,
}) => {
  return (
    <Template
      className={className}
      value={value}
      mode={mode}
      name={name}
      onClickInGroup={onClickInGroup}
      onClick={onClick}
      onChange={onChange}
    />
  );
};

export default ARRadioButton;