import { getPercent, } from '@utils/math';
import { getOtherPercent, getOtherText, } from './getters';
import Country from '../components/Country';

export const renderCountriesCount = (data) => {
  if (!data?.percentage) return <></>;

  return renderCountries(data.percentage, 'count', 'other', 'total', '%');
};
export const renderCountriesPrice = (data) => {
  if (!data?.prices) return <></>;

  return renderCountries(data.prices, 'price', 'other', 'total', '$');
};

const renderCountries = (data, field, other, total, unit) => {
  const COUNTRIES = [...data.countries];

  COUNTRIES.sort((a, b) => (b[field] - a[field]));

  return (
    <>
      {COUNTRIES.slice(0, 3).map((country) => (
        <Country
          title={country.name}
          value={getPercent(country[field], data[total])}
          text={`${field == 'count' ? Math.round(getPercent(country[field], data[total])) : country[field]}${unit}`}
        />
      ))}

      <Country
        title={'Другие'}
        value={getOtherPercent(data[other], data[total])}
        text={`${getOtherText(data[other], data[total], field)}${unit}`}
      />
    </>
  );
};