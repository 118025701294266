import * as React from 'react';
import LanguageFormContext from './LanguageForm.contexts';
import StateStore from './store/StateStore';

interface Props {
  children: React.ReactNode;
  stateStore: StateStore;
}

const LanguageFormProvider = (params: Props) => {
  const [stateStoreState, setStateStoreState] = React.useState<StateStore>(new StateStore());

  React.useEffect(() => {
    console.debug('LanguageFormProvider[useEffect]', params.stateStore); //DELETE
    setStateStoreState(params.stateStore);
  }, [params.stateStore.languageCodes]);

  return (
    <LanguageFormContext.Provider value={{ stateStore: stateStoreState, }}>
      {params.children}
    </LanguageFormContext.Provider>
  );
}

export default LanguageFormProvider;