import React from 'react';
import Calendar from './svg/Сalendar';
import Search from './svg/Search';

const ARSvg = ({ name, color, width, height, }) => {
  return getSvgByName({ name, color, width, height, });
};

const getSvgByName = ({ name, color, width, height, }) => {
  console.debug('getSvgByName', { name, color, width, height, }); //DELETE

  switch (name.toLowerCase()) {
    case 'calendar':
      return <Calendar color={color} width={width} height={height} />;

    case 'search':
      return <Search color={color} width={width} height={height} />;

    default:
      return <></>;
  }
};

export default ARSvg;