import { SocialNetwork } from '@domain/SocialNetwork';
import { api } from '@api';
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

export const socialNetworksDictionaryIndex = async (): Promise<Array<SocialNetwork>> => {
  console.debug('api/rest/dictionaries/socialNetworksDictionaryIndex'); //DELETE

  const response = await api.get({
    gateway: artlionUkApiGateway,
    url: 'admin/dictionaries/social-networks',
  });

  console.debug('api/rest/dictionaries/socialNetworksDictionaryIndex/response', response); //DELETE

  return response.data;
};
export const socialNetworksDictionaryCreate = async (socialNetwork: SocialNetwork) => {
  console.debug('api/rest/dictionaries/socialNetworksDictionaryCreate/socialNetwork', socialNetwork); //DELETE

  const socialNetworkToAdd = new FormData();

  socialNetworkToAdd.append('name', socialNetwork.name!);
  socialNetworkToAdd.append('icon', socialNetwork.file!);

  console.debug('api/rest/dictionaries/socialNetworksDictionaryCreate/socialNetworkToAdd', socialNetworkToAdd); //DELETE

  await api.post({
    gateway: artlionUkApiGateway,
    url: 'admin/dictionaries/social-networks',
    params: socialNetworkToAdd,
    properties: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  });
};
export const socialNetworksDictionaryRead = async () => {
  console.debug('api/rest/dictionaries/socialNetworksDictionaryRead'); //DELETE
};
export const socialNetworksDictionaryUpdate = async () => {
  console.debug('api/rest/dictionaries/socialNetworksDictionaryUpdate'); //DELETE
};
export const socialNetworksDictionaryDelete = async () => {
  console.debug('api/rest/dictionaries/socialNetworksDictionaryDelete'); //DELETE
};