import {
  AppInputPictureLabel,
  AppInputPictureLabelTop,
  AppInputPictureLabelRight,
  AppInputPictureLabelBottom,
  AppInputPictureLabelLeft,
  AppInputPictureLabelPositonClassName,
} from './AppInputPicture.types';
import { IAppInputPictureParams } from './AppInputPicture.interfaces';

export const getLabel = (params: IAppInputPictureParams): AppInputPictureLabel => {
  return getLabelTop(params) ||
    getbelRight(params) ||
    getelBottom(params) ||
    getabelLeft(params) ||
    '';
};
export const getLabelTop = (params: IAppInputPictureParams): AppInputPictureLabelTop => {
  return params.labelTop;
};
export const getbelRight = (params: IAppInputPictureParams): AppInputPictureLabelRight => {
  return params.labelRight;
};
export const getelBottom = (params: IAppInputPictureParams): AppInputPictureLabelBottom => {
  return params.labelBottom;
};
export const getabelLeft = (params: IAppInputPictureParams): AppInputPictureLabelLeft => {
  return params.labelLeft;
};
export const getLabelPositionClass = (params: IAppInputPictureParams): AppInputPictureLabelPositonClassName => {
  if (!!getLabelTop(params)) {
    return 'top';
  };
  if (!!getbelRight(params)) {
    return 'right';
  };
  if (!!getelBottom(params)) {
    return 'bottom';
  };
  if (!!getabelLeft(params)) {
    return 'left';
  };

  return 'bottom';
};