import React from 'react';
import Template from './template';

const ARSearch = ({ className, onInput, }) => {
  return (
    <Template
      className={className}
      onInput={onInput}
    />
  );
};

export default ARSearch;