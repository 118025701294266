import { Props } from './AppButton.interfaces';

export const setStylesByVariant = (params: Props): React.CSSProperties => { //FIXME:implement with switch by variant
  switch (params.variant) {
    case 'contained':
      return setStylesForContained(params);

    case 'outlined':
      return setStylesForOutlined(params);

    default:
      return {};
  }
}
export const setStylesForContained = (params: Props): React.CSSProperties => {
  return {
    background: setBackgroundColorForContained(params),
  };
};
export const setStylesForOutlined = (params: Props): React.CSSProperties => {
  return {};
};
export const setBackgroundColorForContained = (params: Props): string => {
  if (params.disabled && !params.loading) {
    return '#D7D7D7';
  }

  return params.background || '#CF2C37';
};