import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { renderTableHead, renderTableBody } from './services/renderers';
import { tableHeadCellStyles, columns, } from './services/data';
import styles from './styles.module.scss';

const AccountsTableBody = ({ accounts, }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [accountsState, setAccountsState] = useState([]);

  useEffect(async () => {
    console.debug('AccountsTableBody::accounts', accounts); //DELETE

    setAccountsState(accounts);

    console.debug('AccountsTableBody::accountsState', accountsState); //DELETE
  }, [accounts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {renderTableHead({ columns, tableHeadCellStyles, })}
          </TableHead>
          <TableBody>
            {renderTableBody({ columns, rows: accountsState, })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={accounts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default AccountsTableBody;