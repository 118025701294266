import React, { useState, useEffect } from 'react';
import PacksContext from '@contexts/PacksContext';
import Template from './template';

const PacksTable = ({
  search,
  selectAll,
  selectAllState,
  selectPack,
}) => {
  return (
    <PacksContext.Consumer>
      {({ packs, }) => (
        <Template
          packs={packs}
          search={search}
          selectAll={selectAll}
          selectPack={selectPack}
          selectAllState={selectAllState}
        />
      )}
    </PacksContext.Consumer>
  );
};

export default PacksTable;