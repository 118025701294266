import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Props } from './AppSelect.interfaces';
import './AppSelect.scss';

const AppSelect = (params: Props) => {
  const [value, setValue] = React.useState(params.value || '');

  React.useEffect(() => {
    // console.debug('AppSelect/params.value', params.value); //DELETE
    if (!!params.value) {
      setValue(params.value);
    }
  }, [params.value]);
  React.useEffect(() => {
    if (!!value) {
      if (params.onChange) params.onChange(value);
    }
  }, [value]);

  return (
    <TextField
      select
      label={params.label}
      defaultValue={params.defaultValue}
      helperText={params.helperText}
      className="app-select"
      sx={{
        minWidth: '280px'
      }}
      value={value}
      onChange={e => setValue(e.target.value)}
    >
      {params.items.map((option: any) => (
        <MenuItem className="app-select__item" key={option.uuid} value={option.uuid}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AppSelect;