import React from 'react';
import AppButton from '@presentation/components/AppButton/AppButton';
import ARSearchWithLogo from '@components/composite/ARSearchWithLogo';
import styles from './styles.module.scss';

const Template = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.search__wrapper}>
        <ARSearchWithLogo />
      </div>

      <AppButton
        background="#CF2136"
        color="#FFFFFF"
        type="right-top"
        width="280"
        height="64"
      >
        <span className={styles.arbutton__add}>
          Добавить
        </span>
      </AppButton>
    </div>
  );
};

export default Template;