import React, { useEffect } from 'react';

import { useNavigate, } from 'react-router-dom';

import './assets/sass/styles.scss';

import auth from './store/auth';
// import User from './store/workspace/user';

import User from './store/user';

import Router from '@router';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    console.debug('render App'); // DELETE

    if (auth.isAuth()) {
      User.my().then(
        (response) => {
          console.debug('User my:', response); // DELETE

          if (Number(response.status) !== 200) {
            auth.signout().then(() => {
              navigate('/login');
            });
          }
        }
      );
    }
  }, []);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
