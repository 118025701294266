import { useEffect, useRef, } from "react"
import Dialog from '@store/dialog';

export const useScrollTop = () => {
    const domNode = useRef<HTMLDivElement>(null);

    const scrollHandler = (event: any) => {
        if (domNode.current) {
            Dialog.setOffsetTop(domNode.current.scrollTop)
        }
        return false;
    };

    useEffect(() => {
        document.addEventListener('wheel', scrollHandler, { passive: false });
        return () => document.removeEventListener('wheel', scrollHandler);
    }, [])

    return domNode;
}