import { useEffect, useRef, } from "react"
import Dialog from '@store/dialog';

const preventScroll = (event: any) => {
    if (!Dialog.isOpen) return false;
    event.preventDefault();
    event.stopPropagation();
    return false;
};

export const usePreventScroll = () => {
    const domNode = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // document.addEventListener('wheel', preventScroll, { passive: false });
        // return () => document.removeEventListener('wheel', preventScroll);
    }, []);

    return domNode;
}