import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 17.7443C0 26.0309 5.66855 33.128 13.6358 35C13.1655 29.1799 15.2418 23.5462 19.394 19.394C23.1647 15.6233 28.1693 13.5647 33.4127 13.5647C33.945 13.5647 34.4677 13.5825 35 13.6358C33.128 5.67742 26.0398 0 17.7443 0C7.95833 0 0 7.95833 0 17.7443ZM21.2759 21.2759C17.7181 24.8336 15.9343 29.6415 16.2714 34.6188C17.6022 33.6695 20.1929 31.3192 25.809 25.712C31.3718 20.1402 33.6957 17.5845 34.6361 16.2714C29.6588 15.9165 24.8336 17.7181 21.2759 21.2759Z" fill={colorState} />
    </svg>
  );
};

export default ArtlionLogoVertical;