import React, { useState, useEffect, } from 'react';

const Arrow = ({ className, color, width, height, onClick, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg className={className} onClick={onClick} width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9996 9.13897C18.9929 8.92277 18.8986 8.71795 18.7385 8.56677L10.112 0.243836C9.77503 -0.0812786 9.22913 -0.0812786 8.89216 0.243836L0.265665 8.56677C0.0398941 8.77484 -0.0510883 9.08533 0.028101 9.37793C0.10729 9.66891 0.34317 9.89649 0.64476 9.97289C0.948036 10.0493 1.26984 9.96151 1.48551 9.74368L9.50208 2.00921L17.5187 9.74368C17.7663 9.98914 18.1454 10.0639 18.474 9.93062C18.8008 9.7957 19.0098 9.48197 18.9996 9.13897Z" fill={colorState} />
    </svg>
  );
};

export default Arrow;