import axios, { AxiosInstance, AxiosResponse, } from 'axios';
import Cookies from 'js-cookie'

axios.defaults.headers.common['Content-Type'] = "application/json";

interface IApiParams {
  gateway: AxiosInstance;
  url: string;
  params?: any;
  properties?: any;
};

const giveResponse = async (response: AxiosResponse) => {
  console.debug('api/giveResponse/response', response); //DELETE

  return {
    status: response.status,
    data: response.data,
  };
};

const api = {
  async get(params: IApiParams) {
    console.debug('api/get/params', params); //DELETE

    const response = await params.gateway.get(params.url);

    console.debug('api/get/response', response); //DELETE

    return await giveResponse(response);
  },
  async post(params: IApiParams) {
    console.debug('api/post/params', params); //DELETE

    const response = await params.gateway.post(params.url, params.params, params.properties || {});

    console.debug('api/post/response', response); //DELETE

    return await giveResponse(response);
  },
  async put(params: IApiParams) {
    console.debug('api/put/params', params); //DELETE

    const response = await params.gateway.put(params.url, params.params);

    console.debug('api/put/response', response); //DELETE

    return await giveResponse(response);
  },
  async delete(params: IApiParams) {
    console.debug('api/delete/params', params); //DELETE

    const response = await params.gateway.delete(params.url, params.properties || {});

    console.debug('api/delete/response', response); //DELETE

    return await giveResponse(response);
  },
};

const apiAnalytics = axios.create({
  baseURL: process.env.REACT_APP_ANALYTICS_URL || 'http://localhost:80/analytics/v1/'
});

apiAnalytics.interceptors.request.use(
  function (config) {
    const token = Cookies.get('token');

    console.debug('setToken[apiAnalytics]', token); // DELETE

    if (token) {
      (config.headers ??= {}).Authorization = `Bearer ${token}`;
    }

    config.maxContentLength = 500000000;
    config.maxBodyLength = 5000000000;

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

export {
  api,
  apiAnalytics,
};