import { useState, useEffect, } from 'react'
import PacksContext from '@contexts/PacksContext'

const PacksProvider = ({ children, packs }) => {
  const [packsState, setPacksState] = useState([]);

  useEffect(() => {
    // console.debug('PacksProvider[useEffect]', packs); //DELETE

    setPacksState(packs);
  }, [packs]);

  return (
    <PacksContext.Provider value={{ packs: packsState, }}>
      {children}
    </PacksContext.Provider>
  );
}

export default PacksProvider;