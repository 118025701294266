import { signin } from '@api/rest/auth/signin';
import { Dispatch, SetStateAction } from 'react';
import { signinFormValidation } from './signin.formValidation';
import User from '@store/user';

interface signinActionParams {
  setEmailErrorState: Dispatch<SetStateAction<boolean>>,
  emailValueState: string | null,
  setPasswordErrorState: Dispatch<SetStateAction<boolean>>,
  passwordValueState: string | null,
};

export const signinAction = async ({
  emailValueState,
  setEmailErrorState,
  passwordValueState,
  setPasswordErrorState,
}: signinActionParams): Promise<number | null> => {
  // console.debug('presentation/pages/signin/services/formActions/signinAction/emailValueState', emailValueState); //DELETE
  // console.debug('presentation/pages/signin/services/formActions/signinAction/passwordValueState', passwordValueState); //DELETE

  const isFormValid = signinFormValidation({
    emailValueState,
    setEmailErrorState,
    passwordValueState,
    setPasswordErrorState,
  });

  // console.debug('presentation/pages/signin/services/formActions/signinAction/isFormValid', isFormValid); //DELETE

  if (!isFormValid) {
    return null;
  }

  const response = await signin({
    email: emailValueState,
    password: passwordValueState,
  });

  console.debug('presentation/pages/signin/services/formActions/signinAction/response', response); //DELETE

  if (response.status === 200) {
    User.signin(response.data.token);
  }

  return response.status;
}