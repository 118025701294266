export const columns = [
  { id: 'name', label: 'Название', width: 180, align: 'left' },
  { id: 'email', label: 'Email', width: 280, align: 'left' },
  { id: 'role', label: 'Роль', width: 180, align: 'left' },
  { id: 'packs', label: 'Паки', width: 100, align: 'center' },
  { id: 'percentage', label: '%', width: 100, align: 'center' },
];
export const tableHeadCellStyles = {
  borderBottom: "none",
  background: '#F9F9F9',
  fontFamily: 'Helvetica Neue',
  fontSize: 16,
  fontWeight: 500,
};