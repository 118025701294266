import React, { useState, useEffect, } from 'react';
import './styles.scss';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { ThemeProvider, } from '@mui/material/styles';
import ARSvg from '../../UI/ARSvg';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import Box from '@mui/material/Box';

const Template = ({
  id,
  className,
  theme,
  open,
  anchorEl,
  handleClick,
  handleClose,
  onChange,
}) => {
  const [value, setValue] = useState([null, null]);

  useEffect(async () => {
    onChange({ value });
  }, [value]);

  return (
    <div className={`date-picker-btn ${className}`}>
      <ThemeProvider theme={theme}>
        <Button aria-describedby={id} variant="contained" onClick={handleClick} color="ar_red">
          <ARSvg name="calendar" width="27" height="27" color="#FFFFFF" />
          <div className="date-picker-btn__btn--title">01 МАЯ - 09 МАЯ</div>
        </Button>
      </ThemeProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={{ start: 'Check-in', end: 'Check-out' }}
        >
          <DateRangePicker
            open={true}
            className="date-picker-btn__picker"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(startProps, endProps) => (<></>)}
          />
        </LocalizationProvider>
      </Popover>
    </div>
  );
};

export default Template;