import { IOnInput } from './ARTextField.interfaces';

interface IInputHandlerParams {
  event: ARTextFieldInputEvent;
  onInput?: IOnInput;
}

export const inputHandler = ({ event, onInput }: IInputHandlerParams) => {
  const target = event.target as HTMLInputElement;

  if (onInput) {
    onInput(target.value);
  }
}