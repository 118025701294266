import React from 'react';
import styles from './styles.module.scss';


const Template = ({ className, onInput, }) => {
  return (
    <div className={`${className} ${styles.wrapper}`}>
      <input
        className={`${styles.common}`}
        type="text"
        onInput={onInput}
      />
    </div>
  );
};

export default Template;