import React from "react";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import { IAppInputPictureParams } from './AppInputPicture.interfaces';
import {
  AppInputPictureWidth,
  AppInputPictureHeight,
} from './AppInputPicture.types';
import { witdhSetter, heightSetter, } from './AppInputPicture.setters';
import { getLabel, getLabelPositionClass } from './AppInputPicture.getters';
import './AppInputPicture.scss';

const AppInputPicture = (params: IAppInputPictureParams) => {
  const addInputRef = React.useRef<HTMLInputElement>(null);
  const [widthState, setWidthState] = React.useState<AppInputPictureWidth>(undefined);
  const [heightState, setHeightState] = React.useState<AppInputPictureHeight>(undefined);
  const [imgSrcState, setImgSrcState] = React.useState<string>('');

  React.useEffect(() => {
    witdhSetter(params.width, setWidthState);
    heightSetter(params.height, setHeightState);
  }, []);
  React.useEffect(() => {
    console.debug('AppInputPicture/useEffect', params.imgSrc); //DELETE

    setImgSrcState(params.imgSrc + '');
  }, [params.imgSrc])

  const onAddStickerClicked = () => {
    addInputRef.current!.click();
  };
  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.debug('onFileChanged', event.target.files![0]); // DELETE
    // console.debug('onFileChanged/addInputRef/value', addInputRef.current!.value); // DELETE

    const file = event.target.files![0];
    const _URL = window.URL || window.webkitURL;
    let img = new Image();

    img.src = _URL.createObjectURL(file);

    // console.debug('onFileChanged/img/src', img.src); //DELETE

    img.onload = async () => {
      // console.debug('onFileChanged/img', img); //DELETE

      if (addInputRef.current) {
        addInputRef.current.value = '';
      }

      // console.debug('onFileChanged/reset/addInputRef/value', addInputRef.current?.value); //DELETE

      if (params.addImg) {
        params.addImg(img, file);
      }

      // setImgSrcState(_URL.createObjectURL(file)); //DELETE
    }
  };
  const deleteImg = () => {
    // console.debug('deleteImg/imgSrcState', imgSrcState); //DELETE
    // console.debug('deleteImg/addInputRef/value', addInputRef.current?.value); //DELETE

    if (params.deleteImg) {
      return params.deleteImg(imgSrcState);
    }
  };

  return (
    <div className={`app-input-picture app-input-picture_label-position app-input-picture_label-position_${getLabelPositionClass(params)}`}>
      {imgSrcState
        ? <div className="app-input-picture__img">
          <img
            className={`app-input-picture_${params.type}`}
            src={imgSrcState}
            alt="Sticker"
            style={{
              width: widthState,
              height: heightState,
              userSelect: 'none',
            }}
          />
          <AppSvgIcon className="app-input-picture__delete-img" name="plus" color="#CF2C37" onClick={() => { deleteImg() }} />
        </div>
        : <div
          className={`app-input-picture__container app-input-picture_${params.type}`}
          style={{
            width: widthState,
            height: heightState,
          }}
        >
          <IconButton aria-label="delete" size="small" onClick={() => onAddStickerClicked()}>
            <AppSvgIcon name="plus" color="#CF2C37" />
          </IconButton>

          <input ref={addInputRef} type="file" hidden={true} onChange={(e) => onFileChanged(e)} />
        </div>
      }

      <div className={`app-input-picture__label app-input-picture__label_margin-${getLabelPositionClass(params)}`}>
        {getLabel(params)}
      </div>
    </div>
  );
};

export default AppInputPicture;