import { useState, useEffect } from 'react';
import classNames from "classnames";

export default function useGetMenuClasses(isOpen: boolean) {
  const [state, setState] = useState('');

  useEffect(() => {
    setState(classNames(
      'app-select-search__card',
      'app-select-search__card-menu',
      !isOpen ? 'app-select-search__card_hidden' : ''
    ));
  }, [isOpen]);

  return state;
}