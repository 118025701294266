import React, { useState, useEffect, } from 'react';

const Calendar = ({ color, width, height, }) => {
  const defaultColor = '#000000';
  const defaultWidth = 'auto';
  const defaultHeight = 'auto';

  const [colorState, setColorState] = useState(defaultColor);
  const [widthState, setWidthState] = useState(defaultWidth);
  const [heightState, setheightState] = useState(defaultHeight);

  useEffect(
    () => {
      setColorState(color || defaultColor);
      setWidthState(width || defaultWidth);
      setheightState(height || defaultHeight);
    },
    [
      color,
      width,
      height,
    ]
  );

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: widthState, height: heightState, }}
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 3.14585 3.14585 0 7 0C10.8541 0 14 3.14585 14 7C14 8.748 13.348 10.345 12.2812 11.5742L12.707 12H14L19.5859 17.5859C20.1379 18.1379 20.1379 19.0339 19.5859 19.5859C19.0339 20.1379 18.1379 20.1379 17.5859 19.5859L12 14V12.707L11.5742 12.2812C10.345 13.348 8.748 14 7 14C3.14585 14 0 10.8541 0 7ZM12 7C12 4.22673 9.77327 2 7 2C4.22673 2 2 4.22673 2 7C2 9.77327 4.22673 12 7 12C9.77327 12 12 9.77327 12 7Z"
        fill={colorState}
      />
    </svg>
  );
};

export default Calendar;