import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2.59011C0 1.16167 1.16278 0 2.59259 0H20.7407C22.1706 0 23.3333 1.16167 23.3333 2.59011V14.2456H12.963C12.2474 14.2456 11.6667 14.8258 11.6667 15.5407C11.6667 16.2555 12.2474 16.8357 12.963 16.8357H23.3333V28.4912C23.3333 29.9197 22.1706 31.0813 20.7407 31.0813H2.59259C1.16278 31.0813 0 29.9197 0 28.4912V2.59011ZM23.3333 16.8357H30.5744L27.602 19.8052C27.2634 20.1301 27.1269 20.6124 27.2454 21.0663C27.3638 21.5201 27.7185 21.8745 28.1728 21.9928C28.6271 22.1111 29.1099 21.9749 29.435 21.6365L34.6202 16.4563C35.1263 15.9506 35.1263 15.1308 34.6202 14.625L29.435 9.4448C29.191 9.19417 28.8559 9.05276 28.5059 9.05274C27.9784 9.05288 27.5036 9.37231 27.3051 9.86056C27.1066 10.3488 27.224 10.9085 27.602 11.2761L30.5744 14.2456H23.3333V16.8357Z" fill={colorState} />
    </svg>
  );
};

export default ArtlionLogoVertical;