import React from 'react';
import styles from './styles.module.scss';

const Template = ({ children, className }) => {
  return (
    <div className={`${className} ${styles.arcard}`}>
      {children}
    </div>
  );
};

export default Template;