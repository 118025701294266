import { makeAutoObservable } from "mobx";
import { api } from "@api";
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

class Dictionary {
  countries = [];
  categories = [];
  languages = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchCountries() {
    try {
      const response = await api.get({
        gateway: artlionUkApiGateway,
        url: `dictionary/countries`,
      });

      return this.countries = response.data;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }
  async fetchCategories() {
    try {
      const response = await api.get({
        gateway: artlionUkApiGateway,
        url: `dictionary/categories`,
      });

      return this.categories = response.data;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }
  async fetchLanguages() {
    try {
      const response = await api.get({
        gateway: artlionUkApiGateway,
        url: `dictionary/languages`,
      });

      return this.languages = response.data;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }
}

export default new Dictionary();