import React from "react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import ARTextField from '@presentation/components/ui/ARTextField/ARTextField.index';
import AppInputPicture from '@presentation/components/AppInputPicture/AppInputPicture';
import AppSvgIcon from '@presentation/components/AppSvgIcon/AppSvgIcon';
import { ITableBodyRowProps } from './TableBodyRow.interfaces';
import './TableBodyRow.scss';

const TableBodyRow = (params: ITableBodyRowProps) => {
  const [socialNameErrorState, setSocialNameErrorState] = React.useState(false);
  const [socialNameErrorTextState, setSocialNameErrorTextState] = React.useState('');
  const [socialNameState, setSocialNameState] = React.useState<string | null>(params.name || null);
  const [imgSrcState, setImgSrcState] = React.useState<string | undefined>('');

  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/socialNameState', socialNameState); //DELETE

    if (socialNameState !== null) {
      setSocialNameErrorState(!socialNameState.length);
      params.inputSocialNetworkName(params.uuid + '', socialNameState + '');
    }
  }, [socialNameState]);
  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/socialNameState', socialNameState); //DELETE

    if (socialNameErrorState) {
      setSocialNameErrorTextState('Заполните поле');
    } else {
      setSocialNameErrorTextState('');
    }

  }, [socialNameErrorState]);
  React.useEffect(() => {
    console.debug('TableBodyRow/useEffect/params/iconUrl', params.iconUrl); //DELETE

    if (params.isNew) {
      console.debug('TableBodyRow/useEffect/imgSrcState', imgSrcState); //DELETE

      setImgSrcState(params.iconUrl);
    }
  }, [params.iconUrl]);

  if (params.isNew) {
    return (
      <TableRow>
        <TableCell sx={{ borderBottom: "none", width: '25%', }}>
          <ARTextField
            className="socials-settings-table-body-row__input"
            theme='standard'
            label="Email"
            variant="filled"
            fullWidth={true}
            error={socialNameErrorState}
            helperText={socialNameErrorTextState}
            onInput={setSocialNameState}
            value={socialNameState}
          />
        </TableCell>

        <TableCell sx={{ borderBottom: "none", width: '25%', }}>
          <AppInputPicture
            type="circle"
            width={50}
            height={50}
            labelRight="100x100 .PNG"
            imgSrc={imgSrcState}
            addImg={(img, file) => params.addIcon(params.uuid + '', img, file)}
            deleteImg={(imgSrc) => params.deleteIcon(params.uuid + '', imgSrc)}
          />
        </TableCell>

        <TableCell sx={{ borderBottom: "none", width: '50%', }} align="right">
          <AppSvgIcon
            className="socials-settings-table-body-row__actions--delete"
            name="delete"
            color="#CF2136"
            onClick={() => { params.delete(params.uuid + '') }}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell className="socials-settings-table-body-row__cell" sx={{ borderBottom: "none", width: '25%', }}>
        <div className="socials-settings-table-body-row__cell-container">
          {params.name}
        </div>
      </TableCell>
      <TableCell className="socials-settings-table-body-row__cell" sx={{ borderBottom: "none", width: '25%', }}>
        <Avatar alt={params.name} src={params.iconUrl} sx={{ width: 50, height: 50, }} />
      </TableCell>
    </TableRow>
  );
};

export default TableBodyRow;