import React, { ReactNode } from 'react';
import styles from './ARCard.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
}

const ARCard = ({ children, className }: Props) => {
  return (
    <div className={`${className} ${styles.arcard}`}>
      {children}
    </div>
  );
};

export default ARCard;