import React from "react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import './TableHeadRow.scss';

const TableHeadRow = () => {
  return (
    <TableRow>
      <TableCell className="socials-settings-table-head-row__cell" sx={{ borderBottom: "none", width: '50%', }} key={1}>{'Код локализации'}</TableCell>
      <TableCell className="socials-settings-table-head-row__cell" sx={{ borderBottom: "none", width: '50%', }} key={2}></TableCell>
    </TableRow>
  );
};

export default TableHeadRow;