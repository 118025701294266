import React from 'react';
import TextField from '@mui/material/TextField';
import { Props } from './ARTextField.interfaces';
import { inputHandler } from './ARTextField.handlers';
import './ARTextField.scss';

const ARTextField = ({
  className,
  theme,
  label,
  variant,
  fullWidth,
  error,
  helperText,
  value,
  onInput,
  type,
}: Props) => {
  React.useEffect(() => {
  }, []);

  return (
    <TextField
      className={`artextfield artextfield-variant__${variant} artextfield-theme__${theme} ${className}`}
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      onChange={(event: ARTextFieldInputEvent) => { inputHandler({ event, onInput }) }}
      value={value || ''}
      type={type}
    />
  );
};

export default ARTextField;