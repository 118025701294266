import React from 'react';
import './AppTable.scss';
import { IAppTableParams } from './AppTable.interfaces';
import { renderAppTable } from './AppTable.renderers';
import { getAppTableInlineStyles } from './AppTable.getters';

const AppTable = (params: IAppTableParams) => {
  const getAppTableInlineStylesParams = {
    width: params.width,
    minWidth: params.minWidth,
    maxWidth: params.maxWidth,
  };

  return (
    <div className={`artable ${params.className}`} style={getAppTableInlineStyles(getAppTableInlineStylesParams)}>
      {renderAppTable(params)}
    </div>
  );
};

export default AppTable;