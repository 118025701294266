import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ARCard from '@presentation/components/ui/ARCard/ARCard.index';
import ARSvg from '@presentation/components/ui-embedded/ARSvg';
import ARTextField from '@presentation/components/ui/ARTextField/ARTextField.index';
import LoginBackdrop from '@assets/img/pages/signin/page-signin-login-form-background.png';
import styles from './signin.styles.module.scss';
import { signinAction } from './signin.formActions';
import { requireEmailTitle, requirePasswordTitle, } from './signin.formData';
import AppButton from '@presentation/components/AppButton/AppButton';

const Signin = observer(() => {
  const navigate = useNavigate();
  // const location = useLocation();

  const [emailValueState, setEmailValueState] = useState<Email | null>(null);
  const [emailErrorState, setEmailErrorState] = useState(false);
  const [emailErrorTextState, setEmailErrorTextState] = useState('');
  const [passwordValueState, setPasswordValueState] = useState<Password | null>(null);
  const [passwordErrorState, setPasswordErrorState] = useState(false);
  const [passwordErrorTextState, setPasswordErrorTextState] = useState('');
  const [signinLoaderState, setSigninLoaderState] = useState(false);

  useEffect(() => { //DELETE
    console.debug('presentation/pages/signin/useEffect'); //DELETE
  }, []);
  useEffect(() => {
    if (emailValueState !== null) {
      setEmailErrorState(!emailValueState.length);
    }
  }, [emailValueState]);
  useEffect(() => {
    if (passwordValueState !== null) {
      setPasswordErrorState(!passwordValueState.length);
    }
  }, [passwordValueState]);
  useEffect(() => {
    setEmailErrorTextState(emailErrorState ? requireEmailTitle : '');
    setPasswordErrorTextState(passwordErrorState ? requirePasswordTitle : '');
  }, [emailErrorState, passwordErrorState]);

  const signinHandler = async () => {
    setSigninLoaderState(true);

    const status = await signinAction({
      setEmailErrorState,
      emailValueState,
      setPasswordErrorState,
      passwordValueState
    })

    setSigninLoaderState(false);

    if (status !== null && status !== 200) {
      alert('Ошибка авторизации');
    }

    if (status !== null && status === 200) {
      navigate('/');
    }
  };

  return (
    <ARCard className={styles.signin}>
      <div className={styles.signin__container}>
        <div className={styles.signin__login}>
          <img className={styles.signin__login_backdrop} src={LoginBackdrop} alt="LoginBackdrop" />
          <div className={styles.signin__login_content}>
            <div className={styles.signin__login_logo}>
              <ARSvg name="ArtlionLogoSidebar" color="#B0B0B0" />
            </div>
            <div className={styles.signin__login_form}>
              <div className={styles.signin__login_form__fields}>
                <ARTextField
                  className={styles.signin__login_form__field_login}
                  theme='default'
                  label="Email"
                  variant="outlined"
                  fullWidth={true}
                  error={emailErrorState}
                  helperText={emailErrorTextState}
                  onInput={setEmailValueState}
                  value={emailValueState}
                />

                <ARTextField
                  className={styles.signin__login_form__field_password}
                  theme='default'
                  label="Пароль"
                  variant="outlined"
                  fullWidth={true}
                  error={passwordErrorState}
                  helperText={passwordErrorTextState}
                  onInput={setPasswordValueState}
                  value={passwordValueState}
                  type="password"
                />
              </div>

              <div className={styles.signin__login_form__actions}>
                <div className={styles.signin__login_form__action_forgot}>
                  Забыли пароль?
                </div>

                <AppButton
                  className={styles.signin__login_form__action_btn}
                  variant="contained"
                  onClick={signinHandler}
                  loading={signinLoaderState}
                  loaderColor="white"
                >
                  <span className={styles.signin__login_form__action_signin__title}>Вход</span>
                  <ARSvg name="Login" color="#FFFFFF" />
                </AppButton>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.signin__info}>
          <div className={styles.signin__info_title}>
            <div className={styles.signin__info_title__chunk}>Вход</div>
            <div className={styles.signin__info_title__chunk}>в кабинет</div>
            <div className={styles.signin__info_title__chunk}>администратора</div>
          </div>

          <ul className={styles.signin__info_list}>
            <li className={styles.signin__info_list__item}>
              Доступно только для художников, селебрити и брендов.
            </li>

            <li className={styles.signin__info_list__item}>
              Чтобы получить логин и пароль для входа, перейдите на сайт и сделаете соответствующий запрос или свяжитесь с нами: <span className={styles.signin__info__feedback_email}>yo@artlion.app</span>
            </li>
          </ul>
        </div>
      </div>
    </ARCard>
  );
});

export default Signin;