import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 17.5C0 7.83562 7.83562 0 17.5 0C27.1644 0 35 7.83562 35 17.5C35 22.6946 32.7244 27.347 29.1296 30.5509V30.5253V30.1265L28.9246 29.7847C27.4298 27.2633 21.7686 24.7917 17.4972 24.7917C13.2286 24.7917 7.56884 27.2647 6.07259 29.7847L5.87036 30.1237V30.5509C2.27703 27.3455 0 22.6946 0 17.5ZM23.3333 14.5833C23.3333 11.3663 20.7171 8.75 17.5 8.75C14.2829 8.75 11.6667 11.3663 11.6667 14.5833V16.0417C11.6667 19.2587 14.2829 21.875 17.5 21.875C20.7171 21.875 23.3333 19.2587 23.3333 16.0417V14.5833ZM17.5 11.6667C15.8915 11.6667 14.5833 12.9748 14.5833 14.5833V16.0417C14.5833 17.6502 15.8915 18.9583 17.5 18.9583C19.1085 18.9583 20.4167 17.6502 20.4167 16.0417V14.5833C20.4167 12.9748 19.1085 11.6667 17.5 11.6667ZM8.78418 30.9981C9.96397 29.6477 14.1124 27.7083 17.4972 27.7083C20.8834 27.7083 25.0332 29.6477 26.213 30.9981C26.2138 31.3217 26.215 31.6338 26.2161 31.9356C26.2171 32.1832 26.218 32.424 26.2187 32.6587C23.6491 34.1389 20.6777 35 17.5 35C14.3208 35 11.3495 34.1404 8.78133 32.6587L8.78418 30.9981Z" fill={colorState} />
    </svg>
  );
};

export default ArtlionLogoVertical;