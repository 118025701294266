import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import AccountsStore from '@store/accounts';
import AccountsPageProvider from '@providers/AccountsPageProvider';
import Template from './template';

const Accounts = observer(() => {
  const [accountsState, setAccountsState] = useState([]);

  useEffect(async () => {
    setAccountsState(await AccountsStore.fetchAccounts());

    console.debug('AccountsPage::accountsState', accountsState); //DELETE
  }, []);

  return (
    <AccountsPageProvider accounts={accountsState}>
      <Template />
    </AccountsPageProvider>
  );
});

export default Accounts;