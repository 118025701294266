import { debounce } from "lodash";
import { ISearchLanguageCodeParams } from './searchLanguageCodeService.interfaces';
import { fetchLanguageCodes, } from '@api/rest/dictionaries/languageCodes/languageCodes';

export const searchLanguageCode = debounce(async (params: ISearchLanguageCodeParams) => {
  const fetchLanguageCodesResponse = await fetchLanguageCodes();
  params.store.setLanguageCodes(
    fetchLanguageCodesResponse.filter(
      (item: any) => (
        item.code.includes(params.value)
      )
    )
  );
}, 400);