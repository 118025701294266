import React, { useState, useEffect } from 'react';
import AvatarGroup from '@mui/material/AvatarGroup';
import { renderAvatars } from './services/renderers';
import './styles.scss';

const ARAvatarGroup = ({ avatars, }) => {
  const [avatarsState, setAccountsState] = useState([]);

  useEffect(async () => {
    setAccountsState(avatars || []);

    console.debug('ARAvatarGroup', avatars); //DELETE
  }, [avatars]);

  return (
    <AvatarGroup
      className="aravatargroup__container"
      max={3}
      spacing="small"
      total={avatarsState.length}
    >
      {renderAvatars({ avatars: avatarsState, })}
    </AvatarGroup>
  );
};

export default ARAvatarGroup;