import React from 'react';
import {
  Props,
  LogoProps,
  SvgProps,
} from './AppSvgIcon.interfaces';
import Calendar from './svg/Сalendar';
import Search from './svg/Search';
import ArtlionLogoVertical from './svg/ArtlionLogoVertical';
import ArtlionLogoSidebar from './svg/ArtlionLogoSidebar';
import Sticker from './svg/Sticker';
import Chart from './svg/Chart';
import Money from './svg/Money';
import Account from './svg/Account';
import Directory from './svg/Directory';
import Settings from './svg/Settings';
import Logout from './svg/Logout';
import Login from './svg/Login';
import Plus from './svg/Plus';
import Delete from './svg/Delete';
import Save from './svg/Save';
import Arrow from './svg/Arrow';

const AppSvgIcon = (params: SvgProps) => {
  return getSvgByName(params);
};

const getSvgByName = ({ className, name, color, width, height, onClick, }: SvgProps) => {
  switch (name.toLowerCase()) {
    case 'calendar':
      return <Calendar color={color} width={width} height={height} />;
    case 'search':
      return <Search className={className} color={color} width={width} height={height} />;
    case 'artlionlogovertical':
      return <ArtlionLogoVertical color={color} width={width} height={height} />;
    case 'artlionlogosidebar':
      return <ArtlionLogoSidebar color={color} width={width} height={height} />;
    case 'sticker':
      return <Sticker color={color} width={width} height={height} />;
    case 'chart':
      return <Chart color={color} width={width} height={height} />;
    case 'money':
      return <Money color={color} width={width} height={height} />;
    case 'account':
      return <Account color={color} width={width} height={height} />;
    case 'directory':
      return <Directory color={color} width={width} height={height} />;
    case 'settings':
      return <Settings color={color} width={width} height={height} />;
    case 'logout':
      return <Logout color={color} width={width} height={height} />;
    case 'login':
      return <Login color={color} width={width} height={height} />;
    case 'plus':
      return <Plus className={className} color={color} width={width} height={height} onClick={onClick} />;
    case 'delete':
      return <Delete className={className} color={color} width={width} height={height} onClick={onClick} />;
    case 'save':
      return <Save className={className} color={color} width={width} height={height} onClick={onClick} />;
    case 'arrow':
      return <Arrow className={className} color={color} width={width} height={height} onClick={onClick} />;
    default:
      return <></>;
  }
};

export default AppSvgIcon;