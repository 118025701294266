import React from 'react';
import Button from "@mui/material/Button";
import ARSvg from '@presentation/components/ui-embedded/ARSvg';
import styles from './TheLocalization.styles.module.scss';

const TheLocalization = () => {
  return (
    <div className={styles.the_localization}>
      <Button
        sx={{
          borderRadius: '100px',
          width: '40px',
          height: '40px',
          minWidth: '40px',
          background: '#D7D7D7',
          color: '#5C5B5B',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '14px',
          '&:hover': { background: '#B0B0B0' }
        }}
        variant="contained"
      >
        RU
      </Button>

      <ARSvg name="ArtlionLogoVertical" color="#B0B0B0" />
    </div>
  );
};

export default TheLocalization;