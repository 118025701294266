import { nFormatter, } from '@utils/math';

export const analyticsMock = ({ count, startDate, }) => {
  return {
    salesToFavoritesRatio: [
      { date: '01.01.2021', favorites: 9, sales: 9 },
      { date: '10.01.2021', favorites: 2, sales: 2 },
    ],
    platformsRatio: {
      ANDROID: 4,
      IOS: 7,
      total: 11
    },
    countriesRatio: {
      percentage: {
        countries: [
          { "name": "DE", "count": 4 },
          { "name": "RU", "count": 3 },
          { "name": "EN", "count": 2 },
        ],
        other: 3,
        total: 12,
      },
      prices: {
        countries: [
          { "name": "EN", "price": 723 },
          { "name": "DE", "price": 511 },
          { "name": "RU", "price": 300 },
        ],
        other: 450,
        total: 1984,
      },
    }
  };
};
export const analyticsPacksMock = ({ count, }) => {
  console.debug('analyticsPacksMock') //DELETE

  return [
    {
      select: true,
      avatar: 'https://artlion.uk/storage/271/c0e37b63-4b16-4d44-8716-0da6296fda9e.08_tray.png',
      name: 'DUDEmaan',
      download: nFormatter(123030, 1),
      price: 250,
    },
    {
      select: false,
      avatar: 'https://artlion.uk/storage/39/d6856db2-88dc-4cdf-987f-ec865ae9e03d.Ina_tray.png',
      name: 'Mrs Fit Ina',
      download: nFormatter(7500, 1),
      price: 125,
    },
    {
      select: true,
      avatar: 'https://artlion.uk/storage/358/68bc89d7-5253-4821-b61e-1908829d24d3.Migel_tray.png',
      name: 'Migel',
      download: nFormatter(6700, 1),
      price: 200,
    },
    {
      select: true,
      avatar: 'https://artlion.uk/storage/125/0fff14b7-8ff9-4c3c-b505-8d0ffbd75647.04_tray.png',
      name: 'QUPPY',
      download: nFormatter(12500, 1),
      price: 127,
    },
    {
      select: false,
      avatar: 'https://artlion.uk/storage/151/9ecedbf2-7058-4b9e-a01e-4bc1d675da1c.05_tray.png',
      name: 'Hi! Pizza',
      download: nFormatter(325, 1),
      price: 320,
    },
    {
      select: false,
      avatar: 'https://artlion.uk/storage/241/386b54f5-fec7-4acb-bbd9-2e10897d2cca.06_tray.png',
      name: 'Wool&Mania',
      download: nFormatter(1500, 1),
      price: 332,
    },
    {
      select: false,
      avatar: 'https://artlion.uk/storage/407/d14d8aa1-e672-4af4-b250-f1f12e001eee.07_tray.png',
      name: 'YOLO! Stickers',
      download: nFormatter(127, 1),
      price: 129,
    },
    {
      select: false,
      avatar: 'https://artlion.uk/storage/381/e11bff06-4513-4333-ab78-6799533b65e8.Hamster_tray.png',
      name: 'Hamster-Invest',
      download: nFormatter(10500, 1),
      price: 327,
    },
    {
      select: false,
      avatar: 'https://artlion.uk/storage/65/0fca6cd0-ea33-40a1-b1c9-e078115990f1.10_tray.png',
      name: 'TOM the Donut',
      download: nFormatter(32700, 1),
      price: 437,
    },
  ];
};