import * as React from 'react';
import classNames from "classnames";
import AppDialog from '@presentation/components/AppDialog/AppDialog';
import { Position } from '@presentation/components/AppDialog/AppDialog.types';
import AppButtonSave from '@presentation/components/AppButtonSave/AppButtonSave';
import AppButtonCancel from '@presentation/components/AppButtonCancel/AppButtonCancel';
import AppTable from '@presentation/components/AppTable/AppTable';
import { LanguageFormProps } from './LanguageForm.interfaces';
import {
  save,
  cancel,
  add,
  deleteItem,
  setDefaultStatus,
  setLanguageLocalName,
  setLanguageLocalCode,
} from './LanguageForm.services';
import TableHeader from './components/TableHeader/TableHeader';
import TableHeadRow from './components/TableHeadRow/TableHeadRow';
import TableBodyRow from './components/TableBodyRow/TableBodyRow';
import './LanguageForm.scss';

import StateStore from './store/StateStore';
import LanguageFormProvider from './LanguageForm.providers';
import { observer } from 'mobx-react-lite';
import { fetchLanguageCodes, } from '@api/rest/dictionaries/languageCodes/languageCodes';
import { fetchLanguage, } from '@api/rest/dictionaries/languages/languages';
import { LanguageCode } from '@domain/LanguageCode';

const LanguageForm = observer((params: LanguageFormProps) => {
  const [stateStore, setStateStore] = React.useState<StateStore>(new StateStore());

  React.useEffect(() => { }, [stateStore.languageCodes, stateStore.languageCode,]);
  React.useEffect(() => {
    if (!params.isOpen) {
      setStateStore(new StateStore());
    } else {
      fetchLanguageCodes()
        .then(response => stateStore.setLanguageCodes(response))
        .catch(() => params.onClose());
    }
  }, [params.isOpen]);

  React.useEffect(() => {
    console.debug('LanguageForm/useEffect/IS_EDIT', params.languageUuid, params.isOpen, params); //DELETE

    if (params.isOpen && params.languageUuid) {
      console.debug('LanguageForm/useEffect/EDIT'); //DELETE
      stateStore.setIsNew(false);
      fetchLanguage({ uuid: params.languageUuid }).then(response => {
        console.debug('LanguageForm/useEffect/EDIT/fetchLanguage/response', response); //DELETE
        stateStore.setLanguageCode(response.languageCode);
        stateStore.setLanguageCodesLocals(response.languageCodesLocals);
      })
    } else {
      console.debug('LanguageForm/useEffect/NOT_EDIT'); //DELETE
      stateStore.setIsNew(true);
    }
  }, [params.isOpen]);

  const inputLanguageLocalNameHandler = (uuid: Uuid, value: string) => {
    setLanguageLocalName({
      store: stateStore,
      uuid,
      value,
    });
  };
  const setDefaultStatusHandler = (uuid: Uuid, status: boolean) => {
    setDefaultStatus({
      store: stateStore,
      uuid,
      status,
    });
  };
  const getLanguageCodes = () => (stateStore.languageCodes?.map((languageCode: any) => ({
    ...languageCode,
    name: languageCode.code,
  })) || []);
  const setLanguageLocalCodeHandler = (uuid: Uuid, languageCode: LanguageCode) => {
    setLanguageLocalCode({
      store: stateStore,
      uuid,
      languageCode,
    });
  };

  return (
    <LanguageFormProvider stateStore={stateStore}>
      <AppDialog
        className={classNames('language-form', params.className)}
        classNameCard="language-form__card"
        position={Position.Bottom}
        isOpen={!!params.isOpen}
      >
        <div className="language-form__container">
          <div className="language-form__header">
            <AppButtonCancel
              className="language-form__cancel"
              disabled={!stateStore.isLoaded || stateStore.isSaving}
              onClick={() => cancel({ store: stateStore, callback: params.onClose, })}
            />

            <AppButtonSave
              className="language-form__save"
              disabled={!stateStore.isLoaded || stateStore.isSaving}
              onClick={() => save({ store: stateStore })}
            />
          </div>

          <div className="language-form__main">
            <AppTable
              className="language-form__table"
              variant="contained"
              header={<TableHeader add={() => add({ store: stateStore })} />}
              tableHeadRow={<TableHeadRow />}
              tableBodyRow={<TableBodyRow
                delete={(uuid: Uuid) => deleteItem({ store: stateStore, uuid, })}
                inputLanguageLocalName={inputLanguageLocalNameHandler}
                setLanguageLocalCode={setLanguageLocalCodeHandler}
                setDefaultStatus={setDefaultStatusHandler}
                languageCodes={getLanguageCodes()}
              />}
              items={stateStore.languageCodesLocals}
              loading={!stateStore.isLoaded}
              emptyActionBtnLabel="Добавить локализацию"
              onEmptyActionBtnClick={() => add({ store: stateStore })}
              maxHeight={'400px'}
            />
          </div>
        </div>
      </AppDialog>
    </LanguageFormProvider>
  );
});

export default LanguageForm;