import React, { useState, } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import AddImgStub from '@components/UI/AddImgStub';
import StickerPackItem from '@store/workspace/stickerPack/item';

const Preview = ({ classNameImg, close, img, slug, width, height, }) => {

  const staticData = {}
  const getters = {
    getStubClass() {
      return `
        add-button
        ${classNameImg}
        ${img ? 'arl-preview_hidden' : ''}
      `;
    },
    getImgClass() {
      return `
        ${img ? '' : 'arl-preview_hidden'}
      `;
    },
  }
  const handlers = {}
  const callbacks = {
    addPreview: async ({file, img,}) => {
      console.debug('addPreview[callback]', file, img); // DELETE

      await StickerPackItem.setPreviewsToAdd({
        file,
        preview: img,
        slug,
        width,
        height,
      });
    },
  }

  return (
    <div className="arl-preview">
      <AddImgStub 
        className={getters.getStubClass()}
        change={callbacks.addPreview}
      />

      <div className={getters.getImgClass()}>
        <div 
          className="arl-preview_close"
          onClick={() => close({slug: img?.slug})}
        >
          <SvgIcon
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M0 17.5C0 7.83562 7.83562 0 17.5 0C27.1644 0 35 7.83562 35 17.5C35 27.1644 27.1644 35 17.5 35C7.83562 35 0 27.1644 0 17.5ZM18.9583 18.9583H26.25C27.055 18.9583 27.7083 18.3065 27.7083 17.5C27.7083 16.6935 27.055 16.0417 26.25 16.0417H18.9583V8.75C18.9583 7.94354 18.305 7.29167 17.5 7.29167C16.695 7.29167 16.0417 7.94354 16.0417 8.75V16.0417H8.75C7.945 16.0417 7.29167 16.6935 7.29167 17.5C7.29167 18.3065 7.945 18.9583 8.75 18.9583H16.0417V26.25C16.0417 27.0565 16.695 27.7083 17.5 27.7083C18.305 27.7083 18.9583 27.0565 18.9583 26.25V18.9583Z" fill="#CF2136"/>
          </SvgIcon>
        </div>
        <img
          className={`arl-preview__img ${classNameImg}`}
          draggable={false}
          src={img?.original_url}
          alt=""
        />
      </div>
    </div>
  );
};

export default Preview;