import React from 'react';
import { Outlet } from 'react-router-dom';
import TheLocalization from '@presentation/components/TheLocalization/TheLocalization.index';
import TheCopyright from '@presentation/components/TheCopyright/TheCopyright.index';

import styles from './AuthLayout.module.scss';

const AuthLayout = () => {
  return (
    <div className={styles.auth_layout}>
      <div className={styles.auth_layout__middle_bar}>
        <div className={styles.auth_layout__main}>
          <div className={styles.auth_layout__back_to_site}>
            Вернуться на сайт
          </div>

          <div className={styles.auth_layout__outlet}>
            <Outlet />
          </div>
        </div>

        <TheCopyright />
      </div>

      <div className={styles.auth_layout__right_bar}>
        <TheLocalization />
      </div>
    </div>
  );
};

export default AuthLayout;