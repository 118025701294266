import { percentData, chartData, } from './data';

export const setPercentData = (data) => {
  // console.debug('DevicesChart[setter][setPercentData]', data); //DELETE

  if (!data) return percentData;

  return {
    ...percentData,
    ios: {
      percent: data.ios.percent,
    },
    android: {
      percent: data.android.percent,
    },
  };
};
export const setChartData = (data) => {
  // console.debug('DevicesChart[setter][setChartData]', data); //DELETE

  if (!data) return chartData;

  return {
    ...chartData,
    datasets: [{
      ...chartData.datasets[0],
      data: [data.ios.count, data.android.count],
    }]
  };
};