import React from "react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import './TableHeadRow.scss';

const TableHeadRow = () => {
  return (
    <TableRow>
      <TableCell className="socials-settings-table-head-row__cell" sx={{ borderBottom: "none", width: '30%', }} key={1}>{'Название'}</TableCell>
      <TableCell className="socials-settings-table-head-row__cell" sx={{ borderBottom: "none", width: '20%', paddingLeft: '10px !important', }} key={2}>{'Иконка'}</TableCell>
      <TableCell className="socials-settings-table-head-row__cell" sx={{ borderBottom: "none", width: '50%', }} key={3}></TableCell>
    </TableRow>
  );
};

export default TableHeadRow;