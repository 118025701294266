import { languagesGet, languagesPost, languageGet, } from './languages.endpoints';
import { languagesGetAdapter, languagesCreateAdapter, languageGetAdapter, } from './languages.adapters';
import { fetchLanguagesParams, createLanguageParams, fetchLanguageParams, } from './fetchLanguages.interfaces';

export const fetchLanguages = async (params: fetchLanguagesParams) => {
  const languagesGetResponse = await languagesGet();
  const languagesGetAdapterResponse = await languagesGetAdapter(languagesGetResponse, params.pure);
  return languagesGetAdapterResponse;
};

export const createLanguage = async (params: createLanguageParams) => {
  console.debug('createLanguage/params', params); //DELETE
  const languagesCreateAdapterResponse = await languagesCreateAdapter(params);
  console.debug('createLanguage/languagesCreateAdapterResponse', languagesCreateAdapterResponse); //DELETE
  const languagesGetResponse = await languagesPost([languagesCreateAdapterResponse]);
  console.debug('createLanguage/languagesGetResponse', languagesGetResponse); //DELETE
  return languagesGetResponse;
};

export const fetchLanguage = async (params: fetchLanguageParams) => {
  console.debug('fetchLanguage/params', params); //DELETE
  const languageGetResponse = await languageGet(params.uuid);
  console.debug('fetchLanguage/languageGetResponse', languageGetResponse); //DELETE
  const languageGetAdapterResponse = await languageGetAdapter(languageGetResponse.data);
  return languageGetAdapterResponse;
};