import { IOnInput } from './AppTextField.interfaces';
import { AppTextFieldInputEvent } from './AppTextField.types';

interface IInputHandlerParams {
  event: AppTextFieldInputEvent;
  onInput?: IOnInput;
}

export const inputHandler = ({ event, onInput }: IInputHandlerParams) => {
  const target = event.target as HTMLInputElement;

  if (onInput) {
    onInput(target.value);
  }
}