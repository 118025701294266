import { makeAutoObservable } from "mobx";
import Cookies from 'js-cookie';
import { api } from "@api";
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';
// import { User } from '@domain/User'

interface Error {
  response?: any;
};

let error: Error = {};

class User {
  user = null;

  // authData = {};

  constructor() {
    makeAutoObservable(this);
  }

  async my() {
    try {
      const response = await api.get({
        gateway: artlionUkApiGateway,
        url: 'user/my',
      });

      this.user = response.data;

      console.debug('this.user', this.user); //DELETE

      return response;
    } catch (e) {
      return Object.assign(error, e).response;
    }
  }

  async signin(token: AuthToken) {
    console.debug("store/user/signin/token", token); // DELETE

    Cookies.set('token', token);
  }

  async signout() {
    console.debug("store/User/signout"); // DELETE

    Cookies.set('token', '');
  }

  // async setAuthData(payload = {}) {
  //   console.debug( "setAuthData", payload ); // DELETE

  //   this.authData = {
  //     ...this.authData,
  //     ...payload,
  //   };
  // }

  // isAuth() {
  //   console.log('isAuth: ', Cookies.get('token')); // DELETE

  //   return Boolean(Cookies.get('token'));
  // }
}

export default new User();