import React from "react";
import { socialNetworksDictionaryIndex, socialNetworksDictionaryCreate, } from '@api/rest/dictionaries/socialNetworksDictionary/socialNetworksDictionary';
import ARTable from '@presentation/components/ui/ARTable/ARTable';
import { SocialNetwork } from '@domain/SocialNetwork';
import TableHeader from './components/TableHeader/TableHeader';
import TableHeadRow from './components/TableHeadRow/TableHeadRow';
import TableBodyRow from './components/TableBodyRow/TableBodyRow';

import { ISocialsSettingsParams } from './SocialsSettings.interfaces';

import { useStateBoolean } from '@hooks/useStateBoolean';

const SocialsSettings = (params: ISocialsSettingsParams) => {
  const [readSocialNetworksState, setReadSocialNetworksState] = React.useState<Array<SocialNetwork>>([]);
  const [addSocialNetworksState, setAddSocialNetworksState] = React.useState<Array<SocialNetwork>>([]);
  const [loadingSocialNetworksState, setLoadingSocialNetworksState] = React.useState<boolean>(true);
  const [saveLoadingState, setSaveLoadingState] = React.useState<boolean>(false);
  const saveDisabled = useStateBoolean(!addSocialNetworksState.length);
  const socialNetworks = React.useMemo(() => {
    console.debug('socialNetworks/useMemo/readSocialNetworksState', readSocialNetworksState); //DELETE
    console.debug('socialNetworks/useMemo/addSocialNetworksState', addSocialNetworksState); //DELETE

    return [
      ...readSocialNetworksState,
      ...addSocialNetworksState,
    ];
  }, [readSocialNetworksState, addSocialNetworksState,]);

  React.useEffect(() => {
    console.debug('pages/settingsData/SocialsSettings/useEffect/construct'); //DELETE

    (async () => {
      await fetchSocialNetworks();
      setLoadingSocialNetworksState(false);
    })();
  }, []);

  const deleteIcon = (uuid: Uuid, imgSrc: string) => {
    console.debug("SocialsSettings/deleteIcon/uuid", uuid); //DELETE
    console.debug("SocialsSettings/deleteIcon/imgSrc", imgSrc); //DELETE

    if (saveLoadingState) {
      return;
    }

    setAddSocialNetworksState(addSocialNetworksState.map((addSocialNetwork) => {
      if (addSocialNetwork.uuid === uuid) {
        return {
          ...addSocialNetwork,
          iconUrl: '',
        };
      }

      return addSocialNetwork;
    }));
  };
  const addIcon = (uuid: Uuid, img: HTMLImageElement, file: File) => {
    console.debug('SocialsSettings/addIcon/uuid', uuid); //DELETE
    console.debug('SocialsSettings/addIcon/img', img); //DELETE
    console.debug('SocialsSettings/addIcon/img/src', img.src); //DELETE
    console.debug('SocialsSettings/addIcon/file', file); //DELETE

    if (saveLoadingState) {
      return;
    }

    setAddSocialNetworksState(addSocialNetworksState.map((addSocialNetwork) => {
      if (addSocialNetwork.uuid === uuid) {
        return {
          ...addSocialNetwork,
          iconUrl: img.src,
          file,
        };
      }

      return addSocialNetwork;
    }));
  }
  const deleteSocialNetwork = (uuid: Uuid) => {
    console.debug('SocialsSettings/deleteSocialNetwork/uuid', uuid); //DELETE

    if (saveLoadingState) {
      return;
    }

    setAddSocialNetworksState(addSocialNetworksState.filter((addSocialNetwork) => addSocialNetwork.uuid !== uuid));
  };
  const inputSocialNetworkName = (uuid: Uuid, name: string) => {
    console.debug('SocialsSettings/inputSocialNetworkName/uuid', uuid); //DELETE
    console.debug('SocialsSettings/inputSocialNetworkName/name', name); //DELETE

    if (saveLoadingState) {
      return;
    }

    setAddSocialNetworksState(addSocialNetworksState.map((addSocialNetwork) => {
      if (addSocialNetwork.uuid === uuid) {
        return {
          ...addSocialNetwork,
          name,
        };
      }

      return addSocialNetwork;
    }));
  };
  const save = async () => {
    console.debug('SocialsSettings/save/socialNetworks', socialNetworks); //DELETE
    console.debug('SocialsSettings/save/readSocialNetworksState', readSocialNetworksState); //DELETE
    console.debug('SocialsSettings/save/addSocialNetworksState', addSocialNetworksState); //DELETE

    if (!await validationOnSave()) {
      alert('Данные введены некорректно');

      return;
    }

    console.debug('SocialsSettings/save/start'); //DELETE

    setSaveLoadingState(true);
    await uploadAddSocialNetworks(addSocialNetworksState);
    await fetchSocialNetworks();
    setSaveLoadingState(false);

    console.debug('SocialsSettings/save/end'); //DELETE
  };
  const addNewSocial = () => {
    console.debug('pages/settingsData/SocialsSettings/addNewSocial'); //DELETE

    setAddSocialNetworksState([...addSocialNetworksState, {
      uuid: new Date().getTime().toString(),
      name: '',
      iconUrl: '',
      isNew: true,
    }]);
  };
  const validationOnSave = async () => {
    console.debug('pages/settingsData/SocialsSettings/validationOnSave'); //DELETE

    let isValid = true;

    addSocialNetworksState.forEach(addSocialNetwork => isValid = !!addSocialNetwork.name && !!addSocialNetwork.file);

    return isValid;
  };
  const uploadAddSocialNetworks = async (socialNetworks: Array<SocialNetwork>) => {
    console.debug('pages/settingsData/SocialsSettings/uploadAddSocialNetworks', socialNetworks); //DELETE

    for (let i = 0; i < socialNetworks.length; i++) {
      console.debug('pages/settingsData/SocialsSettings/uploadAddSocialNetwork', socialNetworks[i]); //DELETE

      await socialNetworksDictionaryCreate(socialNetworks[i]);
    }
  };
  const fetchSocialNetworks = async () => {
    const socialNetworks = await socialNetworksDictionaryIndex();

    setAddSocialNetworksState([]);
    setReadSocialNetworksState(socialNetworks);
  };

  return (
    <ARTable
      className={params.className}
      variant="contained"
      header={<TableHeader
        save={save}
        add={addNewSocial}
        stub={loadingSocialNetworksState}
        saveLoading={saveLoadingState}
        saveDisabled={saveDisabled}
      />}
      tableHeadRow={<TableHeadRow />}
      tableBodyRow={<TableBodyRow
        addIcon={addIcon}
        deleteIcon={deleteIcon}
        delete={deleteSocialNetwork}
        inputSocialNetworkName={inputSocialNetworkName}
      />}
      items={socialNetworks}
      loading={loadingSocialNetworksState}
      emptyActionBtnLabel="Добавить соц. сеть"
      onEmptyActionBtnClick={addNewSocial}
      maxHeight={'400px'}
    />
  );
};

export default SocialsSettings;