import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 3.75007C0 1.68253 1.68253 0 3.75007 0H10.0441C11.1133 0 12.1555 0.376205 12.9771 1.06122L16.7028 4.16675H27.9172C29.9847 4.16675 31.6673 5.84928 31.6673 7.91682V9.16684H9.12778C7.05607 9.16684 5.17505 10.4691 4.4467 12.4091L2.1794 18.4444L1.28258 20.8337L0.266932 23.5356C0.220265 23.6598 0.185537 23.7865 0.15137 23.9132C0.0622017 23.594 0 23.2646 0 22.9171V3.75007ZM6.78724 13.288C7.15308 12.313 8.08609 11.6669 9.12778 11.6669H33.3323C34.5007 11.6669 35.3059 12.8384 34.8884 13.9293L30.7135 25.046C30.3476 26.0211 29.4146 26.6672 28.3729 26.6672H4.16837C3.00418 26.6672 2.19909 25.5037 2.6091 24.4145L6.78724 13.288Z" fill={colorState} />
    </svg>
  );
};

export default ArtlionLogoVertical;