import React, { useState, useEffect, } from 'react';

const ArtlionLogoVertical = ({ color, width, height, }: LogoProps) => {
  const defaultColor = '#000000';

  const [colorState, setColorState] = useState(defaultColor);

  useEffect(() => {
    setColorState(color || defaultColor);
  }, [color, width, height,]);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12ZM13 13H18C18.552 13 19 12.553 19 12C19 11.447 18.552 11 18 11H13V6C13 5.447 12.552 5 12 5C11.448 5 11 5.447 11 6V11H6C5.448 11 5 11.447 5 12C5 12.553 5.448 13 6 13H11V18C11 18.553 11.448 19 12 19C12.552 19 13 18.553 13 18V13Z" fill={colorState} />
    </svg>
  );
};

export default ArtlionLogoVertical;