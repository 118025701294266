import React from "react";
import './EmptyCase.scss';
import AppButton from '@presentation/components/AppButton/AppButton';
import { IEmptyCaseParams } from './EmptyCase.interfaces';

const EmptyCase = (params: IEmptyCaseParams) => {
  return (
    <div className="artable-empty-case">
      <AppButton
        className="artable-empty-case__action-btn"
        variant="outlined"
        loaderColor="white"
        onClick={params.onActionBtnClick}
      >
        {params.actionBtnLabel}
      </AppButton>
    </div>
  );
};

export default EmptyCase;