import { makeAutoObservable } from "mobx";
import { api } from "@api";
import accountsAdapter from '@adapters/accounts'

class Accounts {
  accounts = [];

  constructor() {
    makeAutoObservable(this);
  }

  /* FETCHERS */
  async fetchAccounts() {
    try {
      // const response = await api.get(`admin/packs/${payload.uuid}`);

      return this.accounts = accountsAdapter({
        data: [],
        isMock: true,
      });
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }
}

export default new Accounts();