import React from "react";
import AppButtonSave from '@presentation/components/AppButtonSave/AppButtonSave';
import AppButtonAdd from '@presentation/components/AppButtonAdd/AppButtonAdd';
import { useStateBoolean } from '@hooks/useStateBoolean';
import { IAppTableHeaderParams } from './AppTableHeader.interfaces';
import styles from './AppTableHeader.module.scss';

const AppTableHeader = (params: IAppTableHeaderParams) => {
  const isLoading = useStateBoolean(!!params.stub);
  const isLoadingSave = useStateBoolean(!!params.saveLoading);
  const isAppButtonSaveDisabled = useStateBoolean(!!isLoading || !!params.saveDisabled);
  const isAppButtonAddDisabled = useStateBoolean(!!isLoading || !!isLoadingSave);

  return (
    <div className={styles.AppTableHeader}>
      <AppButtonSave
        className={styles.AppTableHeaderBtnSave}
        onClick={params.save}
        disabled={isAppButtonSaveDisabled}
        loading={isLoadingSave}
      />
      <AppButtonAdd
        className={styles.AppTableHeaderBtnAdd}
        onClick={params.add}
        disabled={isAppButtonAddDisabled}
      />
    </div>
  );
};

export default AppTableHeader;