import React from 'react';
import { Link, } from "react-router-dom";
import ARSvg from '@presentation/components/ui-embedded/ARSvg';
import styles from '../TheSideBar.module.scss';

interface IRenderMenuItemsParams {
  items: Array<MenuItem>;
  logout?: () => void;
};
interface IRenderMenuItemParams {
  item: MenuItem;
  logout?: () => void;
  key: number;
};


export const renderMenuItems = ({ items, logout }: IRenderMenuItemsParams) => {
  return items.map((item: MenuItem, index) => (renderMenuItem({ item, logout, key: index })));
}

export const renderMenuItem = ({ item, logout, key }: IRenderMenuItemParams) => {
  if (item.iconName.toLowerCase() === 'logout') {
    return (
      <div className={styles.sidebar__item} key={key} onClick={logout}>
        <ARSvg name={item.iconName} color="#4D4D4D" />
      </div>
    );
  }

  return (
    <Link to={item.route} className={styles.sidebar__item} key={key}>
      <ARSvg name={item.iconName} color="#4D4D4D" />
    </Link>
  );
};