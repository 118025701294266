import Cookies from 'js-cookie';

export const tokenInterceptor = (config: any) => {
  console.debug('api/interceptors/request/tokenInterceptor'); // DELETE

  const token = Cookies.get('token');

  console.debug('api/interceptors/request/tokenInterceptor/token', token); // DELETE

  config.headers.Authorization = `Bearer ${token}`;

  config.maxContentLength = 500000000;
  config.maxBodyLength = 5000000000;

  return config;
}