import React, { useState, useEffect, useRef, } from 'react';

import { observer } from 'mobx-react-lite';
import StickerPackItem from '@store/workspace/stickerPack/item';

const DefaultStickersTab = observer(
  ({ params }) => {
    const [drag, setDrag] = useState(false);
    const refAddSticker = useRef(null);

    const staticData = {
      fileCount: 0,
      fileCounter: 0,
      stickers: [],
    }
    const handlers = {
      dragStart(payload = {}) {
        payload.event.preventDefault();
        setDrag(true);
      },
      dragLeave(payload = {}) {
        payload.event.preventDefault();
        setDrag(false);
      },
      dragDrop(payload = {}) {
        payload.event.preventDefault();

        const files = [...payload.event.dataTransfer.files];

        staticData.fileCount = files.length;

        files.forEach(
          (file) => {
            const _URL = window.URL || window.webkitURL;
            let img = new Image();

            img.src = _URL.createObjectURL(file);

            img.onload = async function () {
              await StickerPackItem.setStickersToAdd({ file, sticker: img, });
              await StickerPackItem.parseStickersToDisplay();

              helpers.checkInvalidStickers({ fileCounter: ++staticData.fileCounter, });
            }
          }
        );

        setDrag(false);
      },
      onAddStickerClicked() {
        refAddSticker.current.click();
      },
      onFileChanged(event) {
        console.debug('onFileChanged', event.target.files); // DELETE

        const files = [...event.target.files];

        console.debug('onFileChanged[files]', files); // DELETE

        staticData.fileCount = files.length;

        files.forEach(
          (file) => {
            const _URL = window.URL || window.webkitURL;
            let img = new Image();

            img.src = _URL.createObjectURL(file);

            img.onload = async function () {
              await StickerPackItem.setStickersToAdd({ file, sticker: img, });
              await StickerPackItem.parseStickersToDisplay();

              helpers.checkInvalidStickers({ fileCounter: ++staticData.fileCounter, });
            }
          }
        );
      },
    }
    const helpers = {
      async checkInvalidStickers(payload = {}) {
        console.debug('checkInvalidStickers', payload); // DELETE

        if (payload.fileCounter === staticData.fileCount) {
          staticData.fileCount = 0;
          staticData.fileCounter = 0;

          if (StickerPackItem.invalidStickersToAdd.length) {
            alert('sticker select error');
          }
        }
      },
    }

    return (
      <div className="add-stickers add-stickers--new-pack">
        <div className="toggle add-stickers__toggle">
          <button className="toggle__button toggle__button_left toggle__button_active">Статика</button>
          <button className="toggle__button toggle__button_right">Анимация</button>
        </div>

        {drag
          ? <div
            className="add-stickers__text drop-area"
            onDragStart={event => handlers.dragStart({ event })}
            onDragLeave={event => handlers.dragLeave({ event })}
            onDragOver={event => handlers.dragStart({ event })}
            onDrop={event => handlers.dragDrop({ event })}
          >
            Отпустите файлы, чтобы загрузить их
          </div>
          : <div
            className="add-stickers__drop-area"
            onDragStart={event => handlers.dragStart({ event })}
            onDragLeave={event => handlers.dragLeave({ event })}
            onDragOver={event => handlers.dragStart({ event })}
          >
            <p className="add-stickers__text">Перетащите и вставьте .PNGs 512x512 px файлы или</p>
            <button className="add-stickers__button" onClick={() => handlers.onAddStickerClicked()}>Выберите файлы</button>

            <input 
              ref={refAddSticker}
              type="file"
              hidden={true}
              onChange={(e) => handlers.onFileChanged(e)}
              multiple
            />
          </div>
        }
      </div>
    )
  }
);

export default DefaultStickersTab;