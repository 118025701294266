import { createLanguageParams, } from './fetchLanguages.interfaces';
import { LanguageCode } from '@domain/LanguageCode';
import { LanguageLocal } from '@domain/LanguageLocal';

export const languagesGetAdapter = async (data: any, pure: boolean = false): Promise<any> => {
  const languagesNode = data.data;
  const languages = [];

  if (!pure) {
    languages.push({
      uuid: 'default',
      name: 'все',
    });
  }

  for (let i = 0; i < languagesNode.length; i++) {
    languages.push({
      uuid: languagesNode[i].uuid,
      name: languagesNode[i].name,
      code: languagesNode[i].code,
    });
  }

  return languages;
};

export const languagesCreateAdapter = async (params: createLanguageParams): Promise<any> => {
  const payload = {
    dictionary_language_uuid: params.languageCode.uuid,
    localizations: params.languageCodesLocals.map(item => ({
      dictionary_language_uuid: item.languageCode.uuid,
      name: item.name,
      is_default: item.default,
    })),
  };

  return payload;
};

export interface ILanguageGetAdapterResponse {
  languageCode: LanguageCode;
  languageCodesLocals: Array<LanguageLocal>
}

export const languageGetAdapter = async (data: any): Promise<ILanguageGetAdapterResponse> => {
  console.debug('languageGetAdapter', data); //DELETE
  return {
    languageCode: {
      uuid: data.uuid,
      code: data.code,
      name: data.code,
    },

    languageCodesLocals: data.localizations.map((item: any) => ({
      uuid: item.uuid,
      name: item.name,
      default: item.is_default,
      languageCode: {
        uuid: item.dictionary_language.uuid,
        code: item.dictionary_language.code,
        name: item.dictionary_language.code,
      }
    })),
  };
};