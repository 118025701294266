import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './MainLayout.styles.module.scss';
import { useScrollTop } from './hooks/useScrollTop';
import { usePreventScroll } from '@hooks/usePreventScroll';
import { getMainLayoutStyles } from './MainLayout.services';
import Dialog from '@store/dialog';
import { observer } from 'mobx-react-lite';

const MainLayout = observer(() => {
  const ref = useScrollTop();
  const mainRef = usePreventScroll();

  return (
    <div ref={ref} className={styles.main_layout} style={getMainLayoutStyles(Dialog.isOpen)}>
      <div ref={mainRef} className={styles.main_layout__main}>
        <Outlet />
      </div>
    </div>
  );
});

export default MainLayout;