import { IServiceParams } from '../AppSelectSearch.interfaces';
import { Item, OnItemClickCallBack, } from '../AppSelectSearch.types';

export interface IOnItemClickParams extends IServiceParams {
  item: Item;
  close: () => void;
  onItemClick?: OnItemClickCallBack;
}

export const onItemClickHandler = (params: IOnItemClickParams) => {
  console.debug('onItemClickHandler/params.store.searchValue', params.store.searchValue); //DELETE
  params.store.setValue(params.item.name);
  params.close();
  if (params.store.searchValue !== null) params.store.setSearchValue('');
  if (params.onItemClick) params.onItemClick(params.item);
};