import { makeAutoObservable } from "mobx";
import { api } from "@api";
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

class StickerPackList {
  packs = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetch(payload = {}) {
    console.debug("fetch packs", payload); // DELETE

    try {
      const response = await api.get({
        gateway: artlionUkApiGateway,
        url: 'admin/packs',
      });

      console.debug('res fetch packs: ', response); //DELETE es muss spaeter weg

      return this.packs = response.data;
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }

  async detele(payload = {}) {
    console.debug("detele packs", payload); // DELETE

    try {
      return await api.delete({
        gateway: artlionUkApiGateway,
        url: `admin/packs/${payload.uuid}/delete`,
      });
    }
    catch (e) {
      return Object.assign({}, e).response;
    }
  }

  async upload(payload = {}) { }
}

export default new StickerPackList();