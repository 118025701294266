import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Props } from './AppButton.interfaces';
import { setStylesByVariant } from './AppButton.helpers';
import './AppButton.scss';

const AppButton = (params: Props) => {
  return (
    <Button
      className={`arbutton arbutton_${params.variant} ${params.className}`}
      variant={params.variant}
      onClick={params.onClick}
      disabled={params.loading || params.disabled}
      style={{
        ...params.style,
        ...setStylesByVariant(params),
      }}
    >
      {params.loading
        ? <CircularProgress className={`arbutton__loader_${params.loaderColor}`} size={params.loaderSize} />
        : params.children
      }
    </Button>
  );
};

export default AppButton;