import React from 'react';
import {
  AppInputPictureWidth,
  AppInputPictureHeight,
} from './AppInputPicture.types';

export const witdhSetter = (
  width: AppInputPictureWidth,
  setter: React.Dispatch<React.SetStateAction<AppInputPictureWidth>>
): void => {
  if (width) {
    setter(`${width}px`);
  } else {
    setter('48px');
  }
};
export const heightSetter = (
  height: AppInputPictureHeight,
  setter: React.Dispatch<React.SetStateAction<AppInputPictureHeight>>
): void => {
  if (height) {
    setter(`${height}px`);
  } else {
    setter('48px');
  }
};