import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import StoreState from './store/StoreState';
import { IAppRadioButtonParams } from './AppRadioButton.interfaces';
import BpRadio from './components/BpRadio/BpRadio';
import { observer } from 'mobx-react-lite';
import './AppRadioButton.scss';

const AppRadioButton = observer((params: IAppRadioButtonParams) => {
  const [storeState, setStoreState] = React.useState<StoreState>(new StoreState());

  React.useEffect(() => {
    storeState.setActive(!!params.value);
  }, [params.value]);

  return (
    <FormControlLabel
      label=""
      control={<BpRadio />}
      checked={!!storeState.active}
      onClick={() => {
        storeState.setActive(!storeState.active);

        if (params.onChange && storeState.activePrev !== undefined) {
          params.onChange({ value: storeState.active, });
        }

        if (params.onClick) {
          params.onClick({ value: storeState.active, });
        }
      }}
    />
  );
});

export default AppRadioButton;