import React from 'react';
import Template from './template';

const ARSearchWithLogo = ({
  className,
  leftLabel,
  onInput,
}) => {
  return (
    <Template
      className={className}
      leftLabel={leftLabel}
      onInput={onInput}
    />
  );
};

export default ARSearchWithLogo;