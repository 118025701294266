import React from 'react';

const Template = ({ className, value, }) => {
  return (
    <div className={`download-info__progress-bar ${className}`}>
      <span style={{ width: `${value ?? 0}%` }}></span>
    </div>
  );
}

export default Template;