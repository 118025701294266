import React from 'react';
import styles from './TheCopyright.module.scss';

const TheCopyright = () => {
  return (
    <div className={styles.the_copyright}>
      COPYRIGHT 2022 © ARTLION
    </div>
  );
};

export default TheCopyright;