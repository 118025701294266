import { api } from '@api';
import { artlionUkApiGateway } from '@api/gateways/artlionUkApiGateway';

export const languageCodesGet = async (): Promise<any> => {
  const response = await api.get({
    gateway: artlionUkApiGateway,
    url: 'dictionaries/languages/codes',
  });

  return response;
};

export const languageCodesPost = async (payload: any): Promise<any> => {
  const response = await api.post({
    gateway: artlionUkApiGateway,
    url: 'dictionaries/languages/codes',
    params: payload,
  });

  return response;
};