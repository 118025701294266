import React from "react";
import classNames from "classnames";
import { fetchLanguages, } from '@api/rest/dictionaries/languages/languages';
import AppTable from '@presentation/components/AppTable/AppTable';
import AppTableHeader from '@presentation/components/AppTableHeader/AppTableHeader';
import { ILanguageCodesParams } from './Languages.interfaces';
import TableHeadRow from './components/TableHeadRow/TableHeadRow';
import TableBodyRow from './components/TableBodyRow/TableBodyRow';
import useLanguageCodes from './hooks/useLanguageCodes';
import useLanguageCodesLoading from './hooks/useLanguageCodesLoading';
import LanguageForm from './components/LanguageForm/LanguageForm';
import Dialog from '@store/dialog';

const LanguageCodes = (params: ILanguageCodesParams) => {
  const [saveLoadingState, setSaveLoadingState] = React.useState<boolean>(false);
  const [saveDisabledState, setSaveDisabledState] = React.useState<boolean>(true);
  const [languageCodesRead, setLanguageCodesRead] = React.useState<Array<any> | null>(null);
  const [languageCodesAdd, setLanguageCodesAdd] = React.useState<Array<any>>([]);
  const languageCodes = useLanguageCodes(languageCodesRead, languageCodesAdd);
  const languageCodesLoading = useLanguageCodesLoading(languageCodes);
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  // const [isNew, setIsNew] = React.useState<boolean>(false);
  const [languageUuid, setLanguageUuid] = React.useState<string | null>(null);

  React.useEffect(() => {
    fetchLanguages({ pure: true }).then((response) => {
      console.debug('fetchLanguages', response); //DELETE
      setLanguageCodesRead(response);
    });

    return () => {
      Dialog.setOpenStatus(false);
    }
  }, []);
  React.useEffect(() => {
    setSaveDisabledState(!languageCodesAdd.length);
  }, [languageCodesAdd.length]);

  const deleteLanguageCode = (uuid: Uuid) => {
    console.debug('LanguageCodes/deleteLanguageCode/uuid', uuid); //DELETE

    if (saveLoadingState) {
      return;
    }

    setLanguageCodesAdd(languageCodesAdd.filter((languageCodeAdd) => languageCodeAdd.uuid !== uuid));
  };
  const inputLanguageCodeName = (uuid: Uuid, name: string) => {
    if (saveLoadingState) {
      return;
    }

    setLanguageCodesAdd(languageCodesAdd.map((languageCodeAdd) => {
      if (languageCodeAdd.uuid === uuid) {
        return {
          ...languageCodeAdd,
          code: name,
        };
      }

      return languageCodeAdd;
    }));
  };
  const save = async () => {
    if (!await validationOnSave()) {
      alert('Данные введены некорректно');
      return;
    }

    setSaveLoadingState(true);
    // await createLanguageCodes(languageCodesAdd);
    const languageCodes = await fetchLanguages({ pure: true });
    setLanguageCodesAdd([]);
    setLanguageCodesRead(languageCodes);
    setSaveLoadingState(false);
  };
  const addLanguage = () => {
    setLanguageUuid(null);
    setIsDialogOpen(true);
  };
  const editLanguage = (uuid: Uuid) => {
    console.debug('editLanguage/uuid', uuid); //DELETE
    setLanguageUuid(uuid);
    setIsDialogOpen(true);
  };
  const validationOnSave = async () => {
    let isValid = true;
    languageCodesAdd.forEach(languageCode => isValid = !!languageCode.code);
    return isValid;
  };

  const closeLanguageForm = () => {
    setIsDialogOpen(false);
    setLanguageUuid(null);
  };

  return (
    <div className={classNames('languages', params.className)}>
      <AppTable
        variant="contained"
        header={<AppTableHeader
          save={save}
          add={addLanguage}
          stub={languageCodesLoading}
          saveLoading={saveLoadingState}
          saveDisabled={saveDisabledState}
        />}
        tableHeadRow={<TableHeadRow />}
        tableBodyRow={<TableBodyRow
          delete={deleteLanguageCode}
          inputLanguageCodeName={inputLanguageCodeName}
          onClick={(uuid: Uuid) => editLanguage(uuid)}
        />}
        items={languageCodes}
        loading={languageCodesLoading}
        emptyActionBtnLabel="Добавить соц. сеть"
        onEmptyActionBtnClick={addLanguage}
        maxHeight={'400px'}
      />

      <LanguageForm
        isOpen={isDialogOpen}
        onClose={closeLanguageForm}
        languageUuid={languageUuid}
      />
    </div>
  );
};

export default LanguageCodes;