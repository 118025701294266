import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styles from './styles.module.scss'

ChartJS.register(ArcElement, Tooltip, Legend);

const DevicesChart = ({ chartData, percentData, }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Устройства</div>
      <div className={`${styles.platform} ${styles.platform__android}`}>Android</div>
      <div className={`${styles.platform} ${styles.platform__ios}`}>iOS</div>
      <div className={styles.platform__count}>
        <div className={styles['platform__count--ios']}>
          <div className={styles['platform__count--title']}>
            {percentData.ios.percent} %
          </div>
          <div className={styles['platform__count--subtitle']}>64,700/$10</div>
        </div>
        <div className={styles['platform__count--android']}>
          <div className={styles['platform__count--title']}>
            {percentData.android.percent} %
          </div>
          <div className={styles['platform__count--subtitle']}>45,700/$0</div>
        </div>
      </div>

      <Doughnut className={styles.chart} data={chartData} />
    </div>
  );
};

export default DevicesChart;