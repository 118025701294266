import React from 'react';
import Calendar from './svg/Сalendar';
import Search from './svg/Search';
import ArtlionLogoVertical from './svg/ArtlionLogoVertical';
import ArtlionLogoSidebar from './svg/ArtlionLogoSidebar';
import Sticker from './svg/Sticker';
import Chart from './svg/Chart';
import Money from './svg/Money';
import Account from './svg/Account';
import Directory from './svg/Directory';
import Settings from './svg/Settings';
import Logout from './svg/Logout';
import Login from './svg/Login';
import Plus from './svg/Plus';



const ARSvg = ({ name, color, width, height, }: SvgProps) => {
  return getSvgByName({ name, color, width, height, });
};

const getSvgByName = ({ name, color, width, height, }: SvgProps) => {
  switch (name.toLowerCase()) {
    case 'calendar':
      return <Calendar color={color} width={width} height={height} />;

    case 'search':
      return <Search color={color} width={width} height={height} />;

    case 'artlionlogovertical':
      return <ArtlionLogoVertical color={color} width={width} height={height} />;

    case 'artlionlogosidebar':
      return <ArtlionLogoSidebar color={color} width={width} height={height} />;

    case 'sticker':
      return <Sticker color={color} width={width} height={height} />;

    case 'chart':
      return <Chart color={color} width={width} height={height} />;

    case 'money':
      return <Money color={color} width={width} height={height} />;

    case 'account':
      return <Account color={color} width={width} height={height} />;

    case 'directory':
      return <Directory color={color} width={width} height={height} />;

    case 'settings':
      return <Settings color={color} width={width} height={height} />;

    case 'logout':
      return <Logout color={color} width={width} height={height} />;

    case 'login':
      return <Login color={color} width={width} height={height} />;

    case 'plus':
      return <Plus color={color} width={width} height={height} />;

    default:
      return <></>;
  }
};

export default ARSvg;